import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../../services/global.service';
import { NftService } from '../../services/nftservice.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-hot-collections',
  templateUrl: './hot-collections.component.html',
  styleUrls: ['./hot-collections.component.css']
})
export class HotCollectionsComponent implements OnInit {

  importCollectionform : FormGroup;
  imgsrc : any = '';
  public imageURL;
  hotCollection : any = [];
  submitted : boolean = false;
  loading : boolean = false;
  subscription: Subscription;
  constructor(private router: Router,private formBuilder: FormBuilder, private toastr: ToastrService , private nftservice : NftService,private globalservice : GlobalService) { }

  ngOnInit(): void {
    this.getHotCollection();
    this.subscription = this.globalservice.refreshPage.subscribe( (res) => {
      this.getHotCollection();
    });
  }

  getHotCollection(){
    this.nftservice.hotCollections()
    .subscribe(
      (data) => {
        if(data['status'] == 'success'){
          this.hotCollection = data['data']
        }
      }
    )
  }



}