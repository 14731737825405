import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,FormArray ,Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NftService } from '../../services/nftservice.service';
import { GlobalService } from '../../services/global.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create-single-collectible',
  templateUrl: './create-single-collectible.component.html',
  styleUrls: ['./create-single-collectible.component.css']
})
export class CreateSingleCollectibleComponent implements OnInit {

  createCollectionForm: FormGroup;
  addcollectionform: FormGroup;
  myFiles = [];
  imageType : any;
  imgsrc : any = '';
  public imageURL;
  submitted : boolean =false;
  submitted1 : boolean =false;
  properties: FormArray;
  levels: FormArray;
  stats: FormArray;
  loading = false;
  userData : any = [];
  collectionData : any = [];
  categoryData : any = [];
  collection_id : any;
  subscription: Subscription;
  id : any;
  collectionDtls : any;
  collectionerror : boolean = false;
  collection_name : string;

  constructor(private formBuilder: FormBuilder,private toastr: ToastrService,private router : Router,private activatedRoute : ActivatedRoute,private globalservice : GlobalService, private nftservice : NftService) { 
    this.activatedRoute.params.subscribe((res) => {
      this.collection_id = res['id'];
    });
  }

  ngOnInit(): void {
    this.id = this.collection_id;
    this.initializePage();
    this.subscription = this.globalservice.refreshPage.subscribe( (res) => {
      this.initializePage();
    });
    this.createCollectionForm = this.formBuilder.group({   
      name: ['',Validators.required],
      description: '',
      link: '',
      supply: '1',
      category_id: ['',Validators.required],
      //collection_id: ['',Validators.required],
      // on_sale : '',
      // instant_sale : '',
      price : '',
      //user_id: ['',Validators.required],      
      image : ['',Validators.required],
      unlock_description : '',
      properties: this.formBuilder.array([ this.createProperty() ]),
      levels: this.formBuilder.array([ this.createLevel() ]),
      stats: this.formBuilder.array([ this.createStat() ])
    });

    this.addcollectionform = this.formBuilder.group({
      name: ['', Validators.required],
      image: ['', Validators.required],
      description: '',
    })

  }

  initializePage(){
    this.productdata();
    this.collectionName();
  } 

  productdata(){
    this.nftservice.productData()
    .subscribe(
      (x) => {
        if(x['status'] == 'success'){
          this.userData = x['users'];
          this.collectionData = x['collections'];
          this.categoryData = x['categories'];
        }
      }
    )
  }

  showPrice(){
    if(!this.createCollectionForm.value.instant_sale){
      document.getElementById('price_piece').style.display ="block";
    }else{
      document.getElementById('price_piece').style.display ="none";
    }
    
  }

  collectionName(){
    this.nftservice.collectionDetails(this.collection_id)
    .subscribe(
      (data) => {
        if(data['status'] == 'success'){
          this.collectionDtls = data['data'];
          //console.log(this.collectionDtls);
        }
      }
    )
  }

  get f(){ return this.createCollectionForm.controls; } 

  onFileChange(event) {
	this.myFiles= [];
	var FileSize = event.target.files[0].size / 1024 / 1024; 
	
	if (FileSize > 50)
	{
    this.toastr.error('Please select less than 50 MB file.');
		return;
	} 
	else 
	{
  		for (var i = 0; i < event.target.files.length; i++) { 
  		  this.myFiles.push(event.target.files[i]);
  		}
  		var get_image_length = event.target.value.split('.');
  		this.imageType = event.target.value.split('.')[get_image_length.length-1];
  		const reader = new FileReader();
  		const file = event.target.files[0];
  		this.imageURL = event.target.files;
  		reader.readAsDataURL(event.target.files[0]);
  		reader.onload = (_event) => { 
        if(file.type == 'video/mp4') {
          this.imgsrc = 'https://cdn.imgbin.com/9/13/2/imgbin-logo-mpeg-4-part-14-symbol-mp3-symbol-6X4ETmLrfFWUbkyvJD1urQTrN.jpg';
        } else if(file.type == 'audio/mpeg') {
          this.imgsrc = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8adAr8WVIKAcNmJvngNodmciCuykjuBEz_-eD9P4a8BMIS5pAh4-zgCIzft2jcwnmWFY&usqp=CAU';
        } else {
          this.imgsrc = reader.result;
        }
  		}
      console.log(file);
  		this.createCollectionForm.get('image').setValue(file);
  	}
  }

  addProperty(): void {
    this.properties = this.createCollectionForm.get('properties') as FormArray;
    this.properties.push(this.createProperty());
  }

  removeProperty(rowIndex:number){
    if(rowIndex > 0){
      this.properties.removeAt(rowIndex);
    }
    
  }

  createProperty(): FormGroup {
    return this.formBuilder.group({
      type: '',
      name: ''
    });
  }


  addLevel(): void {
    this.levels = this.createCollectionForm.get('levels') as FormArray;
    this.levels.push(this.createLevel());
  }

  removeLevel(rowIndex:number){
    if(rowIndex > 0){
      this.levels.removeAt(rowIndex);
    }
    
  }

  createLevel(): FormGroup {
    return this.formBuilder.group({
      name : '',
      from : '',
      to   : ''

    });
  }

  addStat(): void {
    this.stats = this.createCollectionForm.get('stats') as FormArray;
    this.stats.push(this.createStat());
  }

  removeStat(rowIndex:number){
    if(rowIndex > 0){
      this.stats.removeAt(rowIndex);
    }
    
  }

  createStat(): FormGroup {
    return this.formBuilder.group({
      name: '',
      from: '',
      to: ''
    });
  }

  get g() { return this.addcollectionform.controls; }

  onFileChange1(event) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.myFiles.push(event.target.files[i]);
    }
    const reader = new FileReader();
    this.imageURL = event.target.files;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgsrc = reader.result;
    }
  }

  uniqueCollection(event){
    this.collection_name = event.target.value
    this.nftservice.uniqueCollection(this.collection_name)
    .subscribe(
      (x) => {
        if(x['status'] == 'success'){
          this.collectionerror  = true;
        }else{
          this.collectionerror  = false;  
        }
      }
    )

  }

  onSubmitted(form_data){
    this.submitted1= true;
    this.loading  = true;
    if(this.addcollectionform.invalid){
      return;
    }
    
    const fd: FormData = new FormData();
    Object.keys(form_data).forEach(el => {
      fd.append(el, form_data[el]);
    });

    if (this.myFiles.length > 0) {
      for (var i = 0; i < this.myFiles.length; i++) {
        fd.append("image", this.myFiles[i]);
      }
    }

    this.nftservice.createCollection(fd)
    .subscribe(
      (data) => {
        if(data['status'] == 'success'){
          this.toastr.success(data['message']);
          this.addcollectionform.reset();
          this.submitted1 = false;
          this.loading = false;
          this.imgsrc = "";
          this.globalservice.refreshPage.next(true);
        }
      }
    )

  }

  onSubmit(form_data){
    this.loading = true;
    this.submitted = true;
    //console.log(this.createCollectionForm.value);
    if(this.createCollectionForm.invalid){
      return;
    }

    this.loading = true;

    const fd:FormData = new FormData();
    Object.keys(form_data).forEach(el => {      
      fd.append(el, form_data[el]);
      fd.append("collection_id", this.collection_id);
      fd.append("properties", JSON.stringify(form_data['properties']));
      fd.append("levels", JSON.stringify(form_data['levels']));
      fd.append("stats", JSON.stringify(form_data['stats']));
    });

    if(this.myFiles.length > 0 ){      
      for (var i = 0; i < this.myFiles.length; i++) { 
        fd.append("image", this.myFiles[i]);
      }
    }

    this.nftservice.createSingleCollectible(fd)
    .subscribe(
      (data) => {
        if(data['status'] == 'success'){
          this.loading = false;
          this.submitted = false;
          this.toastr.success(data['message'])
          this.createCollectionForm.reset();  
          this.router.navigate(['/collection-details',data['collection_slug']]);
        }
      }
    )

  }


}
