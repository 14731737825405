<section class="editProfileSection cmsParagraph">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="editProileHead">
                    {{ cmsData.title}}
                </div>
            </div>

            <div class="col-12" [innerHTML]="cmsData.body">
                
            </div>
        </div>
    </div>
</section>