import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../../services/global.service';
import { NftService } from '../../services/nftservice.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-import-collection',
  templateUrl: './import-collection.component.html',
  styleUrls: ['./import-collection.component.css']
})
export class ImportCollectionComponent implements OnInit {

  importCollectionform : FormGroup;
  imgsrc : any = '';
  public imageURL;
  submitted : boolean = false;
  loading : boolean = false;
  subscription: Subscription;
  constructor(private router: Router,private formBuilder: FormBuilder, private toastr: ToastrService , private nftservice : NftService,private globalservice : GlobalService) { }

  ngOnInit(): void {
    this.initializePage()
    this.subscription = this.globalservice.refreshPage.subscribe( (res) => {
      this.initializePage();
    });
  }

  initializePage(){
    this.importCollectionform = this.formBuilder.group({
      exchange : ['',Validators.required]
    });
  }

  onSubmitted(form_data){
    this.submitted = true;
    if(this.importCollectionform.value.exchange == ''){
      this.toastr.error("Please enter Contract Address.");
    }
    this.loading = true;
    const fd: FormData = new FormData();
    Object.keys(form_data).forEach(el => {
      fd.append(el, form_data[el]);
    });
    this.nftservice.importCollection(fd)
    .subscribe(
      (x) => {
        if(x['status'] == 'success'){
          this.toastr.success(x['message'])
          this.globalservice.refreshPage.next(true);
          this.loading = false;
          this.submitted = false;
          this.router.navigate(['/collections']);
        }
      }
    )
  }


}