<section class="editProfileSection">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-10 col-lg-7 mx-auto">
                <div class="editProileHead">
                    Import Collection
                    <a class="nav-link extra_btn" [routerLink]="['/collections']" href="javascript:void(0);">Go Back </a>
                </div>
                <div class="editProfileCard">
                    <div class="profileUpload">
                    </div>
                    <div class="formHead">
                        <form [formGroup] ="importCollectionform">
                            <div class="form-group">
                                <label for="display">Contract Address</label>
                                <input required type="text" formControlName="exchange" class="form-control" placeholder="Enter your ERC721 or ERC1155 contract address" />
                            </div>                            
                        </form>
                    </div>
                    <div class="editFooter">
                        <button type="button" (click)="onSubmitted(importCollectionform.value)" class="updateBtn">Submit</button>
                    </div>                   
                </div>                
            </div>
        </div>
    </div>
</section>
