import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { NftService } from '../../services/nftservice.service';
import { GlobalService } from '../../services/global.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Web3Service } from 'src/app/web3.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-collectiondetails',
  templateUrl: './collectiondetails.component.html',
  styleUrls: ['./collectiondetails.component.css']
})
export class CollectiondetailsComponent implements OnInit {

  editcollectionform : FormGroup;
  submitted : boolean = false;
  collectiondtls : any = [];
  collection_id : any;
  collection_slug : any;
  collectionItemData : any = [];
  currentRoute : string;
  subscription: Subscription;
  showform : boolean = false;
  myFiles  = [];
  public imageURL;
  imgsrc: any = "";
  nameerror : boolean = false;
  collection_name : string ;
  constructor(private nftservice : NftService,private router : Router, private toastr: ToastrService,public web3Service: Web3Service ,private formBuilder: FormBuilder ,private activatedRoute : ActivatedRoute,private globalservice : GlobalService) { 
    this.activatedRoute.params.subscribe((params) => {
      this.collection_slug = params['collection_slug'];
    });

   this.currentRoute =  this.router.url;
  }

  ngOnInit(): void {
    this.initializePage()
    this.subscription = this.globalservice.refreshPage.subscribe( (res) => {
      this.initializePage();
    });
	
	this.subscription = this.globalservice.signInApproved.subscribe( (res) => {
		this.toastr.success("Approved!"); 
		this.router.navigate(['/create-single', this.collection_id]);	
  });
	
	this.subscription = this.globalservice.signInDisApproved.subscribe( (res) => {
		this.toastr.error("Canceled!");  
  });

    this.editcollectionform = this.formBuilder.group({
      name: ['', Validators.required],
      image: '',
      description: ['', Validators.required],
    })

  }
  
  initializePage(){
    this.collectionDtls()
    this.collectionItemList()
    this.gotoTop()
  }

  collectionDtls(){
    this.nftservice.collectionDetails(this.collection_slug)
    .subscribe(
      (x) => {
        if(x['status'] == 'success'){
          this.collectiondtls = x['data']
          this.collection_id = x['data']['id'];
        }
      });
  }

  collectionItemList(){
    this.nftservice.collectionItems(this.collection_slug)
    .subscribe(
      (d) => {
        if(d['status'] == 'success'){
          this.collectionItemData = d['data']          
        }
      });
  }

  editcollection(id){
    this.showform = true;
    this.imgsrc = this.collectiondtls.image;
    this.editcollectionform.patchValue({
      name : this.collectiondtls.collection_name,
      description : this.collectiondtls.description,
      image : ''
    });
  }

  get f(){ return this.editcollectionform.controls; }

  onFileChange(event) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.myFiles.push(event.target.files[i]);
    }
    const reader = new FileReader();
    this.imageURL = event.target.files;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgsrc = reader.result;
    }
  }

  onSubmitted(form_data){
    this.submitted = true;
    if(this.editcollectionform.invalid){
      return;
    }
    console.log(this.editcollectionform.value);
    const fd: FormData = new FormData();
    Object.keys(form_data).forEach(el => {
      fd.append(el, form_data[el]);
    });

    if (this.myFiles.length > 0) {
      for (var i = 0; i < this.myFiles.length; i++) {
        fd.append("image", this.myFiles[i]);
      }
    }

    this.nftservice.updateCollection(this.collectiondtls.id,fd)
    .subscribe(
      (data) => {
        if(data['status'] == 'success'){
          this.toastr.success(data['message']);          
          this.collectionDtls();
          this.submitted = false;
          this.showform = false;
          this.globalservice.refreshPage.next(true);
        }
      }
    )

  }

  back(){
    this.showform = false;
    //this.globalservice.refreshPage.next(true);
  }

  uniqueCollectionEdit(event){
    this.collection_name = event.target.value
    this.nftservice.uniqueCollectionedit(this.collection_id,this.collection_name)
    .subscribe(
      (x) => {
        if(x['status'] == 'success'){
          this.nameerror  = true;
        }else{
          this.nameerror  = false;  
        }
      }
    )

  }

  async signInToMetamask() {
		
		var thisobj =	this;
		
		var message = "Welcome to NFTMarketplace! \n \n \n Click 'Sign' to sign in. No password needed! \n \n \n I accept the NFTMarketplace Terms of Service: https://blockdemo.net/ ";
		await this.web3Service.signIn(message).then(result=>{
			console.log(result);
			
		});
		/* 
		thisobj.mainService.userDetails(thisobj.current_account).subscribe(res => {
			if(res.status=="success"){
				localStorage.setItem('authUser', JSON.stringify(res));
				this.toastr.success("Account logged in successfully.");
			}else{
				this.toastr.error("Error in loging into account.");
			}
		}); */
	
	}

  gotoTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

}
