import { Component, OnInit } from '@angular/core';
import { NftService } from '../../services/nftservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../../services/global.service';
import { Subscription } from 'rxjs';

declare var copyToClipboard: Function;

@Component({
  selector: 'app-bid-user',
  templateUrl: './bid-user.component.html',
  styleUrls: ['./bid-user.component.css']
})
export class BidUserComponent implements OnInit {

  soldProductData : any = [];
  collectionData : any = [];
  productData : any = [];
  favData : any = [];
  userData : any = [];
  createdProductData : any = [];
  followersList : any = [];
  subscription: Subscription;
  currentRoute : string;
  followingList : any = [];
  wishlistData : any = [];
  address : any;
  followStatus : any;
  constructor(private nftservice : NftService,private router: Router, private toastr: ToastrService, private activatedRoute: ActivatedRoute,private globalservice : GlobalService) { 
    this.activatedRoute.params.subscribe((params) => {
      this.address = params['address'];
    });
   this.currentRoute =  this.router.url;
  }

  ngOnInit(): void {
    this.initializePage()
    this.subscription = this.globalservice.refreshPage.subscribe( (res) => {
      this.initializePage();
    });
  }

  initializePage(){
    this.getOtherUserProfileDetails()
    // this.soldProducts();
    // this.userCollections();
    // this.userProducts();
    // this.favourites();
    // this.activities()
  }

  getOtherUserProfileDetails(){
    this.nftservice.getOtherUserProfileDetails(this.address)
    .subscribe(
      (x) => {
        if(x['status'] == 'success'){
          this.userData = x['userData']
          this.followStatus = x['followStatus']
          this.followersList = x['followersList']
          this.followingList = x['followingList']
          this.wishlistData = x['wishlistData']
          this.createdProductData = x['createdProductData']
          this.collectionData = x['collectionData']
        }
      });
  }

  // soldProducts(){
  //   this.nftservice.soldProducts()
  //   .subscribe(
  //     (data) => {
  //       if(data['status'] == 'success'){
  //         this.soldProductData  = data['data']
          
  //       }
  //     }
  //   )
  // }

  // userCollections(){
  //   this.nftservice.collections()
  //   .subscribe(
  //     (data) => {
  //       if(data['status'] == 'success'){
  //         this.collectionData  = data['data']
          
  //       }
  //     }
  //   )
  // }

  // userProducts(){
  //   this.nftservice.userProducts()
  //   .subscribe(
  //     (data) => {
  //       if(data['status'] == 'success'){
  //         this.productData  = data['data']
          
  //       }
  //     }
  //   )
  // }

  // favourites(){
  //   this.nftservice.userFavourites()
  //   .subscribe(
  //     (data) => {
  //       if(data['status'] == 'success'){
  //         this.favData  = data['data']
  //         console.log(this.favData);
  //       }
  //     }
  //   )
  // }

  // activities(){
  //   this.nftservice.userActivities()
  //   .subscribe(
  //     (data) => {
  //       if(data['status'] == 'success'){
  //         this.activityData  = data['data']
  //       }
  //     }
  //   )
  // }

  followUnfollowUser(follow_user_id) {
      const fd: FormData = new FormData();
      fd.append('follow_user_id', follow_user_id);
      this.nftservice.followUnfollowUser(fd)
      .subscribe(
        (data) => {
          if(data['status'] == 'success'){
            this.toastr.success(data['message']);
            this.initializePage();
          }
        }
      );
  }

  copyInputMessage(inputElement){
    copyToClipboard(inputElement);
    this.toastr.success('Copied!');
  }


}