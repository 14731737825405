import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  refreshPage = new Subject<boolean>();
  updateUserLogin = new Subject<boolean>();
  refreshUserData = new Subject<boolean>();
  signInApproved = new Subject<boolean>();
  signInDisApproved = new Subject<boolean>();
  sellSignInApproved = new Subject<any>();
  sellSignInDisApproved = new Subject<boolean>();
  signupTextdata = new Subject<any>();
  checkEventListenData = new Subject<any>();
  constructor() { }
}
