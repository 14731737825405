<section class="editProfileSection">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-10 col-lg-7 mx-auto">
                <div class="editProileHead">
                    Create collection
                    <!-- <a href="javascript:void(0);">Go back</a> -->
                </div>
                <div class="editSubHead">Choose “Single” if you want your collection to be one of a kind or “Multiple” if you
                    want to sell one collection multiple times</div>


                <div class="createMultiflex">
                    <a href="javascript:void(0);" class="singleCard edit-block" data-toggle="modal"  data-target="#collectionModal">
                        <div class="singleCardHead">
                            <img src="assets/img/logo.png" alt="" />
                            <!-- <div class="singleCardImg"></div>
                            <div class="lineBar_1 "></div>
                            <div class="lineBar_1 lineBar_2"></div>
                            <div class="lineBar_1 lineBar_3"></div>
                            <div class="markSingleCard"></div> -->
                        </div>
                        <div class="markSingleHead">
                            Create Collection
                        </div>

                    </a>

                    <a [routerLink] ="['/import-collection']" class="singleCard">
                        <div class="singleCardHead">
                            <img src="assets/img/logo.png" alt="" />
                            <!-- <div class="singleCardImg"></div>
                            <div class="lineBar_1 "></div>
                            <div class="lineBar_1 lineBar_2"></div>
                            <div class="lineBar_1 lineBar_3"></div>
                            <div class="markSingleCard"></div> -->
                        </div>
                        <div class="markSingleHead">
                            Import Collection
                        </div>

                    </a>
                </div>

                <div class="editSubHead">We do not own your private keys and cannot access your funds without your
                    confirmation..</div>  
            </div>

        </div>

        
    </div>
</section>

<section class="hot-bids pb-4">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="hotCollectparent">
                    <div class="hotCollectionCard" *ngFor="let collection of collectionData">
                        <a href="javascript:void(0);" [routerLink] ="['/collection-details',collection.slug]">
                            <img src="{{collection.image}}" alt="" />
                        </a>
                        <div class="hotCollectBody">
                            <a href="javascript:void(0);" [routerLink] ="['/collection-details',collection.slug]" class="hotBidCardHead">{{collection.collection_name}}</a>
                        </div>
                    </div>
                
                </div>

            </div>
        </div>
    </div>
</section>

<div class="modal fade theme-modal" id="collectionModal" tabindex="-1" role="dialog"
    aria-labelledby="collectionModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="collectionModalTitle">Create Collection</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 340.8 340.8"
                        style="enable-background:new 0 0 340.8 340.8;" xml:space="preserve">
                        <g>
                            <g>
                                <path d="M170.4,0C76.4,0,0,76.4,0,170.4s76.4,170.4,170.4,170.4s170.4-76.4,170.4-170.4S264.4,0,170.4,0z M170.4,323.6
                 c-84.4,0-153.2-68.8-153.2-153.2S86,17.2,170.4,17.2S323.6,86,323.6,170.4S254.8,323.6,170.4,323.6z" />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path d="M182.4,169.6l50-50c3.2-3.2,3.2-8.8,0-12c-3.2-3.2-8.8-3.2-12,0l-50,50l-50-50c-3.2-3.2-8.8-3.2-12,0
                 c-3.2,3.2-3.2,8.8,0,12l50,50l-50,49.6c-3.2,3.2-3.2,8.8,0,12c1.6,1.6,4,2.4,6,2.4s4.4-0.8,6-2.4l50-50l50,50c1.6,1.6,4,2.4,6,2.4
                 s4.4-0.8,6-2.4c3.2-3.2,3.2-8.8,0-12L182.4,169.6z" />
                            </g>
                        </g>
                    </svg>

                </button>
            </div>
            <div class="modal-body checkout-modalBody thememodalBody">

                <form [formGroup]="addcollectionform">

                    <div class="form-group ">  
                        <div class="form-row">
                            <div class="col-12">
                                <label>Image<label style="color: #fb0707;">*</label></label>
                            </div>
                        </div> 
                        <div class="modal-input customFile">
                            <input type="file" id="collectImg" hidden formControlName="image" class="form-control" (change)="onFileChange($event)" [ngClass]="{ 'is-invalid': submitted && f.image.errors }" />
                            <label for="collectImg">Choose File</label>
                            <div *ngIf="submitted && f.image.errors" class="invalid-feedback">
                                <div *ngIf="f.image.errors.required">image is required</div>
                            </div>
                            <img [src]="imgsrc" id="upload_img" class="img-fluid mr-4" height="100" width="100" *ngIf="imgsrc">
                        </div>
                        
                    </div>                                        

                    <div class="form-group">
                        <div class="form-row">
                            <div class="col-12">
                                <label>Name<label style="color: #fb0707;">*</label></label>
                            </div>
                        </div>
                        <div class="modal-input">
                            <input type="text" formControlName="name" class="form-control" (focusout)="uniqueCollection($event)"  [ngClass]="{ 'is-invalid' : submitted && f.name.errors || collectionerror }"/> 
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">name is required</div>
                             </div>
                             <div class="invalid-feedback" *ngIf="collectionerror">collection already exists</div>
                        </div>
                         
                    </div>

                    <div class="form-group ">
                        <div class="form-row">
                            <div class="col-12">
                                <label>Description</label>
                            </div>
                        </div>
                        <textarea type="text" formControlName="description" class="form-control"></textarea>
                        <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                            <div *ngIf="f.description.errors.required">description is required</div>
                        </div>
                    </div>


                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="button" (click)="onSubmitted(addcollectionform.value)" data-dismiss="modal" [disabled]="!addcollectionform.valid && submitted || collectionerror" class="btn btn-primary">Submit</button>
            </div>
        </div>
    </div>
</div>