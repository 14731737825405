<section class="hot-bids pb-5">
    <br>
    <div class="container">
        <div class="heading pb-5">
            <div class="row align-items-center">
                <div class="col">
                    <h3>My Wishlist</h3>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="exploreTabhead">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                            <div class="exploreParent">
                                <div class="hotBidCard" *ngFor="let allProduct of allProductData">
                                    <a routerLink="/assets/{{allProduct.product.token_id}}" class="placeBidHref">
                                        <div class="hotBidCardImg"
                                            style="background-image: url({{allProduct.product.image}});">
                                            <img src="{{allProduct.image}}" alt="" />
                                        </div>
                                    </a>
                                        <div class="hotBidCardBody">
                                        <a href="javascript:void(0);" [routerLink]="['/assets',allProduct.product.id]" class="hotBidCardHead">{{allProduct.product.name}}</a>
                                        <div class="hotBidCardRank" *ngIf="allProduct.product.price">{{allProduct.product.price}} ETH</div>
                                        <a routerLink="/assets/{{allProduct.product.token_id}}" class="placeBidHref">Place a bid</a>
                                    </div>
                                    <a href="javascript:void(0);" class="like active" (click)="updateWistlist(allProduct.product.id)">
                                        <i class="fa fa-heart"></i>
                                    </a>
                                <a routerLink="/assets/{{allProduct.product.token_id}}" class="placeBidHref">
                                    <div class="dropListHotBid">
                                        <i class="fal fa-ellipsis-v"></i>
                                    </div>
                                </a>
                                </div>                             
                               
                            </div>
                        </div>                        
                    </div>
                </div>

                <div class="alert alert-danger" *ngIf="allProductData.length == 0">
                  <strong>Nothing here!</strong>
                </div>
                
            </div>
        </div>
    </div>
</section>