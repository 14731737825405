import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../../services/global.service';
import { NftService } from '../../services/nftservice.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})

export class EditProfileComponent implements OnInit {

  profileData : any = [];
  editProfileform : FormGroup;
  imgsrc : any = '';
  public imageURL;
  submitted : boolean = false;
  loading : boolean = false;
  myFiles = [];
  subscription: Subscription;
  constructor(private router: Router,private formBuilder: FormBuilder, private toastr: ToastrService , private nftservice : NftService,private globalservice : GlobalService) { }

  ngOnInit(): void {
    this.initializePage()
    this.subscription = this.globalservice.refreshPage.subscribe( (res) => {
      this.initializePage();
    });
  }

  initializePage(){
    this.editProfile()
    this.editProfileform = this.formBuilder.group({
      image : '',
      name : ['',Validators.required],
      bio : ['',Validators.required],
      custom_url : ['',Validators.required],
      twitter_username : ['',Validators.required],
      portfolio : ['',Validators.required]
    });
  }

  editProfile(){
    this.nftservice.profile()
    .subscribe(
      (data) => {
        if(data['status'] == 'success'){
          this.profileData = data['data']
          this.imgsrc = this.profileData.image;
          this.editProfileform.patchValue({
            image : '',
            name : this.profileData.name,
            portfolio : this.profileData.portfolio,
            twitter_username : this.profileData.twitter_username,
            custom_url : this.profileData.custom_url,
            bio : this.profileData.bio
          });
        }
      }
    )
  }

  onFileChange(event) {
    for (var i = 0; i < event.target.files.length; i++) { 
      this.myFiles.push(event.target.files[i]);
    }
    const reader = new FileReader();
    this.imageURL = event.target.files;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => { 
      this.imgsrc = reader.result;
    }
    const file = event.target.files[0];
    this.editProfileform.get('image').setValue(file);

  }

  onSubmitted(form_data){
    this.submitted = true;
    if(this.editProfileform.invalid){
      this.toastr.error('Please fill all required fields');
      return;
    }

    this.loading = true;
    const fd: FormData = new FormData();
    Object.keys(form_data).forEach(el => {
      fd.append(el, form_data[el]);
    });

    if (this.myFiles.length > 0) {
      for (var i = 0; i < this.myFiles.length; i++) {
        fd.append("image", this.myFiles[i]);
      }
    }

    this.nftservice.updateProfile(fd)
    .subscribe(
      (x) => {
        if(x['status'] == 'success'){
          this.toastr.success(x['message'])
          this.globalservice.refreshPage.next(true);
          this.loading = false;
          this.submitted = false;

        }
      }
    )

  }

}
