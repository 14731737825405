import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { HomeComponent } from './components/home/home.component';
import { BidUserComponent } from './components/bid-user/bid-user.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { CreateCollectibleComponent } from './components/create-collectible/create-collectible.component';
import { CreateSingleCollectibleComponent } from './components/create-single-collectible/create-single-collectible.component';
import { ActivityComponent } from './components/activity/activity.component';
import { CmsPageComponent } from './components/cms-page/cms-page.component';
import { ExploreComponent } from './components/explore/explore.component';
import { AccountComponent } from './components/account/account.component';
import { FaqComponent } from './components/faq/faq.component';
import { CollectiondetailsComponent } from './components/collectiondetails/collectiondetails.component';
import {EditItemComponent } from './components/edit-item/edit-item.component';
import { ImportCollectionComponent } from './components/import-collection/import-collection.component';
import { WishlistComponent } from './components/wishlist/wishlist.component';
import { CollectionsComponent } from './components/collections/collections.component';
import { HistoryComponent } from './components/history/history.component';
import { AssetsComponent } from './components/assets/assets.component';
import { CreatorsComponent } from './components/creators/creators.component';
import { HotCollectionsComponent } from './components/hot-collections/hot-collections.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'assets/:token_id',
    component: AssetsComponent
  },
  {
    path: 'user/:address',
    component: BidUserComponent
  },
  {
    path: 'wishlist',
    component: WishlistComponent
  },
  {
    path: 'creators',
    component: CreatorsComponent
  },
  {
    path: 'hot-collections',
    component: HotCollectionsComponent
  },
  {
    path: 'edit-profile',
    component: EditProfileComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'cms-page/:slug',
    component: CmsPageComponent
  },
  {
    path: 'collections',
    component: CreateCollectibleComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'import-collection',
    component: ImportCollectionComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'create-single/:id',
    component: CreateSingleCollectibleComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'collections/:collection_slug',
    component: CollectionsComponent
  },
  {
    path: 'collection-details/:collection_slug',
    component: CollectiondetailsComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'activity',
    component: HistoryComponent
  },
  {
    path: 'explore',
    component: ExploreComponent
  },
  {
    path: 'account',
    component: AccountComponent
  },
  {
    path: 'faqs',
    component: FaqComponent
  },
  {
    path: 'edit-item/:id',
    component: EditItemComponent
  }
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
