import { Component, OnInit } from '@angular/core';
import { NftService } from '../../services/nftservice.service'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  facebook_url : any;
  twitter_url : any;
  instagram_url : any;
  youtube_url : any;
  constructor(private nftservice : NftService) { }

  ngOnInit(): void {
    this.initializePage();
  }

  initializePage(){
    this.socialSettings();
  }

  socialSettings(){
    this.nftservice.settings()
    .subscribe(
      (data) => {
        if(data['status'] == 'success'){
          this.facebook_url = data['Furl'] 
          this.twitter_url = data['Turl']
          this.instagram_url = data['Iurl']
          this.youtube_url = data['Yurl']
        }
      }
    )
  }

}
