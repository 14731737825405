// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  infuraId: '3c7eb7651b334a76ac5ba3386e523b15',
  network: 'rinkeby',
  fortmatickey: 'pk_test_5BDB26FB1CDFA95A',
  COINBASE_APP_NAME: 'NFTMarketPlace',
  COINBASE_APP_LOGO_URL: 'https://blockdemo.net/assets/img/logo.png',
  COINBASE_ETH_JSONRPC_URL: 'https://rinkeby.infura.io/v3/',
  COINBASE_CHAIN_ID: 1,
  apiUrl: 'https://admin.blockdemo.net/api/v1/',
  nodeApiUrl: 'https://nftns.stage04.obdemo.com/',
  serviceFee: 2.5,
  FEERECIPIENT: '0x72E161C3EF7ea698aaf25A38FC074cd83Bf12010',
  EXCHANGECONTRACTADDRESS: '0x2051412b796Cb4C2e6bA51731962aab4e2Cfd2F2',
  PROXYREGISTRYCONTRACTADDRESS: '0x5027e22f8c00456c6fab98c3986109a5be26243b',
  DAOCONTRACTADDRESS: '0x50cb085f18c536ba353e527e759EBc3a4D8401df',
  TOKENCONTRACTADDRESS: '0xE024c9cE6aEd648f9245aE54a46E6185ED70c245',
  ATOMICIZERCONTRACTADDRESS: '0xE6bb5f2b8B9fc47d0559233dE44F50A321ac730a',
  TOKEN_1155_CONTRACT_ADDRESS: '0x1AfaABe8E69D334A7f1C95Db8F59D3626145A7B5'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
