<section class="editProfileSection bg-white">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="editProileHead">
                   FAQ's
                </div>
            </div>
        </div>
    </div>
    <div class="exploreTabContent faqsTopBorder">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="editProileHead">
                        <div id="accordion" class="accordion" *ngIf="faqData.length > 0">
                            <div class="faqCard" *ngFor="let faq of faqData;let i = index">
                                <div class="faqheader collapsed" data-toggle="collapse" [attr.href]="'#collapseOne_'+ i">
                                    {{ faq?.question }}
                                </div>
                                <div id="{{'collapseOne_'+ i }}" class="faqContent collapse" data-parent="#accordion" [innerHtml]="faq?.answer">
                                </div>
                            </div>
                                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>








<!-- <section class="editProfileSection">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="editProileHead">
                    <div id="accordion" class="accordion" *ngIf="faqData.length > 0">
                        <div class="card" *ngFor="let faq of faqData;let i = index">
                            <div class="card-header collapsed" data-toggle="collapse" [attr.href]="'#collapseOne_'+ i">
                                <a class="card-title">
                                    {{ faq?.question }}
                                </a>
                            </div>
                            <div id="{{'collapseOne_'+ i }}" class="card-body collapse" data-parent="#accordion" [innerHtml]="faq?.answer">
                            </div>
                        </div>
                                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
