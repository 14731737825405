<footer>
    <div class="footer-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-3">
                    <a href="javascript:void(0);" class="navbar-brand" >
                        <img  src="assets/img/logo.png"> NFT 
                    </a>
                </div>
                <div class="col-6 col-md-3">
                    <div class="footerTitle">Quick Links</div>
                    <ul class="footerList">
                        <li>
                            <a href="javascript:void(0);" [routerLink]="['/cms-page/about-us']">About</a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" [routerLink]="['/faqs']">FAQ's</a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" [routerLink]="['/explore']">Explore</a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" [routerLink]="['/cms-page/how-it-works']">How its work?</a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">Support</a>
                        </li>
                    </ul>
                </div>
                <div class="col-6 col-md-3">
                    <div class="footerTitle">Community</div>
                    <ul class="footerList">
                        <li>
                            <a href="javascript:void(0);">NRT Token</a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">Discussion</a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">Voting</a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">Suggest feature</a>
                        </li>
                    </ul>
                </div>
                <div class="col-12 col-md-3">
                    <div class="footerTitle">Social Media</div>
                    <ul class="footerSociallist">
                        <li>
                            <a href="{{ twitter_url }}" target="_blank">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="{{ instagram_url }}" target="_blank">
                                <i class="fab fa-instagram"></i>
                            </a>
                        </li>
                        <li>
                            <a href="{{ facebook_url }}" target="_blank">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="{{ youtube_url }}" target="_blank">
                                <i class="fab fa-youtube"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="subfooter-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 text-center text-md-left">
                    <div class="copyrightFooter">
                        © NFT, Inc. All rights reserved.
                    </div>
                </div>
                <div class="col-12 col-md-6 text-center text-md-right">
                    <ul class="termpolicyList justify-content-center justify-content-md-end">
                        <li>
                            <a href="javascript:void(0);" [routerLink]="['/cms-page/terms-conditions']">Terms and Condition</a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" [routerLink]="['/cms-page/privacy-policy']">Privacy Policy</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>