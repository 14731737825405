import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { OwlModule } from 'ngx-owl-carousel'
import { HttpClientModule,HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './guards/auth.guard';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { WishlistComponent } from './components/wishlist/wishlist.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AssetsComponent } from './components/assets/assets.component';
import { BidUserComponent } from './components/bid-user/bid-user.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { ImportCollectionComponent } from './components/import-collection/import-collection.component';
import { CmsPageComponent } from './components/cms-page/cms-page.component';
import { ExploreComponent } from './components/explore/explore.component';
import { CollectionsComponent } from './components/collections/collections.component';
import { AccountComponent } from './components/account/account.component';
import { TokenInterceptor } from './token.interceptor';
import { FaqComponent } from './components/faq/faq.component';
import { HistoryComponent } from './components/history/history.component';
import { ToastrModule } from 'ngx-toastr';
import { LoaderComponent } from './loader/loader.component';
import { LoaderService } from './loader/loader.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {CreateSingleCollectibleComponent } from './components/create-single-collectible/create-single-collectible.component';
import {CreateCollectibleComponent } from './components/create-collectible/create-collectible.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { Web3Service } from './web3.service';
import { CollectiondetailsComponent } from './components/collectiondetails/collectiondetails.component';
import { EditItemComponent } from './components/edit-item/edit-item.component';
import { WyvernProtocol } from 'wyvern-js';
import { CommonModule } from '@angular/common';
import { CreatorsComponent } from './components/creators/creators.component';
import { HotCollectionsComponent } from './components/hot-collections/hot-collections.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoaderComponent,
    FooterComponent,
    HomeComponent,
    HotCollectionsComponent,
    WishlistComponent,
    AssetsComponent,
    CreatorsComponent,
    BidUserComponent,
    HistoryComponent,
    EditProfileComponent,
    ImportCollectionComponent,
    CmsPageComponent,
    ExploreComponent,
    CollectionsComponent,
    AccountComponent,
    FaqComponent,
    CreateSingleCollectibleComponent,
    CreateCollectibleComponent,
    CollectiondetailsComponent,
    EditItemComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,  
    OwlModule,
    CarouselModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    ImageCropperModule,
    AutocompleteLibModule,
    ToastrModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,   
      useClass: TokenInterceptor,
      multi: true
    },
    LoaderService,
    // WyvernProtocol,
    Web3Service,AuthGuard
  ],  
  bootstrap: [AppComponent]
})
export class AppModule { }
