<section class="biduserSection">
    <div class="bannerImageSection" style="background-image: url(assets/img/banner_img.jpg);">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="usrShare">
                        <div class="usrProfile" style="background-image: url({{userData.image}});">
                            <img src="{{userData.image}}" alt="" />
                        </div>
                        <!-- <a href="javascript:void(0);" class="shareCard">
                            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="share-all" role="img"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"
                                class="svg-inline--fa fa-share-all fa-w-18">
                                <path fill="currentColor"
                                    d="M470.632 201.371L310.63 41.4C290.59 21.36 256 35.44 256 64.03v72.69C140.5 140.56 0 164.89 0 317.79c0 64.98 33.54 118.26 85.11 155.97 24.19 17.7 58.04-4.92 49.69-34.51-27.7-98.19-.38-122.06 121.2-126.42v71.15c0 28.61 34.61 42.64 54.63 22.63l160.002-159.98c12.491-12.499 12.491-32.759 0-45.259zM288 384.001v-103.9c-145.671 1.326-224.979 22.633-184 167.9-31.51-23.05-72-65.11-72-130.21 0-131.425 125.835-148.345 256-149.69v-104.1l160 160-160 160zm278.628-137.373l-160 159.978c-13.454 13.454-33.492 11.509-45.191.106L544 224.001 361.438 41.294c11.695-11.401 31.723-13.362 45.189.105l160 159.974c12.497 12.497 12.497 32.758.001 45.255z"
                                    class=""></path>
                            </svg>
                            <div class="shareCardProfileName">
                                <h5>Share</h5>
                            </div>
                        </a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="userContentSection">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="bidcontentLeft bidUserLeft">
                        <div class="bidContentHed">
                            {{userData.name}}
                        </div>
                        <a href="javascript:void(0);" class="copyCard">
                            {{userData.address}}
                            <span style="display:none;" id="copyuseraddress">{{userData.address}}</span>
                            <span class="copyIcon"><i class="fal fa-copy" (click)="copyInputMessage('#copyuseraddress')"></i></span>
                        </a>

                        <div class="bidDes">
                            {{userData.bio}}
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="bidcontentRight bidUserRight">
                        <a href="javascript:void(0);" class="followBtn" (click)="followUnfollowUser(userData.id)" *ngIf="followStatus === 'Follow'">
                            <i class="fal fa-user-plus"></i> Follow
                        </a>
                        <a href="javascript:void(0);" class="followBtn" (click)="followUnfollowUser(userData.id)" *ngIf="followStatus === 'Unfollow'">
                            <i class="fal fa-user-plus"></i> Unfollow
                        </a>
                        <!-- <div class="dropdown">
                            <div class="dropListHotBid dropdown-toggle" id="dropdownMenubid" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false"><i class="fal fa-ellipsis-v"></i></div>
                            <div class="dropdown-menu dropdown-menu-right customDropFilter"
                                aria-labelledby="dropdownMenubid">
                                <ul class="filterlistMenu">
                                    <li>
                                        <a href="javascript:void(0);">
                                            Action
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">
                                            Purchase Now
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">
                                            Place a bid
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">
                                            Report
                                        </a>
                                    </li>
                                </ul>


                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="exploreTabhead newBidTab biduserTab">
        <div class="container">
            <div class="row">
                <div class="col-12">

                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="onSale-tab" data-toggle="tab" href="#onSale" role="tab" aria-controls="onSale" aria-selected="true">On Sale</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="collect-tab" data-toggle="tab" href="#collect" role="tab" aria-controls="collect" aria-selected="false">Collections</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="created-tab" data-toggle="tab" href="#created" role="tab" aria-controls="created" aria-selected="false">Created</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="liked-tab" data-toggle="tab" href="#liked" role="tab" aria-controls="liked" aria-selected="false">Liked</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" id="activity-tab" data-toggle="tab" href="#activity" role="tab" aria-controls="activity" aria-selected="false">Activity </a>
                        </li> -->
                        <li class="nav-item">
                            <a class="nav-link" id="following-tab" data-toggle="tab" href="#following" role="tab" aria-controls="following" aria-selected="false">
                                Following
                                <span class="countFollow">{{followingList.length}}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="followers-tab" data-toggle="tab" href="#followers" role="tab" aria-controls="followers" aria-selected="false">
                                Followers
                                <span class="countFollow">{{followersList.length}}</span>
                            </a>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="exploreTabContent">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="onSale" role="tabpanel" aria-labelledby="onSale-tab">
                            <div class="exploreParent">
                                <div class="hotBidCard" *ngFor="let soldProduct of soldProductData">
                                    <div class="hotBidCardImg" style="background-image: url({{soldProduct?.product?.image}});">
                                        <img src="{{soldProduct?.product?.image}}" alt="" />
                                    </div>
                                    <div class="hotBidCardBody">
                                        <div class="hotBidCardHead">{{soldProduct?.product?.name}}</div>
                                        <div class="hotBidCardRank">{{soldProduct?.product?.price}} ETH</div>
                                        <a href="javascript:void(0);" class="placeBidHref">Place a bid</a>
                                        <!-- <a href="javascript:void(0);" class="highestBid">Highest bid <span>0.02 WETH</span></a> -->
                                    </div>

                                    <a href="javascript:void(0);" class="like">
                                        <i class="fa fa-heart"></i>
                                    </a>
                                    <div class="hotBidProfileList">

                                        <div class="hotBidListProfileimg" *ngFor="let user of soldProduct?.product?.owner" style="background-image: url({{user.image}});">
                                            <img src="{{user.image}}" alt="" />
                                        </div>

                                    </div>
                                    <div class="dropListHotBid">
                                        <i class="fal fa-ellipsis-v"></i>
                                    </div>
                                </div>
                                <div class="no-Recordcard" *ngIf="soldProductData.length === 0">
                                    No record found.
                                </div>

                            </div>
                        </div>
                        <div class="tab-pane fade" id="collect" role="tabpanel" aria-labelledby="collect-tab">
                            <div class="exploreParent">
                                <div class="hotBidCard" *ngFor="let collection of collectionData">
                                    <a href="javascript:void(0);" [routerLink]="['/collection-details',collection.slug]">
                                        <img src="{{collection.image}}" alt="" />
                                    </a>
                                    <div class="hotCollectBody">
                                        <a href="javascript:void(0);" [routerLink]="['/collection-details',collection.slug]" class="hotBidCardHead">{{collection.collection_name}}</a>
                                    </div>
                                </div>
                                <div class="no-Recordcard" *ngIf="collectionData.length === 0">
                                    No record found.
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="created" role="tabpanel" aria-labelledby="created-tab">
                            <div class="exploreParent">
                                <div class="hotBidCard" *ngFor="let singleProduct of createdProductData">

                                    <a routerLink="/assets/{{singleProduct.token_id}}" class="placeBidHref">
                                        <div class="hotBidCardImg"
                                            style="background-image: url({{singleProduct.image}});">
                                            <img src="{{singleProduct.image}}" alt="" />
                                        </div>
                                    </a>
                                        <div class="hotBidCardBody">
                                        <a href="javascript:void(0);" [routerLink]="['/assets',singleProduct.token_id]" class="hotBidCardHead">{{singleProduct.name}}</a>
                                        <div class="hotBidCardRank" *ngIf="singleProduct.price">{{singleProduct.price}} ETH</div>
                                        <a routerLink="/assets/{{singleProduct.token_id}}" class="placeBidHref">Place a bid</a>
                                    </div>
                                    <a routerLink="/assets/{{singleProduct.token_id}}" class="placeBidHref">
                                        <div class="dropListHotBid">
                                            <i class="fal fa-ellipsis-v"></i>
                                        </div>
                                    </a>
                                </div>
                                <div class="no-Recordcard" *ngIf="createdProductData.length === 0">
                                    No record found.
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="liked" role="tabpanel" aria-labelledby="liked-tab">
                            <div class="exploreParent">
                                <div class="hotBidCard" *ngFor="let singleProduct of wishlistData">

                                    <a routerLink="/assets/{{singleProduct.product.token_id}}" class="placeBidHref">
                                        <div class="hotBidCardImg"
                                            style="background-image: url({{singleProduct.product.image}});">
                                            <img src="{{singleProduct.image}}" alt="" />
                                        </div>
                                    </a>
                                        <div class="hotBidCardBody">
                                        <a href="javascript:void(0);" [routerLink]="['/assets',singleProduct.product.token_id]" class="hotBidCardHead">{{singleProduct.product.name}}</a>

                                        <div class="hotBidCardRank" *ngIf="singleProduct.product.price">{{singleProduct.product.price}} ETH</div>
                                        <a routerLink="/assets/{{singleProduct.product.token_id}}" class="placeBidHref">Place a bid</a>
                                    </div>
                                    <a routerLink="/assets/{{singleProduct.product.token_id}}" class="placeBidHref">
                                        <div class="dropListHotBid">
                                            <i class="fal fa-ellipsis-v"></i>
                                        </div>
                                    </a>
                                </div>
                                <div class="no-Recordcard" *ngIf="wishlistData.length === 0">
                                    No record found.
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="activity" role="tabpanel" aria-labelledby="activity-tab">
                            <div class="row">
                                <div class="col-12 col-md-8 px-0">

                                    <ul class="activityLeft customScroll">
                                        <li>
                                            <a href='javascript:void(0);'>
                                                <div class="activityCard">
                                                    <div class="activityImg" style="background-image: url(assets/img/slider-1.jpg);">
                                                        <img src="assets/img/slider-1.jpg" alt="" />
                                                        <span class="excIcon"><i class="far fa-exchange-alt"></i></span>
                                                    </div>
                                                    <div class="activityContent">
                                                        <div class="activityContnetHead">
                                                            Holy Apple
                                                        </div>
                                                        <div class="activityTransform">
                                                            <div class="activityTRanferd">transfered from</div>
                                                            <div class="transferPartner">
                                                                <div class="frstTrnsfer" style="background-image:url(assets/img/procss-1.jpg)">
                                                                    <img src="assets/img/procss-1.jpg" alt="" />
                                                                </div>
                                                                <span>Hugs</span> to
                                                                <div class="frstTrnsfer" style="background-image:url(assets/img/procss-2.jpg)">
                                                                    <img src="assets/img/procss-2.jpg" alt="" />
                                                                </div>
                                                                <span>Lirona</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="activityTime">
                                                        59 minutes ago
                                                        <i class="far fa-external-link-alt"></i>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>


                                        <li>
                                            <a href='javascript:void(0);'>
                                                <div class="activityCard">
                                                    <div class="activityImg" style="background-image: url(assets/img/procss-1.jpg);">
                                                        <img src="assets/img/procss-2.jpg" alt="" />
                                                        <span class="excIcon"><i class="far fa-exchange-alt"></i></span>
                                                    </div>
                                                    <div class="activityContent">
                                                        <div class="activityContnetHead">
                                                            Holy Apple
                                                        </div>
                                                        <div class="activityTransform">
                                                            <div class="activityTRanferd">transfered from</div>
                                                            <div class="transferPartner">
                                                                <div class="frstTrnsfer" style="background-image:url(assets/img/procss-1.jpg)">
                                                                    <img src="assets/img/procss-1.jpg" alt="" />
                                                                </div>
                                                                <span>Hugs</span> to
                                                                <div class="frstTrnsfer" style="background-image:url(assets/img/procss-2.jpg)">
                                                                    <img src="assets/img/procss-2.jpg" alt="" />
                                                                </div>
                                                                <span>Lirona</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="activityTime">
                                                        59 minutes ago
                                                        <i class="far fa-external-link-alt"></i>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>

                                        <li>
                                            <a href='javascript:void(0);'>
                                                <div class="activityCard">
                                                    <div class="activityImg" style="background-image: url(assets/img/procss-2.jpg);">
                                                        <img src="assets/img/procss-2.jpg" alt="" />
                                                        <span class="excIcon"><i class="far fa-exchange-alt"></i></span>
                                                    </div>
                                                    <div class="activityContent">
                                                        <div class="activityContnetHead">
                                                            Holy Apple
                                                        </div>
                                                        <div class="activityTransform">
                                                            <div class="activityTRanferd">transfered from</div>
                                                            <div class="transferPartner">
                                                                <div class="frstTrnsfer" style="background-image:url(assets/img/procss-1.jpg)">
                                                                    <img src="assets/img/procss-1.jpg" alt="" />
                                                                </div>
                                                                <span>Hugs</span> to
                                                                <div class="frstTrnsfer" style="background-image:url(assets/img/procss-2.jpg)">
                                                                    <img src="assets/img/procss-2.jpg" alt="" />
                                                                </div>
                                                                <span>Lirona</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="activityTime">
                                                        59 minutes ago
                                                        <i class="far fa-external-link-alt"></i>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>

                                        <li>
                                            <a href='javascript:void(0);'>
                                                <div class="activityCard">
                                                    <div class="activityImg" style="background-image: url(assets/img/slider-1.jpg);">
                                                        <img src="assets/img/slider-1.jpg" alt="" />
                                                        <span class="excIcon"><i class="far fa-exchange-alt"></i></span>
                                                    </div>
                                                    <div class="activityContent">
                                                        <div class="activityContnetHead">
                                                            Holy Apple
                                                        </div>
                                                        <div class="activityTransform">
                                                            <div class="activityTRanferd">transfered from</div>
                                                            <div class="transferPartner">
                                                                <div class="frstTrnsfer" style="background-image:url(assets/img/procss-1.jpg)">
                                                                    <img src="assets/img/procss-1.jpg" alt="" />
                                                                </div>
                                                                <span>Hugs</span> to
                                                                <div class="frstTrnsfer" style="background-image:url(assets/img/procss-2.jpg)">
                                                                    <img src="assets/img/procss-2.jpg" alt="" />
                                                                </div>
                                                                <span>Lirona</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="activityTime">
                                                        59 minutes ago
                                                        <i class="far fa-external-link-alt"></i>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>


                                        <li>
                                            <a href='javascript:void(0);'>
                                                <div class="activityCard">
                                                    <div class="activityImg" style="background-image: url(assets/img/procss-1.jpg);">
                                                        <img src="assets/img/procss-2.jpg" alt="" />
                                                        <span class="excIcon"><i class="far fa-exchange-alt"></i></span>
                                                    </div>
                                                    <div class="activityContent">
                                                        <div class="activityContnetHead">
                                                            Holy Apple
                                                        </div>
                                                        <div class="activityTransform">
                                                            <div class="activityTRanferd">transfered from</div>
                                                            <div class="transferPartner">
                                                                <div class="frstTrnsfer" style="background-image:url(assets/img/procss-1.jpg)">
                                                                    <img src="assets/img/procss-1.jpg" alt="" />
                                                                </div>
                                                                <span>Hugs</span> to
                                                                <div class="frstTrnsfer" style="background-image:url(assets/img/procss-2.jpg)">
                                                                    <img src="assets/img/procss-2.jpg" alt="" />
                                                                </div>
                                                                <span>Lirona</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="activityTime">
                                                        59 minutes ago
                                                        <i class="far fa-external-link-alt"></i>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>

                                        <li>
                                            <a href='javascript:void(0);'>
                                                <div class="activityCard">
                                                    <div class="activityImg" style="background-image: url(assets/img/procss-2.jpg);">
                                                        <img src="assets/img/procss-2.jpg" alt="" />
                                                        <span class="excIcon"><i class="far fa-exchange-alt"></i></span>
                                                    </div>
                                                    <div class="activityContent">
                                                        <div class="activityContnetHead">
                                                            Holy Apple
                                                        </div>
                                                        <div class="activityTransform">
                                                            <div class="activityTRanferd">transfered from</div>
                                                            <div class="transferPartner">
                                                                <div class="frstTrnsfer" style="background-image:url(assets/img/procss-1.jpg)">
                                                                    <img src="assets/img/procss-1.jpg" alt="" />
                                                                </div>
                                                                <span>Hugs</span> to
                                                                <div class="frstTrnsfer" style="background-image:url(assets/img/procss-2.jpg)">
                                                                    <img src="assets/img/procss-2.jpg" alt="" />
                                                                </div>
                                                                <span>Lirona</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="activityTime">
                                                        59 minutes ago
                                                        <i class="far fa-external-link-alt"></i>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>

                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="activityRight">
                                        <div class="customDropFilter">
                                            <div class="sortBy">Filters</div>

                                            <ul class="filterlistMenu">
                                                <li>
                                                    <a href="javascript:void(0);" class="active">
                                                        Listing
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        Purchasees
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        Sales
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        Transfers
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        Burns
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        Bids
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        Likes
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        Followings
                                                    </a>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="following" role="tabpanel" aria-labelledby="following-tab">
                            <div class="row">
                                <div class="col-12 col-md-8 offset-md-2 col-lg-5 mx-auto px-0">
                                    <ul class="activityLeft customScroll followersList">
                                        <li *ngFor="let following of followingList">
                                            <div class="followCard">
                                                <div class="followImg active" style="background-image: url({{following.user.image}});">
                                                    <img src="{{following.user.image}}" alt="" />
                                                </div>
                                                <div class="followContent">
                                                    <div class="followUser" *ngIf="following.user.name">@{{following.user.name}}</div>
                                                </div>
                                                <div class="followCount" *ngIf="following.user.address">{{following.user.address | slice:0:15}}</div>
                                                <!-- <a href="javascript:void(0);" class="followBtn">
                                                    <i class="fal fa-user-plus"></i>Follow
                                                </a> -->
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="no-Recordcard" *ngIf="followingList.length === 0">
                                        No record found.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="followers" role="tabpanel" aria-labelledby="followers-tab">
                            <div class="row">
                                <div class="col-12 col-md-8 offset-md-2 col-lg-5 mx-auto px-0">
                                    <ul class="activityLeft customScroll followersList">
                                        <li *ngFor="let follower of followersList">
                                            <div class="followCard">
                                                <div class="followImg active" style="background-image: url({{follower.followuser.image}});">
                                                    <img src="{{follower.followuser.image}}" alt="" />
                                                </div>
                                                <div class="followContent">
                                                    <div class="followUser" *ngIf="follower.followuser.name">@{{follower.followuser.name}}</div>
                                                </div>
                                                <div class="followCount" *ngIf="follower.followuser.address">{{follower.followuser.address | slice:0:15}}</div>
                                                <!-- <a href="javascript:void(0);" class="followBtn">
                                                    <i class="fal fa-user-plus"></i>Follow
                                                </a> -->
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="no-Recordcard" *ngIf="followersList.length === 0">
                                        No record found.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>