<section class="editProfileSection bg-white">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="editProileHead">
                   Hot Collections
                </div>
            </div>
        </div>
    </div>
    <div class="exploreTabContent faqsTopBorder">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="hotCollectparent">
                        <div class="hotCollectionCard" *ngFor="let collection of hotCollection">
                            <a routerLink="/collections/{{collection.slug}}" class="placeBidHref">
                                <div class="hotCollectImg" style="background-image: url({{collection.image}});">
                                    <img src="{{collection.image}}" alt="" />
                                </div>
                                <div class="hotCollectBody">
                                    <div class="hotBidCardHead">{{collection.collection_name}}</div>
                                    <div class="hotBidCardRank"><span>By </span> {{collection.username}}</div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>