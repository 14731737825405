import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { NftService } from '../../services/nftservice.service';
import { FormBuilder, FormGroup,FormArray ,Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.css']
})
export class WishlistComponent implements OnInit {

  allProductData : any = [];
  keyword = 'name';

  constructor(private nftservice: NftService,private locationpath:Location, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute,private router :Router,private toastr: ToastrService, private globalService: GlobalService) {
    this.activatedRoute.queryParams.subscribe(params => {

    });    
   }

  ngOnInit(): void {
      localStorage.removeItem('cat_id');
      this.initializePage();
  }

  initializePage(){
    this.wishlistData();
  }

  wishlistData(){
    this.nftservice.wishlistData()
    .subscribe(
      (data) => {
        if(data['status'] == 'success'){
          console.log(data);
          this.allProductData = data['data']
        
        }
      }
    )
  }

  updateWistlist(product_id) {
    const fd: FormData = new FormData();
    fd.append('product_id', product_id);  
    this.nftservice.updateWistlist(fd)
      .subscribe(
        (data) => {
          console.log(data);
          if(data['status'] == 'success'){
            this.toastr.success(data['message']);
            this.wishlistData();
          } else {
            this.toastr.error(data['message']);
          }
        }
      );
  }
  

}