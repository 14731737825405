<section class="banner-sec">

    <owl-carousel-o class="banner-images" [options]="banner">
        <ng-container class="item" *ngFor="let slider of sliderData">

            <ng-template carouselSlide [id]="slider.id">
                <div class="homeSliderBg" [ngStyle]="{'background-image' : 'url('+ slider.image_url + ')'}">

                    <img src={{slider.image_url}} alt="">

                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>


    <div class="search-banner text-center">
        <div class="ng-autocomplete">
            <ng-autocomplete [data]="searchData" [searchKeyword]="keyword" placeholder="Search by creator, collectible or collection" (selected)='selectEvent($event)' (keyUp)='onChangeSearch($event)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>


            <ng-template #itemTemplate let-item>
                <a [routerLink]="['/user',item.address]" [innerHTML]="item.name" *ngIf="item.type == 'user'"></a>
                <a [routerLink]="['/collection-details', item.slug]" [innerHTML]="item.name" *ngIf="item.type == 'collection'"></a>
                <a [routerLink]="['/assets', item.token_id]" [innerHTML]="item.name" *ngIf="item.type == 'product'"></a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
            </ng-template>



        </div>

        <!-- <input type="search" placeholder="Search by creator, collectible or collection" (keyup)="searching($event)">
        <span><svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="search" role="img"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-search fa-w-16 fa-2x">
                <path fill="currentColor"
                    d="M508.5 481.6l-129-129c-2.3-2.3-5.3-3.5-8.5-3.5h-10.3C395 312 416 262.5 416 208 416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c54.5 0 104-21 141.1-55.2V371c0 3.2 1.3 6.2 3.5 8.5l129 129c4.7 4.7 12.3 4.7 17 0l9.9-9.9c4.7-4.7 4.7-12.3 0-17zM208 384c-97.3 0-176-78.7-176-176S110.7 32 208 32s176 78.7 176 176-78.7 176-176 176z"
                    class=""></path>
            </svg></span> -->

    </div>

</section>

<section class="top-seller py-5">
    <div class="container pt-5">
        <div class="heading pt-3">
            <div class="row">
                <div class="col-md-3 text-left">
                    <h3> Top Sellers</h3>
                </div>
                <div class="col-md-6 text-center">
                    <div class="d-flex justify-content-center">
                        <select class="form-control custom-select mr-5" (change)="filterbyperiod($event)">
                            <option value='day'>1 day</option>
                            <option value='week'>1 week</option>
                            <option value='month' selected>1 month</option>
                            <option value='year'>1 year</option>
                        </select>
                        <h3> Top Buyer</h3>
                    </div>
                </div>
                <div class="col-md-3 text-right">
                    <select class="form-control custom-select" (change)="filterbbyperiod($event)">
                        <option value='day'>1 day</option>
                        <option value='week'>1 week</option>
                        <option value='month' selected>1 month</option>
                        <option value='year'>1 year</option>
                    </select>
                </div>
            </div>

        </div>

        <div class="row pt-5">

            <div class="col-12 col-md-12 px-0">
                <div class="row">

                    <div class="col-12 col-sm-6 col-md-3" *ngFor="let seller of topSeller">
                        <div class="seller-block bg-white p-3 ">
                            <div class="d-inline-flex align-items-center">
                                <div class="seller-profile">
                                    <img src="{{ seller?.image }}">
                                </div>
                                <div class="">
                                    <h5> {{ seller?.name }}</h5>
                                    <!-- <span> 24.23 ETH</span> -->
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-12 col-md-12 px-0">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-3" *ngFor="let buyer of topBuyer">
                        <div class="seller-block bg-white p-3 ">
                            <div class="d-inline-flex align-items-center">
                                <div class="seller-profile">
                                    <img src="{{ buyer.buyer_name?.image }}">
                                </div>
                                <div class="">
                                    <h5> {{ buyer.buyer_name?.name }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>

        <!-- <div class="row pt-5">
            <div class="col-12 col-sm-6 col-md-3" *ngFor="let buyer of topBuyer">
                <div class="seller-block bg-white p-3 ">
                    <div class="d-inline-flex align-items-center">
                        <div class="seller-profile">
                            <img src="{{ buyer.buyer_name?.image }}">
                        </div>
                        <div class="">
                            <h5> {{ buyer.buyer_name?.name }}</h5>
                            <span> 24.23 ETH</span>
                        </div>
                    </div>
                </div>
            </div>
            
        </div> -->


    </div>
</section>

<section class="hot-bids pb-5">
    <div class="container">
        <div class="heading pb-5">
            <div class="row align-items-center">
                <div class="col">
                    <h3>Hot bids</h3>
                </div>
                <!-- <div class="col-auto"><a class="text-right"> View All<i class="fa fa-angle-right pl-2"></i></a></div> -->
            </div>

        </div>


        <owl-carousel-o class="hotBidSlider" [options]="hotBid">
            <ng-container class="item" *ngFor="let hotBid of hotBids">

                <ng-template carouselSlide [id]="hotBid.id">
                    <div class="hotBidCard">
                        <a routerLink="/assets/{{hotBid.product_name?.token_id}}" class="placeBidHref">
                            <div class="hotBidCardImg" style="background-image: url({{hotBid.product_name?.image}});">
                                <img src="{{ hotBid.product_name?.image }}" alt="" />
                            </div>
                            <div class="hotBidCardBody">
                                <div class="hotBidCardHead"> {{ hotBid.product_name?.name }} #07</div>
                                <div class="hotBidCardRank" *ngIf="hotBid.product_name?.price">{{ hotBid.product_name?.price }} ETH</div>
                                Place a bid
                            </div>
                        </a>

                        <a [ngClass]="{'active': hotBid.wishlist === 'Yes'}" href="javascript:void(0);" class="like" (click)="updateWistlist(hotBid.product_name?.id)">
                            <i class="fa fa-heart"></i>
                        </a>

                        <a routerLink="/assets/{{hotBid.product_name?.token_id}}" class="placeBidHref">
                            <div class="hotBidProfileList">

                                <div class="hotBidListProfileimg" style="background-image: url({{hotBid.bidder_name?.image}});">
                                    <img src="{{hotBid.bidder_name?.image}}" alt="" />
                                </div>

                            </div>
                            <div class="dropListHotBid">
                                <i class="fal fa-ellipsis-v"></i>
                            </div>
                        </a>
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>

        <div class="alert alert-danger" *ngIf="hotBids.length === 0">
          <strong>Nothing here!</strong>
        </div>

    </div>
</section>


<!-- ----------- hot collection s -->

<section class="hot-bids pb-5">
    <div class="container">
        <div class="heading pb-5">
            <div class="row align-items-center">
                <div class="col">
                    <h3>Hot collections</h3>
                </div>
                <div class="col-auto"><a class="text-right" routerLink="/hot-collections">View All<i class="fa fa-angle-right pl-2"></i></a></div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="hotCollectparent">
                    <div class="hotCollectionCard" *ngFor="let collection of hotCollection">
                        <a routerLink="/collections/{{collection.slug}}" class="placeBidHref">
                            <div class="hotCollectImg" style="background-image: url({{collection.image}});">
                                <img src="{{collection.image}}" alt="" />
                            </div>
                            <div class="hotCollectBody">
                                <div class="hotBidCardHead">{{collection.collection_name}}</div>
                                <div class="hotBidCardRank"><span>By </span> {{collection.username}}</div>
                            </div>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    </div>



</section>

<!-- --------------- feature creator -->

<section class="hot-bids pb-5">
    <div class="container">
        <div class="heading pb-5">
            <div class="row align-items-center">
                <div class="col">
                    <h3>Featured creators</h3>
                </div>
                <div class="col-auto">
                    <a class="text-right" routerLink="/creators">View All<i class="fa fa-angle-right pl-2"></i></a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="featuredParent">
                    <div class="featuredCard" *ngFor="let creator of creatorData">
                        <a routerLink="/user/{{creator.address}}" class="placeBidHref">
                            <div class="featureImg" style="background-image:url({{creator.image}})">
                                <img src="{{ creator.image}}" alt="" />
                            </div>
                            <div class="featureBody">
                                <div class="hotBidCardHead">{{ creator.name }}</div>
                                <!-- <div class="featureLinkName">@jonmonaghan</div> -->
                            </div>
                            <div class="featureUser" style="background-image: url({{creator.image}});">
                                <img src="{{creator.image}}" alt="" />
                            </div>
                        </a>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>



<!-- -----explore section  -->

<section class="hot-bids pb-5">
    <div class="container">
        <div class="heading pb-3">
            <div class="row align-items-center">
                <div class="col">
                    <h3>Explore</h3>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="exploreTabhead">
                    <div class="exploreTabFilter">
                        <div class="expScroll">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="all-tab" data-toggle="tab" href="#all" role="tab" aria-controls="all" aria-selected="true">All</a>
                                </li>
                                <li class="nav-item" *ngFor="let category of exploreData">
                                    <a class="nav-link" id="art-tab" data-toggle="tab" href="#{{ category.name}}" role="tab" aria-controls="art" aria-selected="false" (click)="categoryWiseProduct(category.id)">{{ category.name}}</a>
                                </li>


                            </ul>
                        </div>
                        <div class="filterMenu">
                            <div class="dropdown">
                                <a href='javascript:void(0);' class="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fal fa-sliders-h"></i> Filters
                                </a>
                                <div class="dropdown-menu dropdown-menu-right customDropFilter" aria-labelledby="dropdownMenuButton">
                                    <div class="sortBy">Sort by</div>

                                    <ul class="filterlistMenu">
                                        <li>
                                            <a href="javascript:void(0);" class="active">
                                                Recently added
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);" (click)="filterByLowPrice('asc')">
                                               Cheapest
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);" (click)="filterByLowPrice('desc')">
                                              Highest Price
                                            </a>
                                        </li>
                                        <!-- <li>
                                            <a href="javascript:void(0);">
                                               Most Liked
                                            </a>
                                        </li> -->
                                        <div class="dropdown-divider"></div>
                                    </ul>

                                    <div class="sortBy">Options</div>

                                    <ul class="verifiedMenu filterlistMenu">
                                        <li>
                                            <a href="javascript:void(0);">
                                               Verified only
                                                 <div class="checkCustom">
                                                    <input type="checkbox" hidden id='cuscheck_1'>
                                                    <label class="customLabelcheck" for="cuscheck_1"></label>
                                                </div>
                                            </a>
                                        </li>

                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                            <div class="exploreParent">
                                <div class="hotBidCard" *ngFor="let allProduct of allProductData">

                                    <a routerLink="/assets/{{allProduct.token_id}}" class="placeBidHref">
                                        <div class="hotBidCardImg" style="background-image: url({{allProduct.image}});">
                                            <img src="{{allProduct.image}}" alt="" />
                                        </div>
                                        <div class="hotBidCardBody">
                                            <a href="javascript:void(0);" [routerLink]="['/assets',allProduct.token_id]" class="hotBidCardHead">{{allProduct.name}}</a>
                                            <div class="hotBidCardRank" *ngIf="allProduct.price">{{allProduct.price}} ETH</div>
                                            <a routerLink="/assets/{{allProduct.token_id}}" class="placeBidHref">Place a bid</a>
                                            <!-- <a href="javascript:void(0);" class="highestBid">Highest bid <span>0.02 WETH</span></a> -->
                                        </div>
                                    </a>
                                    <a [ngClass]="{'active': allProduct.wishlist === 'Yes'}" href="javascript:void(0);" class="like" (click)="updateWistlist(allProduct.id)">
                                        <i class="fa fa-heart"></i>
                                    </a>

                                    <a routerLink="/assets/{{allProduct.token_id}}" class="placeBidHref">
                                        <div class="hotBidProfileList">

                                            <div class="hotBidListProfileimg" *ngFor="let owner of allProduct.owner" style="background-image: url({{owner.image}});">
                                                <img src="{{owner.image}}" alt="" />
                                            </div>

                                        </div>
                                        <div class="dropListHotBid">
                                            <i class="fal fa-ellipsis-v"></i>
                                        </div>
                                    </a>
                                </div>


                            </div>
                            <div class="loadMoreTb" *ngIf="total > page*per_page && !loading">
                                <a href="javascript:void(0);" class="loadBtn" (click)="loadMore()">Load more items</a>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="{{ category.name}}" role="tabpanel" aria-labelledby="art-tab" *ngFor="let category of exploreData">
                            <div class="exploreParent">
                                <div class="hotBidCard" *ngFor="let product of productData">

                                    <a routerLink="/assets/{{product.token_id}}" class="highestBid">
                                        <div class="hotBidCardImg" style="background-image: url({{product.image}});">
                                            <img src="{{ product.image}}" alt="" />
                                        </div>
                                        <div class="hotBidCardBody">
                                            <a href="javascript:void(0);" [routerLink]="['/assets',product.token_id]" class="hotBidCardHead">{{ product.name}}</a>
                                            <div class="hotBidCardRank" *ngIf="product.price">{{ product.price}} ETH</div>
                                            <!-- <a href="javascript:void(0);" class="placeBidHref">Place a bid</a> -->
                                            <a routerLink="/assets/{{product.token_id}}" class="highestBid">Highest bid <span>0.02

                                                WETH</span></a>
                                        </div>
                                    </a>
                                    <a [ngClass]="{'active': product.wishlist === 'Yes'}" href="javascript:void(0);" class="like" (click)="updateWistlist(product.id)">
                                        <i class="fa fa-heart"></i>
                                    </a>
                                    <a routerLink="/assets/{{product.token_id}}" class="placeBidHref">
                                        <div class="hotBidProfileList">

                                            <div class="hotBidListProfileimg" *ngFor="let owner of product?.owner" style="background-image: url({{owner.image}});">
                                                <img src="{{owner.image}}" alt="" />
                                            </div>

                                        </div>
                                        <div class="dropListHotBid">
                                            <i class="fal fa-ellipsis-v"></i>
                                        </div>
                                    </a>
                                </div>

                            </div>

                            <div class="loadMoreTb" *ngIf="totalP > page1*per_page && !loading">
                                <a href="javascript:void(0);" class="loadBtn" (click)="loadMoreP(category.id)">Load more items</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>