import { Component, OnInit,  HostListener } from '@angular/core';
import { Router ,NavigationEnd} from '@angular/router';
import { NftService } from '../../services/nftservice.service';
import { GlobalService } from '../../services/global.service';
import { Web3Service } from 'src/app/web3.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

declare var copyToClipboard: Function;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  menuShow: boolean = false;
  current_account: any;
  currntaccountbalance= '0';
  currentUser : any;
  notificationsList : any = [];
  subscription: Subscription;
  
  constructor(
	private router : Router,
	private nftservice : NftService,
	public web3Service: Web3Service,
	private globalservice : GlobalService,
	private toastr: ToastrService
  ) { 
		var self =	this;

    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        this.menuShow = false;
        //console.log(this.menuShow);
        let bodyParm = document. getElementsByTagName('body')[0];
        bodyParm.classList.remove('overflow');
      }
    });
		this.web3Service.refreshAccounts();
		this.subscription = this.globalservice.updateUserLogin.subscribe( (res) => {
				setTimeout(function(){
					self.current_account 			=	self.web3Service.getFirstAccount();
					
					self.web3Service.getCurrntAccountBalance().then((result) => {
						self.currntaccountbalance 	=	result;
					});
					var type	=	localStorage.getItem('type');
					self.nftservice.loginregister(self.current_account)
					.subscribe(
					  (res) => {
						if(res['status'] == 'success'){
							//localStorage.removeItem('logout');
							localStorage.setItem('type', type);
							localStorage.setItem('currentUser', JSON.stringify(res['data']));
							localStorage.setItem('token', res['token']);
							self.globalservice.refreshUserData.next(true);
							setTimeout(function(){
								self.currentUser  = JSON.parse(localStorage.getItem('currentUser'));		
								console.log("Header" + self.currentUser.id);
							}, 100);
						}
					});
				}, 400);
    });
  }

	ngOnInit(): void {
		this.accessAccount();
		this.getUserNotificationList();
		this.currentUser  = JSON.parse(localStorage.getItem('currentUser'));	
	}

  menuToggle() {

    this.menuShow = !this.menuShow;
    let bodyParm = document. getElementsByTagName('body')[0];
    if(this.menuShow == true){
       bodyParm.classList.add('overflow');
    }else{
      bodyParm.classList.remove('overflow');
    }
    
  }
  
	async connectToWallet(type) {
		
		var result 	=	await this.web3Service.connectToWallet(type);
		var self =	this;
		
		if(result.code==200){
			setTimeout(function(){
				
				self.current_account 			=	self.web3Service.getFirstAccount();
				
				self.web3Service.getCurrntAccountBalance().then((result) => {
					self.currntaccountbalance 	=	result;
				});
				
				self.nftservice.loginregister(self.current_account)
				.subscribe(
				  (res) => {
					if(res['status'] == 'success'){
						localStorage.removeItem('logout');
						localStorage.setItem('type', type);
						localStorage.setItem('currentUser', JSON.stringify(res['data']));
						localStorage.setItem('token', res['token']);
						setTimeout(function(){
							self.currentUser  = JSON.parse(localStorage.getItem('currentUser'));		
						}, 100);
					}
				});
			}, 1000);
		}else{
			this.toastr.error("Error in connecting account.");
		}
	}
	
	async accessAccount() {
		
		var type	=	localStorage.getItem('type');
		
		if(type && !localStorage.getItem('logout')){
			await this.connectToWallet(type);
		}else{
			localStorage.removeItem('type');
			localStorage.removeItem('currentUser');
			localStorage.removeItem('token');
		}
	}
	
	async signInToMetamask() {
		
		var self =	this;
		
		var message = "Welcome to NFTMarketplace! \n \n \n Click 'Sign' to sign in. No password needed! \n \n \n I accept the NFTMarketplace Terms of Service: http://blockdemo.net/ ";
		var result 	=	await self.web3Service.signIn(message);
		
		/* 
		self.mainService.userDetails(self.current_account).subscribe(res => {
			if(res.status=="success"){
				localStorage.setItem('authUser', JSON.stringify(res));
				this.toastr.success("Account logged in successfully.");
			}else{
				this.toastr.error("Error in loging into account.");
			}
		}); */
	
	}

	logout(){
		localStorage.removeItem('type');
		localStorage.removeItem('currentUser');
		localStorage.removeItem('token');
		localStorage.setItem('logout','true');
		this.currentUser = "";
		this.toastr.success('Logout Successfully');
	}

  ngAfterViewInit() {
    var HeadH = document.getElementById('header').clientHeight;
   
  }

	copyInputMessage(inputElement){
		copyToClipboard(inputElement);
		this.toastr.success('Copied!');
	}

  @HostListener('window:scroll', [])
  onWindowScroll() {
    // do some stuff here when the window is scrolled
      const verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    var navbar = document.getElementById('header');
    var HeadH = document.getElementById('header').clientHeight;

    if (verticalOffset < HeadH) {
       navbar.classList.remove('is-sticky');
       document.getElementById('fitBody').style.paddingTop = '0';
    }
    if (verticalOffset > HeadH) {
      if (!navbar.classList.contains('is-sticky')) {
        navbar.classList.add('is-sticky');
        document.getElementById('fitBody').style.paddingTop = HeadH + 'px';
      }
    }
    if (verticalOffset > HeadH * 2) {
      if (!navbar.classList.contains('awake')) {
        navbar.classList.add('awake');
      }
    }
    if (verticalOffset < HeadH * 2) {
      if (navbar.classList.contains('awake')) {
        navbar.classList.remove('awake');
      }
    }
  }

  getUserNotificationList(){
    this.nftservice.getUserNotificationList()
    .subscribe(
      (x) => {
        if(x['status'] == 'success'){
          this.notificationsList = x['notificationsList']
        }
      });
  }



}
