<section class="editProfileSection">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="editProileHead">
                    Create single collectible
                    <a href="javascript:void(0);" [routerLink]="['/assets',productData?.token_id]">Back</a>
                    <a href="javascript:void(0);">Manage collectible type</a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="uploadSection">
                    <div class="uploadSectionHead">Upload file</div>

                    <div class="uploadCard">
                        <form [formGroup]="editItemForm" (ngSubmit)="onSubmit(editItemForm.value)">
                            <div class="uploadImgSect">
                                <div class="uploadNote mb-3">PNG, GIF,WEBP, MP4 or MP3. Max 30mb.</div>
                                <!-- <div class="customFile">
                                    <img [src]="imgsrc" id="upload_img" class="img-fluid mr-4" height="100" width="100">
                                    <input type="file" id="prfImg" (change)="onFileChange($event)" [ngClass]="{ 'is-invalid': submitted && f.image.errors }" class="form-control" hidden>
                                    <div *ngIf="submitted && f.image.errors" class="invalid-feedback">
                                        <div *ngIf="f.image.errors.required">Image is required</div>
                                    </div>
                                    <label for="prfImg">
                                        Choose file
                                    </label>
                                </div> -->
                                <div class="createSinglImg">
                                    <div class="profieImgView" [ngStyle]="{'background-image': 'url(' + imgsrc + ')'}">
                                        <img [src]="imgsrc" id="upload_img">
                                    </div>
                                    <div class="profileViewContent">
                                        <div class="customFile">
                                            <input type="file" id="prfImg" (change)="onFileChange($event)"
                                                [ngClass]="{ 'is-invalid': submitted && f.image.errors }"
                                                class="form-control" hidden>
                                            <label for="prfImg">
                                                Choose file
                                            </label>
                                            <div *ngIf="submitted && f.image.errors" class="invalid-feedback">
                                                <div *ngIf="f.image.errors.required">Image is required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="form-group ">
                                <div class="createForm">
                                    <div class="createFormLabel">Put on sale </div>
                                    <div class="checkCustom">
                                        <input  type="checkbox" formControlName="on_sale" hidden="" id="cuscheck_1">
                                        <label for="cuscheck_1" class="customLabelcheck"></label>
                                    </div>
                                </div>
                                <div class="createSubLabel">You’ll receive bids on this item</div>
                            </div>

                            <div class="form-group ">
                                <div class="createForm">
                                    <div class="createFormLabel">Instant sale price</div>
                                    <div class="checkCustom">
                                        <input  type="checkbox" formControlName="instant_sale" hidden="" (click)="showPrice()" id="cuscheck_2">
                                        <label for="cuscheck_2" class="customLabelcheck"></label>
                                    </div>
                                </div>
                                <div class="createSubLabel">Enter the price for which the item will be instantly sold</div>
                            </div>

                            <!-- ------------  instant sale price when checked -->

                            <div class="form-group borderFormGroup" id="price_piece" style="display: none;">
                                <div class="createFormLabel">Enter price for one piece</div>
                                <div class="dropInput">
                                    <input type="number" formControlName="price" placeholder='' class="form-control"/>

                                    <div class="dropdown currencyDrop">
                                        <a class="dropdown-toggle" href="javascript:void(0);" role="button" id="currencyLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            ETH
                                        </a>
                                      
                                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="currencyLink">
                                          <a class="dropdown-item" href="javascript:void(0);">ETH</a>
                                          <a class="dropdown-item" href="javascript:void(0);">EURO</a>
                                        </div>
                                      </div>
                                </div>

                               
                                <ul class="serviceFeeInput">
                                    <li>
                                        <div class="serviceName">Service fee</div>
                                        <div class="serviceAmt">2.5%</div>
                                    </li>
                                    <li>
                                        <div class="serviceName">You will receive</div>
                                        <div class="serviceAmt">0 ETH <span>$0.00</span></div>
                                    </li>
                                </ul>
                               
                            </div>



                            <!-- <div class="form-group ">
                                <div class="createForm">
                                    <div class="createFormLabel">Unlock once purchased </div>
                                    <div class="checkCustom">
                                        <input  type="checkbox" checked hidden="" id="cuscheck_3">
                                        <label for="cuscheck_3" class="customLabelcheck"></label>
                                    </div>
                                </div>
                                <div class="createSubLabel">Content will be unlocked after successful transaction</div>
                            </div> -->

                            <!-- ---  unlock once purchased active then show -->

                            <!-- <div class="form-group borderFormGroup">
                                <div class="labelMain">Digital key, code to redeem or link to a file...</div>
                                <input type="text" placeholder='' class="form-control"/>
                                <div class="unlockNote">Tip: Markdown syntax is supported</div>
                            </div> -->


                            <div class="form-group" style="display:none;">
                                <div class="labelMain">Choose collection</div>

                                <div class="labelRadioFlex">
                                    <div class="labelRadio">
                                        <input type="radio" hidden name="chooseCollection" id="createRadio_1"/>
                                        <label for="createRadio_1" class="collectionLabel">
                                            <div class="collectCircle">
                                                <i class="fal fa-plus"></i>
                                            </div>
                                            
                                            <div class="collectSubHead">ERC-1155</div>
                                        </label>
                                    </div>

                                    <div class="labelRadio">
                                        <input type="radio" hidden name="chooseCollection" id="createRadio_2" checked="checked"/>
                                        <label for="createRadio_2" class="collectionLabel">
                                            <div class="collectCircle">N</div>
                                            
                                            <div class="collectSubHead">NFT</div>
                                        </label>
                                    </div>
                                </div>

                            </div>


                            <div class="form-group">
                                <div class="createFormLabel">Name</div>                                
                                <input type="text" formControlName="name" placeholder='e. g. "Redeemable T-Shirt with logo"' class="form-control"
                                    [ngClass]="{ 'is-invalid' : submitted && f.name.errors }">
                                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                    <div *ngIf="f.name.errors.required">Name is required</div>
                                </div>
                            </div>


                            <div class="form-group">
                                <div class="createFormLabel">Description <span>(Optional)</span></div>
                                <textarea formControlName="description" rows="4" placeholder='e. g. "After purchasing you’ll be able to get the real T-Shirt"' class="form-control"
                                    [ngClass]="{ 'is-invalid' : submitted && f.description.errors }"></textarea>
                                <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                                    <div *ngIf="f.description.errors.required">Description is required</div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="createFormLabel">External Lnk </div>
                                <input type="text" formControlName="link" placeholder='External Lnk'
                                    class="form-control" />
                            </div>

                            <!-- <div class="form-group">
                                <div class="labelMain">Users </div>
                                <select formControlName="user_id" class="form-control" [ngClass]="{ 'is-invalid' : submitted && f.user_id.errors }">
                                    <option value=''>Select User</option>
                                    <option [value]="user.id" *ngFor="let user of userData">{{user.name}}</option>                                    
                                </select>
                                <div *ngIf="submitted && f.user_id.errors" class="invalid-feedback">
                                    <div *ngIf="f.user_id.errors.required">user is required</div>
                                </div>
                            </div> -->

                            <!-- <div class="form-group">
                                <div class="labelMain">Collections </div>
                                <select formControlName="collection_id" class="form-control" [ngClass]="{ 'is-invalid' : submitted && f.collection_id.errors }">
                                    <option value=''>Select Collection</option>
                                    <option [value] ="collection.id" *ngFor="let collection of collectionData">{{collection.collection_name}}</option>
                                </select>
                                <div *ngIf="submitted && f.collection_id.errors" class="invalid-feedback">
                                    <div *ngIf="f.collection_id.errors.required">collection is required</div>
                                </div>
                            </div> -->

                            <div class="form-group">
                                <div class="createFormLabel">Category </div>
                                <select formControlName="category_id" class="form-control"
                                    [ngClass]="{ 'is-invalid' : submitted && f.category_id.errors }">
                                    <option value=''>Select Category</option>
                                    <option [value]="category.id" *ngFor="let category of categoryData">
                                        {{category.name}}</option>
                                </select>
                                <div *ngIf="submitted && f.category_id.errors" class="invalid-feedback">
                                    <div *ngIf="f.category_id.errors.required">Category is required</div>
                                </div>
                            </div>

                            <!-- <div class="form-group">
                                <div class="labelMain">Royalties</div>
                                <div class="postFromAppend">
                                    <input type="text" placeholder='10' class="form-control"/>
                                    <div class="prcentMark">%</div>
                                </div>
                                <div class="suggestNote">Suggested: 10%, 20%, 30%</div>
                            </div> -->

                            <!-- <div class="form-group">
                                <div class="labelMain">Properties <span>(Optional)</span> </div>
                                <div class="row">
                                    <div class="col-12 col-sm-6">
                                        <input type="text" placeholder='e. g. .Size' class="form-control"/>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <input type="text" placeholder='e. g. .M' class="form-control"/>
                                    </div>
                                </div>
                                
                            </div> -->

                            <div class="createFormLabel">Properties </div>
                            
                            <div class="working-hours-selection preffered-time ">
                                <button type="button" (click)="addProperty()" class="createAddBtn"><i
                                        class="fa fa-plus" aria-hidden="true"></i>
                                </button>

                                <div formArrayName="properties" class="repeatedCreate" *ngFor="let property of editItemForm.get('properties')['controls']; let i = index;">
                                    <div [formGroupName]="i">

                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-md-6 mb-3 mb-md-0 ">
                                                    <!-- <label for="type">Type :</label> -->
                                                    <input type="text" placeholder="Type" class="form-control"
                                                        formControlName="type"
                                                        [ngClass]="{ 'is-invalid': submitted && f.properties['controls'][i].controls.type.errors }">
                                                    <div *ngIf="submitted && f.properties['controls'][i].controls.type.errors"
                                                        class="invalid-feedback">
                                                        <div>Type is required</div>                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-6 mb-3 mb-md-0 ">
                                                    <!-- <label for="name">Name :</label> -->
                                                    <input type="text" placeholder="Name" class="form-control"
                                                        formControlName="name"
                                                        [ngClass]="{ 'is-invalid': submitted && f.properties['controls'][i].controls.name.errors }">
                                                    <div *ngIf="submitted && f.properties['controls'][i].controls.name.errors"
                                                        class="invalid-feedback">
                                                        <div>name is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <button *ngIf="i > 0" (click)="removeProperty(i)" class="createAddBtn createDeleteBtn">
                                        <i class="fal fa-trash-alt"></i>
                                    </button>

                                </div>

                            </div>
                           

                            <div class="createFormLabel">Levels </div>
                            
                            <div class="working-hours-selection preffered-time ">
                                
                                <button type="button" (click)="addLevel()" class="createAddBtn">
                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                </button>
                                
                                <div formArrayName="levels" class="repeatedCreate" *ngFor="let level of editItemForm.get('levels')['controls']; let i = index;">
                                    <div [formGroupName]="i">

                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-md-4 mb-3 mb-md-0 ">
                                                    <!-- <label for="type">Name :</label> -->
                                                    <input type="text" placeholder="Name" class="form-control" formControlName="name"
                                                        [ngClass]="{ 'is-invalid': submitted && f.levels['controls'][i].controls.name.errors }">
                                                    <div *ngIf="submitted && f.levels['controls'][i].controls.name.errors"
                                                        class="invalid-feedback">
                                                        <div>Name is required</div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4 mb-3 mb-md-0 ">
                                                    <!-- <label for="from">From :</label> -->
                                                    <input type="number" placeholder="Form" class="form-control" min="0"
                                                        formControlName="from"
                                                        [ngClass]="{ 'is-invalid': submitted && f.levels['controls'][i].controls.from.errors }">
                                                    <div *ngIf="submitted && f.levels['controls'][i].controls.from.errors"
                                                        class="invalid-feedback">
                                                        <div>from is required</div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4 mb-3 mb-md-0 ">
                                                    <!-- <label for="to">To :</label> -->
                                                    <input type="number" placeholder="To" class="form-control" min="0"
                                                        formControlName="to"
                                                        [ngClass]="{ 'is-invalid': submitted && f.levels['controls'][i].controls.to.errors }">
                                                    <div *ngIf="submitted && f.levels['controls'][i].controls.to.errors"
                                                        class="invalid-feedback">
                                                        <div>to is required</div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                    </div>
                                    
                                    <button *ngIf="i > 0" (click)="removeLevel(i)" class="createAddBtn createDeleteBtn"> 
                                        <i class="fal fa-trash-alt"></i>
                                    </button>
                                    
                                </div>

                            </div>
                            

                            <div class="createFormLabel">Stats </div>
                            
                            <div class="working-hours-selection preffered-time ">
                                
                                <button type="button" (click)="addStat()"  class="createAddBtn">
                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                </button>
                                
                                <div formArrayName="stats" class="repeatedCreate" *ngFor="let stat of editItemForm.get('stats')['controls']; let i = index;">
                                    <div [formGroupName]="i">

                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-md-4 mb-3 mb-md-0 ">
                                                    <!-- <label for="type">Name :</label> -->
                                                    <input type="text" placeholder="Name" class="form-control" formControlName="name"
                                                        [ngClass]="{ 'is-invalid': submitted && f.stats['controls'][i].controls.name.errors }">
                                                    <div *ngIf="submitted && f.stats['controls'][i].controls.name.errors"
                                                        class="invalid-feedback">
                                                        <div>Name is required</div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4 mb-3 mb-md-0 ">
                                                    <!-- <label for="from">From :</label> -->
                                                    <input type="number" placeholder="From" class="form-control" min="0"
                                                        formControlName="from"
                                                        [ngClass]="{ 'is-invalid': submitted && f.levels['controls'][i].controls.from.errors }">
                                                    <div *ngIf="submitted && f.levels['controls'][i].controls.from.errors"
                                                        class="invalid-feedback">
                                                        <div>from is required</div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4 mb-3 mb-md-0 ">
                                                    <!-- <label for="to">To :</label> -->
                                                    <input type="number" placeholder="To" class="form-control" min="0"
                                                        formControlName="to"
                                                        [ngClass]="{ 'is-invalid': submitted && f.levels['controls'][i].controls.to.errors }">
                                                    <div *ngIf="submitted && f.levels['controls'][i].controls.to.errors"
                                                        class="invalid-feedback">
                                                        <div>to is required</div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    
                                    <button *ngIf="i > 0" (click)="removeStat(i)"class="createAddBtn createDeleteBtn"> 
                                        <i class="fal fa-trash-alt"></i>
                                    </button>
                                    
                                </div>

                            </div>
                            

                            <div class="form-group">
                                <div class="createFormLabel">Supply </div>
                                <input type="text" formControlName="supply" placeholder='Supply' class="form-control" readonly />
                            </div>

                            <div class="form-group">
                                <div class="createFormLabel">Unlockable Description </div>
                                <input type="text" formControlName="unlock_description"
                                    placeholder='Unlockable Description' class="form-control" />
                            </div>

                            <div class="createFooter">
                                <button type="submit" class="btn-danger updateBtn" [disabled]="!editItemForm.valid && submitted">Update Item</button>
                                <!-- <button type="button" class="cancelBtn">Unsaved changes</button> -->
                            </div>

                        </form>
                    </div>
                </div>
            </div>
            <!-- <div class="col-12 col-lg-6">
                <div class="previewSection">
                    <div class="uploadSectionHead">Preview</div>
                    <div class="uploadCard"></div>
                </div>
            </div> -->
        </div>
    </div>

</section>