<section class="bidDetailSection">
    <div class="bidImdDetailBack">
        <div class="container">
            
            <div class="row">
                <div class="col-12">
                    <div class="bidImgSection">
                        <img [ngClass]="{'d-none' :  productdtls?.file_type === 'mp4' }" src="{{ productdtls?.image }}" alt="" />

                        <a href="javascript:void(0);" class="like" [ngClass]="{'active': productdtls?.wishlist=='Yes'}" (click)="updateWistlist(productdtls?.id)">
                            <i class="fa fa-heart"></i>
                        </a>
                        <a href="javascript:void(0);" class="expandActive" (click)="fullScreen()">
                            <i class="fas fa-expand-alt"></i>
                        </a>
                        <audio class="audioBId" controls *ngIf="productdtls?.file_type === 'mp3'" src={{fileURL}}></audio>

                        <video class="uploadedVIdeo" *ngIf="productdtls?.file_type === 'mp4'" width="100%" height="100%" controls src={{fileURL}}></video>
                        <div class="shareLorena">
                            <!-- <div class="shareCard">
                                <div class="shareCardProfile" style="background-image: url({{currentUser.image}});">
                                    <img [src]="currentUser.image" alt="" />
                                </div>
                                <div class="shareCardProfileName">
                                    <h5>{{currentUser.name}}</h5>
                                </div>
                            </div> -->
                            <div class="shareCard" *ngFor="let owner of productdtls?.owner">
                                <div class="shareCardProfile" style="background-image: url({{owner.image}});">
                                    <img [src]="owner.image" alt="" />
                                </div>
                                <div class="shareCardProfileName">
                                    <h5>{{owner.name}}</h5>
                                </div>
                            </div>
                            <div class="dropdown">
                                <a href="javascript:void(0);" class="shareCard dropdown-toggle" id="dropdownMenubid" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                                    <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="share-all" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-share-all fa-w-18">
                                        <path fill="currentColor"
                                            d="M470.632 201.371L310.63 41.4C290.59 21.36 256 35.44 256 64.03v72.69C140.5 140.56 0 164.89 0 317.79c0 64.98 33.54 118.26 85.11 155.97 24.19 17.7 58.04-4.92 49.69-34.51-27.7-98.19-.38-122.06 121.2-126.42v71.15c0 28.61 34.61 42.64 54.63 22.63l160.002-159.98c12.491-12.499 12.491-32.759 0-45.259zM288 384.001v-103.9c-145.671 1.326-224.979 22.633-184 167.9-31.51-23.05-72-65.11-72-130.21 0-131.425 125.835-148.345 256-149.69v-104.1l160 160-160 160zm278.628-137.373l-160 159.978c-13.454 13.454-33.492 11.509-45.191.106L544 224.001 361.438 41.294c11.695-11.401 31.723-13.362 45.189.105l160 159.974c12.497 12.497 12.497 32.758.001 45.255z"
                                            class=""></path>
                                    </svg>
                                    <div class="shareCardProfileName">
                                        <h5>Share</h5>

                                    </div>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right customDropFilter">
                                    <ul class="filterlistMenu">
                                        <li>
                                            <a href="javascript:void(0);" (click)="shareLinkOnSocialMedia('facebook')">
                                                Facebook
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);" (click)="shareLinkOnSocialMedia('twitter')">
                                                Twitter
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);" (click)="shareLinkOnSocialMedia('linkedin')">
                                                LinkedIn
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="bidImgContent">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="bidcontentLeft">
                        <div class="bidContentHed">
                            {{ productdtls?.name }}
                            <!-- <small>({{ productdtls?.token_id }})</small> -->
                        </div>

                        <a href="javascript:void(0);" class="copyCard" *ngFor="let owner of productdtls?.owner">
                            {{owner.address}}
                            <span style="display:none;" id="copyuseraddress">{{owner.address}}</span>
                            <span class="copyIcon"><i class="fal fa-copy" (click)="copyInputMessage('#copyuseraddress')"></i></span>
                        </a>

                        <div class="bidconentSUbhead">
                            <span *ngIf="productdtls?.price">{{ productdtls?.price }} ETH</span>
                        </div>
                        <div class="bidDes">
                            {{ productdtls?.description }}
                        </div>

                        <div class="dropdown droplistHotHead">
                            <div class="dropListHotBid dropdown-toggle" id="dropdownMenubid" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fal fa-ellipsis-v"></i></div>
                            <div class="dropdown-menu dropdown-menu-right customDropFilter" aria-labelledby="dropdownMenubid">
                                <ul class="filterlistMenu">
                                    <li>
                                        <a href="javascript:void(0);" class="labelDropdown">
                                            Action
                                        </a>
                                    </li>
                                    <li *ngIf="currentUser?.id != productdtls?.user_id">
                                        <a href="javascript:void(0);" *ngIf="sellerData?.status =='on-sale'" data-toggle="modal" data-target="#checkoutModalCenter">
                                            Purchase Now
                                        </a>
                                    </li>
                                    <div *ngIf="sellerData?.status !='on-sale' && currentUser">
                                        <li *ngIf="currentUser?.id != productdtls?.user_id && !userBidRecord.id">
                                            <a href="javascript:void(0);" data-toggle="modal" data-target="#placebidModalCenter">
                                                Place a {{bidButtonText}}
                                            </a>
                                        </li>
                                        <li *ngIf="userBidRecord.id">
                                            <a href="javascript:void(0);" data-toggle="modal" data-target="#cancelbidModalCenter">
                                                Cancel {{bidButtonText}}
                                            </a>
                                        </li>
                                    </div>
                                    <li *ngIf="currentUser">
                                        <a href="javascript:void(0);" data-toggle="modal" data-target="#placeReportModalCenter">
                                            Report
                                        </a>
                                    </li>
                                </ul>

                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="bidcontentRight" *ngIf="currentUser?.id != productdtls?.user_id">
                        <div class="heightBidCard">
                            <div class="hightBidCardBody">
                                <div *ngIf="auctionData" class="hightBidFlexHead">
                                     <div class="hightBidCardImg" *ngFor="let owner of productdtls?.owner"
                                        style="background-image: url({{owner.image}});">
                                        <img src="{{ owner.image }}" alt="" />
                                    </div>
                                    <div class="hightBidFlex">
                                        <div class="hightBidHead">
                                            Sale ends ({{auctionData.expDate}} at {{auctionData.expTime}} IST)
                                            <br> Minimum bid -- Reserve price of 1 ETH not met.
                                        </div>
                                        <div class="hightBidSubhead">
                                            <span>{{auctionData.basePrice / 1e18}} ETH</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="hightBidFlexHead" *ngIf="highest_bidder?.buyer_name">
                                    <div class="hightBidCardImg"
                                        style="background-image: url({{highest_bidder?.buyer_name?.image}});">
                                        <img src="{{ highest_bidder?.buyer_name?.image }}" alt="" />
                                    </div>
                                    <div class="hightBidFlex">
                                        <div class="hightBidHead">
                                            Highest {{bidButtonText}} by <span>{{ highest_bidder?.buyer_name?.address | slice:0:15 }}</span>
                                        </div>
                                        <div class="hightBidSubhead">
                                            <span>{{ highest_bidder?.price}} WETH</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="bidBuyBtn">
                                    <a *ngIf="sellerData?.status === 'on-sale'" href="javascript:void(0);" data-toggle="modal" data-target="#checkoutModalCenter" class="bidBuy">Buy Now</a>
                                    <div *ngIf="sellerData?.status !='on-sale'">
                                        <!-- <div *ngIf=""> -->
                                            <a *ngIf="!currentUser" class="bidBid" data-toggle="modal" data-target="#connectWalletModal" href="javascript:void(0);"> Connect Wallet </a>
                                            
                                            <a *ngIf="currentUser?.id != productdtls?.user_id && !userBidRecord.id && currentUser" href="javascript:void(0);" data-toggle="modal" data-target="#placebidModalCenter" class="bidBid">Make {{bidButtonText}}</a>

                                            <a *ngIf="userBidRecord.id && userBidRecord.is_rejected === 'No'" href="javascript:void(0);" data-toggle="modal" data-target="#cancelbidModalCenter" class="bidBid">Cancel {{bidButtonText}}</a>
                                            <p *ngIf="userBidRecord.id && userBidRecord.is_rejected === 'Yes' ">Your bid rejected by owner: {{userBidRecord.reason}}</p>
                                        <!-- </div> -->
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="hightBidCardFooter">
                                Service fee <span>2.5% </span> ETH
                            </div> -->
                        </div>
                    </div>
                    <div class="bidcontentRight" *ngIf="currentUser?.id == productdtls?.user_id">
                        <div class="heightBidCard">

                            <div *ngIf="auctionData" class="hightBidFlexHead">
                                 <div class="hightBidCardImg" *ngFor="let owner of productdtls?.owner"
                                    style="background-image: url({{owner.image}});">
                                    <img src="{{ owner.image }}" alt="" />
                                </div>
                                <div class="hightBidFlex">
                                    <div class="hightBidHead">
                                        Sale ends ({{auctionData.expDate}} at {{auctionData.expTime}} IST)
                                        <br> Minimum bid -- Reserve price of 1 ETH not met.
                                    </div>
                                    <div class="hightBidSubhead">
                                        <span>{{auctionData.basePrice / 1e18}} ETH</span>
                                    </div>
                                </div>
                            </div>

                            <div class="hightBidFlexHead" *ngIf="highest_bidder?.buyer_name">
                                <div class="hightBidCardImg"
                                    style="background-image: url({{highest_bidder?.buyer_name?.image}});">
                                    <img src="{{ highest_bidder?.buyer_name?.image }}" alt="" />
                                </div>
                                <div class="hightBidFlex">
                                    <div class="hightBidHead">
                                        Highest {{bidButtonText}} by <span>{{ highest_bidder?.buyer_name?.address | slice:0:15 }}</span>
                                    </div>
                                    <div class="hightBidSubhead">
                                        <span>{{ highest_bidder?.price}} WETH</span>
                                    </div>
                                </div>
                            </div>
                            <div class="hightBidCardBody">
                                <div class="bidBuyBtn" *ngIf="currentUser?.id == productdtls?.user_id">
                                    <a *ngIf="sellerData?.status !='on-sale' && !auctionData" href="javascript:void(0);" data-toggle="modal" data-target="#sellModalCenter" class="bidBuy">Sell Now</a>
                                </div>
                                <div class="bidBuyBtn" *ngIf="currentUser?.id == productdtls?.creator_id">
                                    <a href="javascript:void(0);" class="bidBuy" [routerLink]="['/edit-item',productdtls.id]">Edit</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

        <div class="exploreTabhead newBidTab">
            <div class="container">
                <div class="row">
                    <div class="col-12">

                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="info-tab" data-toggle="tab" href="#info" role="tab" aria-controls="info" aria-selected="true">Info</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="owners-tab" data-toggle="tab" href="#owners" role="tab" aria-controls="owners" aria-selected="false">Owners</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="history-tab" data-toggle="tab" href="#history" role="tab" aria-controls="history" aria-selected="false">History</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="bid-tab" data-toggle="tab" href="#bid" role="tab" aria-controls="bid" aria-selected="false">Bids</a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="exploreTabContent">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab">
                                <ul class="bidlist customScroll">
                                    <li *ngFor="let owner of productdtls?.owner">
                                        <a routerLink="/user/{{owner.address}}" class="placeBidHref">
                                            <div class="hightBidFlexHead">
                                                <div class="bidUserProfile active" style="background-image: url({{owner.image}});">
                                                    <img src="{{ owner.image}}" alt="" />
                                                </div>
                                                <div class="hightBidFlex">
                                                    <div class="bidUser">
                                                        Owner : {{owner.name}}
                                                    </div>
                                                    <div class="biduserDetail">
                                                        {{owner.address}}
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <div class="hightBidFlexHead">
                                            Category: {{productdtls.category.name}} <br>
                                            Collection: {{productdtls.collection.collection_name}} <br>
                                        </div>
                                        <div class="hightBidFlexHead">
                                            <p *ngIf="productdtls.external_link">External Link: <a routerLink="{{productdtls.external_link}}" target="_blank" class="placeBidHref">{{productdtls.external_link}} </a></p>
                                        </div>
                                    </li>
                                    <li *ngIf="productdtls?.properties.length > 0">
                                        <div class="hightBidFlexHead">
                                            <div>
                                                Properties
                                                <h5 *ngFor="let property of productdtls?.properties">
                                                    {{property.property_name}} : {{property.property_type}}
                                                </h5>
                                            </div>
                                        </div>
                                    </li>
                                    <li *ngIf="productdtls?.levels.length > 0">
                                        <div class="hightBidFlexHead">
                                            <div>
                                                Levels
                                                <h5 *ngFor="let level of productdtls?.levels">
                                                    {{level.level_name}} : {{level.level_value1}} - {{level.level_value2}}
                                                </h5>
                                            </div>
                                        </div>
                                    </li>
                                    <li *ngIf="productdtls?.stats.length > 0">
                                        <div class="hightBidFlexHead">
                                            <div>
                                                Stats
                                                <h5 *ngFor="let level of productdtls?.stats">
                                                    {{level.stat_name}} : {{level.stat_value1}} - {{level.stat_value2}}
                                                </h5>
                                            </div>
                                        </div>
                                    </li>
                                    
                                </ul>

                            </div>
                            <div class="tab-pane fade" id="owners" role="tabpanel" aria-labelledby="owners-tab">
                                <ul class="bidlist customScroll">
                                    <li *ngFor="let owner of ownerData">
                                        <a routerLink="/user/{{owner?.buyer_name?.address}}" class="placeBidHref">
                                            <div class="hightBidFlexHead">
                                                <div class="bidUserProfile active" style="background-image: url({{owner?.buyer_name?.image}});">
                                                    <img src="{{ owner?.buyer_name?.image}}" alt="" />
                                                </div>
                                                <div class="hightBidFlex">
                                                    <div class="bidUser">
                                                        Owner : {{owner?.buyer_name?.name}}
                                                    </div>
                                                    <div class="biduserDetail">
                                                        {{ owner?.buyer_name?.address | slice:0:15}}
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a routerLink="/user/{{productdtls?.creator?.address}}" class="placeBidHref">
                                            <div class="hightBidFlexHead">
                                                <div class="bidUserProfile active" style="background-image: url({{productdtls?.creator?.image}});">
                                                    <img src="{{ productdtls?.creator?.image}}" alt="" />
                                                </div>
                                                <div class="hightBidFlex">
                                                    <div class="bidUser">
                                                        Creator : {{productdtls?.creator?.name}}
                                                    </div>
                                                    <div class="biduserDetail">
                                                        {{ productdtls?.creator?.address | slice:0:15}}
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                            <div class="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">
                                <ul class="bidlist customScroll">

                                    <li *ngFor="let history of histories">
                                        <div class="hightBidFlexHead">
                                            <div class="bidUserProfile active" style="background-image: url({{history?.user?.image}});">
                                                <img src="{{ history?.user?.image}}" alt="" />
                                            </div>
                                            <div class="hightBidFlex">
                                                <div class="bidUser">
                                                    <span>{{ history.type }} at {{history.created_at | date:'MMM d, y'}}</span>
                                                </div>
                                                <div class="biduserDetail">
                                                    <span> by</span> {{ history.user.address }}

                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="tab-pane fade" id="bid" role="tabpanel" aria-labelledby="bid-tab">
                                <ul class="bidlist customScroll">
                                    <li *ngFor="let bid of productdtls?.orders">
                                        <div class="hightBidFlexHead">
                                            <div class="bidUserProfile active" style="background-image: url({{bid?.buyer_name?.image}});">
                                                <img src="{{ bid?.buyer_name?.image}}" alt="" />
                                            </div>
                                            <div class="hightBidFlex">
                                                <div class="bidUser">
                                                    <span>{{ bid.basePrice/1e18 }} WETH</span> <span class="rejected_bid" *ngIf="bid.is_rejected === 'Yes' "> Rejected bid</span>
                                                </div>
                                                <div class="biduserDetail">
                                                    <span> by</span> {{ bid?.buyer_name?.address | slice:0:15}}
                                                </div>
                                                <div class="bidBuyBtn" *ngIf="bid.is_rejected === 'No' ">
                                                    <a href="javascript:void(0);" *ngIf="currentUser?.id == productdtls?.user_id" class="bidBuy" (click)="acceptBid(bid)" data-toggle="modal" data-target="#acceptbidModalCenter">Accept Bid</a>
                                                <!-- </div>
                                                <div class="bidBuyBtn" *ngIf="bid.is_rejected === 'No' "> -->
                                                    <a style="margin-left: 10px;" href="javascript:void(0);" *ngIf="currentUser?.id == productdtls?.user_id" class="bidBuy" (click)="rejectBid(bid)" data-toggle="modal" data-target="#rejectbidModalCenter">Reject Bid</a>
                                                </div>
                                                <p *ngIf="bid.is_rejected === 'Yes' ">{{bid.reason}}</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li *ngIf="productdtls?.orders.length < 1">
                                        <div class="alert alert-danger">
                                          <strong>No record found!</strong>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>




<!-- -------------- them  modal s---------- -->








<!-- <button type="button" class="btn btn-primary ml-3" data-toggle="modal" data-target="#followstepModalCenter">
    Follow steps
  </button>
  
  <button type="button" class="btn btn-primary ml-3" data-toggle="modal" data-target="#followstep2ModalCenter">
    Follow steps 2
  </button>
  
  
  <button type="button" class="btn btn-primary ml-3" data-toggle="modal" data-target="#followstep3ModalCenter">
    Follow steps 3
  </button> -->

<!-- Checkout Modal -->
<div class="modal fade theme-modal" id="checkoutModalCenter" tabindex="-1" role="dialog" aria-labelledby="checkoutModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="checkoutModalCenterTitle">Checkout</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 340.8 340.8"
                        style="enable-background:new 0 0 340.8 340.8;" xml:space="preserve">
                        <g>
                            <g>
                                <path d="M170.4,0C76.4,0,0,76.4,0,170.4s76.4,170.4,170.4,170.4s170.4-76.4,170.4-170.4S264.4,0,170.4,0z M170.4,323.6
                 c-84.4,0-153.2-68.8-153.2-153.2S86,17.2,170.4,17.2S323.6,86,323.6,170.4S254.8,323.6,170.4,323.6z" />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path d="M182.4,169.6l50-50c3.2-3.2,3.2-8.8,0-12c-3.2-3.2-8.8-3.2-12,0l-50,50l-50-50c-3.2-3.2-8.8-3.2-12,0
                 c-3.2,3.2-3.2,8.8,0,12l50,50l-50,49.6c-3.2,3.2-3.2,8.8,0,12c1.6,1.6,4,2.4,6,2.4s4.4-0.8,6-2.4l50-50l50,50c1.6,1.6,4,2.4,6,2.4
                 s4.4-0.8,6-2.4c3.2-3.2,3.2-8.8,0-12L182.4,169.6z" />
                            </g>
                        </g>
                    </svg>

                </button>
            </div>
            <form [formGroup]="buyItemform">
                <div class="modal-body checkout-modalBody thememodalBody">
                    <p class="modaltop_para">
                        You are about to purchase <strong>{{productdtls?.name}} </strong> <br /> from

                        <span *ngFor="let owner of productdtls?.owner">
                            <strong *ngIf="owner">{{owner?.name}}</strong>
                            <strong *ngIf="owner == ''">Unknown</strong>
                        </span>
                        
                    </p>

                    <div class="form-group">
                        <div class="form-row">
                            <div class="col-6">
                                <label>Enter quantity</label>
                            </div>
                            <div class="col-6 text-right">
                                <label>Available: 1</label>
                            </div>
                        </div>
                        <div class="modal-input">
                            <input [value]="1" readonly="true" type="number" formControlName="quantity" [ngClass]="{ 'is-invalid': submitted && g.quantity.errors }">
                            <div *ngIf="submitted && g.quantity.errors" class="invalid-feedback">
                                <div *ngIf="g.quantity.errors.required">Quantity is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="form-row">
                            <div class="col-12">
                                <label>Amount</label>
                            </div>
                        </div>
                        <div class="modal-input modal-inputW-coin">
                            <input type="number" readonly="true" formControlName="amount" [value]="sellerData?.basePrice / 1e18">
                            <span class="InputCoinName">ETH</span>
                            <div *ngIf="submitted && g.amount.errors" class="invalid-feedback">
                                <div *ngIf="g.amount.errors.required">Amount is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="Co_details_list">
                        <!-- <div class="form-row align-items-center mb-2 mb-md-3">
                            <div class="col-7">Your balance</div>
                            <div class="col-5 text-right">
                                <span>5.45 ETH</span>
                            </div>
                        </div> -->
                        <!-- <div class="form-row align-items-cente mb-2 mb-md-3">
                            <div class="col-7">Service fee</div>
                            <div class="col-5 text-right">
                                <span>0.004 ETH</span>
                            </div>
                        </div> -->
                        <div class="form-row align-items-cente">
                            <div class="col-7">You will pay</div>
                            <div class="col-5 text-right">
                                <span>{{basePrice | number : '1.6-6'}} ETH</span>
                            </div>
                        </div>
                    </div>

                </div>
            </form>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary" (click)="purchaseItem(buyItemform.value)" data-dismiss="modal" [disabled]="!buyItemform.valid && submitted">Proceed to Payment</button>
            </div>
        </div>
    </div>
</div>

<!-- Checkout Modal -->
<div class="modal fade theme-modal" id="sellModalCenter" tabindex="-1" role="dialog" aria-labelledby="checkoutModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="checkoutModalCenterTitle">Sell</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 340.8 340.8"
                        style="enable-background:new 0 0 340.8 340.8;" xml:space="preserve">
                        <g>
                            <g>
                                <path d="M170.4,0C76.4,0,0,76.4,0,170.4s76.4,170.4,170.4,170.4s170.4-76.4,170.4-170.4S264.4,0,170.4,0z M170.4,323.6
                 c-84.4,0-153.2-68.8-153.2-153.2S86,17.2,170.4,17.2S323.6,86,323.6,170.4S254.8,323.6,170.4,323.6z" />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path d="M182.4,169.6l50-50c3.2-3.2,3.2-8.8,0-12c-3.2-3.2-8.8-3.2-12,0l-50,50l-50-50c-3.2-3.2-8.8-3.2-12,0
                 c-3.2,3.2-3.2,8.8,0,12l50,50l-50,49.6c-3.2,3.2-3.2,8.8,0,12c1.6,1.6,4,2.4,6,2.4s4.4-0.8,6-2.4l50-50l50,50c1.6,1.6,4,2.4,6,2.4
                 s4.4-0.8,6-2.4c3.2-3.2,3.2-8.8,0-12L182.4,169.6z" />
                            </g>
                        </g>
                    </svg>

                </button>
            </div>
            <ul class="nav justify-content-end pull-lefft">
              <li class="nav-item">
                <a [ngClass]="{'active': selltype=='Price'}" class="nav-link" href="javascript:" (click)="showHideSellFormByType('Price')">Price</a>
              </li>
              <li class="nav-item">
                <a [ngClass]="{'active': selltype=='Auction'}" class="nav-link" href="javascript:" (click)="showHideSellFormByType('Auction')">Auction</a>
              </li>
            </ul>
            <div *ngIf="selltype === 'Price'">
                <form [formGroup]="placeonsellform">
                    <div class="modal-body checkout-modalBody thememodalBody">
                        <p class="modaltop_para">
                            You are about to place the product on sell
                        </p>
                        <div class="form-group">
                            <div class="form-row">
                                <div class="col-12">
                                    <label>Amount</label>
                                </div>
                            </div>
                            <div class="modal-input modal-inputW-coin">
                                <input required="true" type="number" formControlName="sellamount" [ngClass]="{ 'is-invalid': submitted && f.sellamount.errors }" value="" placeholder="0.001" (keyup)="checkFee($event)">
                                <span class="InputCoinName">ETH</span>
                                <div *ngIf="submitted && f.sellamount.errors" class="invalid-feedback">
                                    <div *ngIf="f.sellamount.errors.required">Amount is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="Co_details_list">
                            <div class="form-row align-items-cente mb-2 mb-md-3">
                                <div class="col-7">Service fee</div>
                                <div class="col-5 text-right">
                                    <span>{{fee | number : '1.6-6'}} ETH</span>
                                </div>
                            </div>
                            <div class="form-row align-items-cente">
                                <div class="col-7">You will get</div>
                                <div class="col-5 text-right">
                                    <span>{{youwillget | number : '1.6-6'}} ETH</span>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        <button *ngIf="youwillget === 0" type="button" (click)="onPlaceOnSell(placeonsellform.value)" [disabled]="!placeonsellform.valid && submitted" class="btn btn-primary">Proceed to Payment</button>
                        <button *ngIf="youwillget > 0" type="button" (click)="onPlaceOnSell(placeonsellform.value)" data-dismiss="modal" [disabled]="!placeonsellform.valid && submitted" class="btn btn-primary" data-toggle="modal" data-target="#followstepModalCenter">Proceed to Payment</button>
                    </div>
                </form>
            </div>
            <div *ngIf="selltype === 'Auction'">
                <form [formGroup]="placeonsellauctionform">
                    <div class="modal-body checkout-modalBody thememodalBody">
                        <p class="modaltop_para">
                            You are about to place the product on sell
                        </p>
                        <div class="form-group">
                            <div class="form-row">
                                <div class="col-12">
                                    <label>Minimum Bid</label>
                                </div>
                            </div>
                            <div class="modal-input modal-inputW-coin">
                                <input required="true" type="number" formControlName="sellamount" [ngClass]="{ 'is-invalid': submitted && fh.sellamount.errors }" value="" placeholder="0.001" (keyup)="checkFee($event)">
                                <span class="InputCoinName">ETH</span>
                                <div *ngIf="submitted && fh.sellamount.errors" class="invalid-feedback">
                                    <div *ngIf="fh.sellamount.errors.required">Amount is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-row">
                                <div class="col-12">
                                    <label>Reserve price</label>
                                </div>
                            </div>
                            <div class="modal-input modal-inputW-coin">
                                <input required="true" type="number" formControlName="reserveprice" [ngClass]="{ 'is-invalid': submitted && fh.reserveprice.errors }" value="" placeholder="0.001">
                                <span class="InputCoinName">ETH</span>
                                <div *ngIf="submitted && fh.reserveprice.errors" class="invalid-feedback">
                                    <div *ngIf="fh.reserveprice.errors.required">Amount is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-row">
                                <div class="col-12">
                                    <label>Expiration Days</label>
                                </div>
                            </div>
                            <div class="modal-input modal-inputW-coin">
                                <input required="true" type="number" formControlName="expirationdays" [ngClass]="{ 'is-invalid': submitted && fh.expirationdays.errors }" value="" placeholder="In Days">
                                <span class="InputCoinName">In Days</span>
                                <div *ngIf="submitted && fh.expirationdays.errors" class="invalid-feedback">
                                    <div *ngIf="fh.expirationdays.errors.required">Amount is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        <button type="button" (click)="onPlaceOnSellAuction(placeonsellauctionform.value)" [disabled]="!placeonsellauctionform.valid && submitted" class="btn btn-primary">Proceed to Payment</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>


<!-- Place a Bid Modal -->
<div class="modal fade theme-modal" id="placebidModalCenter" tabindex="-1" role="dialog" aria-labelledby="placebidModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="placebidModalCenterTitle">Make {{bidButtonText}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 340.8 340.8"
                        style="enable-background:new 0 0 340.8 340.8;" xml:space="preserve">
                        <g>
                            <g>
                                <path d="M170.4,0C76.4,0,0,76.4,0,170.4s76.4,170.4,170.4,170.4s170.4-76.4,170.4-170.4S264.4,0,170.4,0z M170.4,323.6
                 c-84.4,0-153.2-68.8-153.2-153.2S86,17.2,170.4,17.2S323.6,86,323.6,170.4S254.8,323.6,170.4,323.6z" />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path d="M182.4,169.6l50-50c3.2-3.2,3.2-8.8,0-12c-3.2-3.2-8.8-3.2-12,0l-50,50l-50-50c-3.2-3.2-8.8-3.2-12,0
                 c-3.2,3.2-3.2,8.8,0,12l50,50l-50,49.6c-3.2,3.2-3.2,8.8,0,12c1.6,1.6,4,2.4,6,2.4s4.4-0.8,6-2.4l50-50l50,50c1.6,1.6,4,2.4,6,2.4
                 s4.4-0.8,6-2.4c3.2-3.2,3.2-8.8,0-12L182.4,169.6z" />
                            </g>
                        </g>
                    </svg>

                </button>
            </div>
            <form [formGroup]="placeonbidform">
                <div class="modal-body checkout-modalBody thememodalBody">
                    <p class="modaltop_para">
                        You are about to purchase <strong>{{productdtls?.name}} </strong> <br /> from
                        <span *ngFor="let owner of productdtls?.owner">
                            <strong *ngIf="owner">{{owner?.name}}</strong>
                            <strong *ngIf="owner == ''">Unknown</strong>
                        </span>
                    </p>

                    <h5 class="mb-3">
                        <strong>Your bid</strong>
                    </h5>

                    <div class="form-group">
                        <div class="form-row">
                            <div class="col-12">
                                <label>Enter bid</label>
                            </div>
                        </div>
                        <div class="modal-input modal-inputW-coin">
                            <input type="number" formControlName="price" [ngClass]="{ 'is-invalid': submitted && h.price.errors }" value="" placeholder="0.001" (keyup)="checkFee($event)">
                            <span class="InputCoinName">WETH</span>
                            <div *ngIf="submitted && h.price.errors" class="invalid-feedback">
                                <div *ngIf="h.price.errors.required">Amount is required</div>
                            </div>
                        </div>
                    </div>


                    <!-- <div class="form-group">
                    <div class="form-row">
                        <div class="col-12">
                            <label>Enter quantity <span class="labelExtra">(10 available)</span></label>
                        </div>
                    </div>
                    <div class="modal-input">
                        <input type="text" value="1">
                    </div>
                </div> -->

                    <!-- <div class="Co_details_list">
                    <div class="form-row align-items-center mb-2 mb-md-3">
                        <div class="col-7">Your balance</div>
                        <div class="col-5 text-right">
                            <span>5.45 ETH</span>
                        </div>
                    </div>
                    <div class="form-row align-items-cente mb-2 mb-md-3">
                        <div class="col-7">Service fee</div>
                        <div class="col-5 text-right">
                            <span>0.004 ETH</span>
                        </div>
                    </div>
                    <div class="form-row align-items-cente">
                        <div class="col-7">You will pay</div>
                        <div class="col-5 text-right">
                            <span>0.154.ETH</span>
                        </div>
                    </div>
                </div> -->


                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    <button type="button" (click)="onPlaceOnBid(placeonbidform.value)" [disabled]="!placeonbidform.valid && submitted" class="btn btn-primary">Place a bid</button>
                </div>
            </form>
        </div>
    </div>
</div>


<!-- Cancel Bid Modal -->
<div class="modal fade theme-modal" id="cancelbidModalCenter" tabindex="-1" role="dialog" aria-labelledby="cancelbidModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="cancelbidModalCenterTitle">Cancel {{bidButtonText}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 340.8 340.8"
                        style="enable-background:new 0 0 340.8 340.8;" xml:space="preserve">
                        <g>
                            <g>
                                <path d="M170.4,0C76.4,0,0,76.4,0,170.4s76.4,170.4,170.4,170.4s170.4-76.4,170.4-170.4S264.4,0,170.4,0z M170.4,323.6
                 c-84.4,0-153.2-68.8-153.2-153.2S86,17.2,170.4,17.2S323.6,86,323.6,170.4S254.8,323.6,170.4,323.6z" />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path d="M182.4,169.6l50-50c3.2-3.2,3.2-8.8,0-12c-3.2-3.2-8.8-3.2-12,0l-50,50l-50-50c-3.2-3.2-8.8-3.2-12,0
                 c-3.2,3.2-3.2,8.8,0,12l50,50l-50,49.6c-3.2,3.2-3.2,8.8,0,12c1.6,1.6,4,2.4,6,2.4s4.4-0.8,6-2.4l50-50l50,50c1.6,1.6,4,2.4,6,2.4
                 s4.4-0.8,6-2.4c3.2-3.2,3.2-8.8,0-12L182.4,169.6z" />
                            </g>
                        </g>
                    </svg>

                </button>
            </div>
            <div class="modal-body checkout-modalBody thememodalBody">
                <p class="modaltop_para mb-4 text-muted">
                    Do you really want to cancel your bid? you can <br /> place a new bid i future.
                </p>

                <h4 class="mb-3">
                    <strong>0 WETH for 1 edition(s)</strong>
                </h4>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="cancelBid(userBidRecord.id)">Cancel {{bidButtonText}}</button>
            </div>
        </div>
    </div>
</div>


<!-- Accpet Bid Modal -->
<div class="modal fade theme-modal" id="acceptbidModalCenter" tabindex="-1" role="dialog" aria-labelledby="acceptbidModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="acceptbidModalCenterTitle">Accept {{bidButtonText}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 340.8 340.8"
                        style="enable-background:new 0 0 340.8 340.8;" xml:space="preserve">
                        <g>
                            <g>
                                <path d="M170.4,0C76.4,0,0,76.4,0,170.4s76.4,170.4,170.4,170.4s170.4-76.4,170.4-170.4S264.4,0,170.4,0z M170.4,323.6
                 c-84.4,0-153.2-68.8-153.2-153.2S86,17.2,170.4,17.2S323.6,86,323.6,170.4S254.8,323.6,170.4,323.6z" />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path d="M182.4,169.6l50-50c3.2-3.2,3.2-8.8,0-12c-3.2-3.2-8.8-3.2-12,0l-50,50l-50-50c-3.2-3.2-8.8-3.2-12,0
                 c-3.2,3.2-3.2,8.8,0,12l50,50l-50,49.6c-3.2,3.2-3.2,8.8,0,12c1.6,1.6,4,2.4,6,2.4s4.4-0.8,6-2.4l50-50l50,50c1.6,1.6,4,2.4,6,2.4
                 s4.4-0.8,6-2.4c3.2-3.2,3.2-8.8,0-12L182.4,169.6z" />
                            </g>
                        </g>
                    </svg>

                </button>
            </div>
            <div class="modal-body checkout-modalBody thememodalBody" *ngIf="bidAcceptRecord.id">
                <p class="modaltop_para mb-4 text-muted">
                    You are about to accept bif for <strong class="text-dark">{{bidAcceptRecord.product_name.name}}</strong><br /> form <strong class="text-dark" *ngIf="bidAcceptRecord.buyer_name.name">{{bidAcceptRecord.buyer_name.name}}</strong>
                    <strong class="text-dark" *ngIf="bidAcceptRecord.buyer_name.name == ''">Unknown</strong>
                </p>
                <h4 class="mb-4 pb-2">
                    <strong>{{bidAcceptRecord.basePrice / 1e18}} ETH for 1 edition(s)</strong>
                </h4>
                <hr class="my-4">
                <div class="Co_details_list">
                    <!-- <div class="form-row align-items-center mb-2 mb-md-3">
                        <div class="col-7">Service fee</div>
                        <div class="col-5 text-right">
                            <span>0.004 ETH</span>
                        </div>
                    </div> -->
                    <div class="form-row align-items-cente">
                        <div class="col-7">You will get</div>
                        <div class="col-5 text-right">
                            <span>{{bidAcceptRecord.basePrice / 1e18}} WETH</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary" (click)="acceptBidRecord(bidAcceptRecord.id)">Accept {{bidButtonText}}</button>
            </div>
        </div>
    </div>
</div>


<!-- Follow 1 steps Modal for sell-->
<div class="modal fade theme-modal" id="followstepModalCenter" tabindex="-1" role="dialog" aria-labelledby="followstepdModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="followstepModalCenterTitle">Follow steps</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 340.8 340.8"
                        style="enable-background:new 0 0 340.8 340.8;" xml:space="preserve">
                        <g>
                            <g>
                                <path d="M170.4,0C76.4,0,0,76.4,0,170.4s76.4,170.4,170.4,170.4s170.4-76.4,170.4-170.4S264.4,0,170.4,0z M170.4,323.6
                 c-84.4,0-153.2-68.8-153.2-153.2S86,17.2,170.4,17.2S323.6,86,323.6,170.4S254.8,323.6,170.4,323.6z" />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path d="M182.4,169.6l50-50c3.2-3.2,3.2-8.8,0-12c-3.2-3.2-8.8-3.2-12,0l-50,50l-50-50c-3.2-3.2-8.8-3.2-12,0
                 c-3.2,3.2-3.2,8.8,0,12l50,50l-50,49.6c-3.2,3.2-3.2,8.8,0,12c1.6,1.6,4,2.4,6,2.4s4.4-0.8,6-2.4l50-50l50,50c1.6,1.6,4,2.4,6,2.4
                 s4.4-0.8,6-2.4c3.2-3.2,3.2-8.8,0-12L182.4,169.6z" />
                            </g>
                        </g>
                    </svg>
                </button>
            </div>
            <div class="modal-body py-0">
                <ul class="folloeSteps_list mb-0 list-unstyled">
                    <li class="active" *ngIf="sellPopupApprove === 'StartApprove'">
                        <div class="followcheck progress-circle rotate linear infinite" data-progress="20"></div>
                        <div class="followStep_content">
                            <h3>Approve</h3>
                            <p>Checking balance and approving</p>
                        </div>
                        <a href="javascript:void(0)" class="btn followStep-btn disabled">Inprogress...</a>
                    </li>
                    <li class="active" *ngIf="sellPopupApprove === 'EndApprove' || sellPopupApprove === 'CreateSign' || sellPopupApprove === 'EndSign'">
                        <div class="followcheck"></div>
                        <div class="followStep_content">
                            <h3>Approve</h3>
                            <p>Checking balance and approving</p>
                        </div>
                        <a href="javascript:void(0)" class="btn followStep-btn">Completed</a>
                    </li>
                    <li class="" *ngIf="sellPopupApprove === 'StartApprove' || sellPopupApprove === 'EndApprove' || sellPopupApprove != 'CreateSign' && signText === 'Pending'">
                        <div class="followcheck"></div>
                        <div class="followStep_content">
                            <h3>Signature</h3>
                            <p>Create a signature to place a bid</p>
                        </div>
                        <a href="javascript:void(0)" class="btn followStep-btn disabled">Start</a>
                    </li>
                    <li class="active" *ngIf="sellPopupApprove === 'CreateSign'">
                        <div class="followcheck progress-circle rotate linear infinite" data-progress="20"></div>
                        <div class="followStep_content">
                            <h3>Signature</h3>
                            <p>Create a signature to place a bid</p>
                        </div>
                        <a href="javascript:void(0)" class="btn followStep-btn disabled">Inprogress...</a>
                    </li>
                    <li class="active" *ngIf="sellPopupApprove === 'EndSign'">
                        <div class="followcheck"></div>
                        <div class="followStep_content">
                            <h3>Signature</h3>
                            <p>Create a signature to place a bid</p>
                        </div>
                        <a href="javascript:void(0)" class="btn followStep-btn">Completed</a>
                    </li>
                </ul>
            </div>

        </div>
    </div>
</div>


<!-- Follow 2 steps Modal for bid-->
<div class="modal fade theme-modal" id="followstep2ModalCenter" tabindex="-1" role="dialog" aria-labelledby="followstep2dModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="followstep2ModalCenterTitle">Follow steps</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 340.8 340.8"
                        style="enable-background:new 0 0 340.8 340.8;" xml:space="preserve">
                        <g>
                            <g>
                                <path d="M170.4,0C76.4,0,0,76.4,0,170.4s76.4,170.4,170.4,170.4s170.4-76.4,170.4-170.4S264.4,0,170.4,0z M170.4,323.6
                 c-84.4,0-153.2-68.8-153.2-153.2S86,17.2,170.4,17.2S323.6,86,323.6,170.4S254.8,323.6,170.4,323.6z" />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path d="M182.4,169.6l50-50c3.2-3.2,3.2-8.8,0-12c-3.2-3.2-8.8-3.2-12,0l-50,50l-50-50c-3.2-3.2-8.8-3.2-12,0
                 c-3.2,3.2-3.2,8.8,0,12l50,50l-50,49.6c-3.2,3.2-3.2,8.8,0,12c1.6,1.6,4,2.4,6,2.4s4.4-0.8,6-2.4l50-50l50,50c1.6,1.6,4,2.4,6,2.4
                 s4.4-0.8,6-2.4c3.2-3.2,3.2-8.8,0-12L182.4,169.6z" />
                            </g>
                        </g>
                    </svg>

                </button>
            </div>

            <div class="modal-body py-0">
                <ul class="folloeSteps_list mb-0 list-unstyled">
                    <li class="active" *ngIf="bidPopupApprove === 'CheckingBalance'">
                        <div class="followcheck progress-circle rotate linear infinite" data-progress="20"></div>
                        <div class="followStep_content">
                            <h3>Check WETH</h3>
                            <p>Checking balance and approving</p>
                        </div>
                        <a href="javascript:void(0)" class="btn followStep-btn disabled">Inprogress...</a>
                    </li>
                    <li class="" *ngIf="bidPopupApprove === 'LowBalance'">
                        <div class="followcheck"></div>
                        <div class="followStep_content">
                            <h3>Deposit WETH</h3>
                            <p>Send transaction with your wallet</p>
                        </div>
                        <a href="javascript:void(0)" class="btn followStep-btn" (click)="showDeposit()">Deposit</a>
                    </li>
                    <li class="active" *ngIf="bidPopupApprove === 'SuffBalance' || bidPopupApprove === 'CreateBidSign' || bidPopupApprove === 'EndBidSign' || bidPopupApprove === 'StartApproveBid' || bidPopupApprove === 'EndApproveBid'">
                        <div class="followcheck"></div>
                        <div class="followStep_content">
                            <h3>Check WETH</h3>
                            <p>Send transaction with your wallet</p>
                        </div>
                        <a href="javascript:void(0)" class="btn followStep-btn" (click)="showDeposit()">Completed</a>
                    </li>
                    <li *ngIf="signTextApprove === 'Pending'">
                        <div class="followcheck"></div>
                        <div class="followStep_content">
                            <h3>Approve</h3>
                            <p>Send transaction with your wallet</p>
                        </div>
                        <a href="javascript:void(0)" class="btn followStep-btn disabled">Start</a>
                    </li>
                    
                    <li class="active" *ngIf="bidPopupApprove === 'StartApproveBid'">
                        <div class="followcheck progress-circle rotate linear infinite" data-progress="20"></div>
                        <div class="followStep_content">
                            <h3>Approve</h3>
                            <p>Checking balance and approving</p>
                        </div>
                        <a href="javascript:void(0)" class="btn followStep-btn disabled">Inprogress...</a>
                    </li>
                    <li class="active" class="active" *ngIf="bidPopupApprove === 'CreateBidSign' || bidPopupApprove === 'EndBidSign'">
                        <div class="followcheck"></div>
                        <div class="followStep_content">
                            <h3>Approve</h3>
                            <p>Checking balance and approving</p>
                        </div>
                        <a href="javascript:void(0)" class="btn followStep-btn">Completed</a>
                    </li>
                    <li *ngIf="signTextBid === 'Pending'">
                        <div class="followcheck"></div>
                        <div class="followStep_content">
                            <h3>Signature</h3>
                            <p>Create a signature to place a bid</p>
                        </div>
                        <a href="javascript:void(0)" class="btn followStep-btn disabled">Start</a>
                    </li>
                    <li class="active" *ngIf="bidPopupApprove === 'CreateBidSign'">
                        <div class="followcheck progress-circle rotate linear infinite" data-progress="20"></div>
                        <div class="followStep_content">
                            <h3>Signature</h3>
                            <p>Create a signature to place a bid</p>
                        </div>
                        <a href="javascript:void(0)" class="btn followStep-btn disabled">Inprogress...</a>
                    </li>
                    <li class="active" *ngIf="bidPopupApprove === 'EndBidSign'">
                        <div class="followcheck"></div>
                        <div class="followStep_content">
                            <h3>Signature</h3>
                            <p>Create a signature to place a bid</p>
                        </div>
                        <a href="javascript:void(0)" class="btn followStep-btn">Completed</a>
                    </li>
                </ul>
            </div>

        </div>
    </div>
</div>


<!-- Follow 3 steps Modal -->
<div class="modal fade theme-modal" id="followstep3ModalCenter" tabindex="-1" role="dialog" aria-labelledby="followstep3dModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="followstep3ModalCenterTitle">Follow steps</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 340.8 340.8"
                        style="enable-background:new 0 0 340.8 340.8;" xml:space="preserve">
                        <g>
                            <g>
                                <path d="M170.4,0C76.4,0,0,76.4,0,170.4s76.4,170.4,170.4,170.4s170.4-76.4,170.4-170.4S264.4,0,170.4,0z M170.4,323.6
                 c-84.4,0-153.2-68.8-153.2-153.2S86,17.2,170.4,17.2S323.6,86,323.6,170.4S254.8,323.6,170.4,323.6z" />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path d="M182.4,169.6l50-50c3.2-3.2,3.2-8.8,0-12c-3.2-3.2-8.8-3.2-12,0l-50,50l-50-50c-3.2-3.2-8.8-3.2-12,0
                 c-3.2,3.2-3.2,8.8,0,12l50,50l-50,49.6c-3.2,3.2-3.2,8.8,0,12c1.6,1.6,4,2.4,6,2.4s4.4-0.8,6-2.4l50-50l50,50c1.6,1.6,4,2.4,6,2.4
                 s4.4-0.8,6-2.4c3.2-3.2,3.2-8.8,0-12L182.4,169.6z" />
                            </g>
                        </g>
                    </svg>

                </button>
            </div>

            <div class="modal-body py-0">
                <ul class="folloeSteps_list mb-0 list-unstyled">
                    <li class="active stepdone">
                        <div class="followcheck"></div>
                        <div class="followStep_content">
                            <h3>Approve</h3>
                            <p>Checking balance and approving</p>
                        </div>
                        <a href="javascript:void(0)" class="btn followStep-btn disabled">Done</a>
                    </li>
                    <li class="active">
                        <div class="followcheck progress-circle" data-progress="20"></div>
                        <div class="followStep_content">
                            <h3>Upload files & Mint token</h3>
                            <p>Checking balance and approving</p>
                        </div>
                        <a href="javascript:void(0)" class="btn followStep-btn disabled">Inprogress...</a>
                    </li>
                    <li>
                        <div class="followcheck"></div>
                        <div class="followStep_content">
                            <h3>Sign sell order</h3>
                            <p>Create a signature to place a bid</p>
                        </div>
                        <a href="javascript:void(0)" class="btn followStep-btn disabled">Start</a>
                    </li>
                </ul>
            </div>

        </div>
    </div>
</div>



<div class="modal fade theme-modal" id="placeReportModalCenter" tabindex="-1" role="dialog" aria-labelledby="placebidModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="placebidModalCenterTitle">Report</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 340.8 340.8"
                        style="enable-background:new 0 0 340.8 340.8;" xml:space="preserve">
                        <g>
                            <g>
                                <path d="M170.4,0C76.4,0,0,76.4,0,170.4s76.4,170.4,170.4,170.4s170.4-76.4,170.4-170.4S264.4,0,170.4,0z M170.4,323.6
                 c-84.4,0-153.2-68.8-153.2-153.2S86,17.2,170.4,17.2S323.6,86,323.6,170.4S254.8,323.6,170.4,323.6z" />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path d="M182.4,169.6l50-50c3.2-3.2,3.2-8.8,0-12c-3.2-3.2-8.8-3.2-12,0l-50,50l-50-50c-3.2-3.2-8.8-3.2-12,0
                 c-3.2,3.2-3.2,8.8,0,12l50,50l-50,49.6c-3.2,3.2-3.2,8.8,0,12c1.6,1.6,4,2.4,6,2.4s4.4-0.8,6-2.4l50-50l50,50c1.6,1.6,4,2.4,6,2.4
                 s4.4-0.8,6-2.4c3.2-3.2,3.2-8.8,0-12L182.4,169.6z" />
                            </g>
                        </g>
                    </svg>

                </button>
            </div>
            <form [formGroup]="placeonreportform">
                <div class="modal-body checkout-modalBody thememodalBody">
                    <p class="modaltop_para">
                        You are about to purchase <strong>{{productdtls?.name}} </strong> <br /> from
                        <span *ngFor="let owner of productdtls?.owner">
                            <strong *ngIf="owner">{{owner?.name}}</strong>
                            <strong *ngIf="owner == ''">Unknown</strong>
                        </span>
                    </p>
                    <div class="form-group">
                        <div class="form-row">
                            <div class="col-12">
                                <label>Reason <label style="color: #fb0707;">*</label></label>
                            </div>
                        </div>
                        <div class="modal-input modal-inputW-coin">
                            <input type="text" formControlName="reason" [ngClass]="{ 'is-invalid': submitted && r.reason.errors }" value="" placeholder="Reason">
                            <div *ngIf="submitted && r.reason.errors" class="invalid-feedback">
                                <div *ngIf="r.reason.errors.required">Reason is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="form-row">
                            <div class="col-12">
                                <label>Additional Comments <label style="color: #fb0707;">*</label></label>
                            </div>
                        </div>
                        <div class="modal-input modal-inputW-coin">
                            <input type="text" formControlName="comments" [ngClass]="{ 'is-invalid': submitted && r.comments.errors }" value="" placeholder="Additional Comments">
                            <div *ngIf="submitted && r.comments.errors" class="invalid-feedback">
                                <div *ngIf="r.comments.errors.required">Additional Comments is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    <button type="button" (click)="onPlaceReport(placeonreportform.value)" [disabled]="!placeonreportform.valid && submitted" class="btn btn-primary">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>


<div class="modal fade theme-modal bd-example-modal-xl" id="depositModalCenter" tabindex="-1" role="dialog" aria-labelledby="followstep2dModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content" style="height: 600px"> 
            <div class="modal-header">
                <h5 class="modal-title" id="followstep2ModalCenterTitle">Convert WETH</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showBidStepPopup()">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 340.8 340.8"
                        style="enable-background:new 0 0 340.8 340.8;" xml:space="preserve">
                        <g>
                            <g>
                                <path d="M170.4,0C76.4,0,0,76.4,0,170.4s76.4,170.4,170.4,170.4s170.4-76.4,170.4-170.4S264.4,0,170.4,0z M170.4,323.6
                 c-84.4,0-153.2-68.8-153.2-153.2S86,17.2,170.4,17.2S323.6,86,323.6,170.4S254.8,323.6,170.4,323.6z" />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path d="M182.4,169.6l50-50c3.2-3.2,3.2-8.8,0-12c-3.2-3.2-8.8-3.2-12,0l-50,50l-50-50c-3.2-3.2-8.8-3.2-12,0
                 c-3.2,3.2-3.2,8.8,0,12l50,50l-50,49.6c-3.2,3.2-3.2,8.8,0,12c1.6,1.6,4,2.4,6,2.4s4.4-0.8,6-2.4l50-50l50,50c1.6,1.6,4,2.4,6,2.4
                 s4.4-0.8,6-2.4c3.2-3.2,3.2-8.8,0-12L182.4,169.6z" />
                            </g>
                        </g>
                    </svg>

                </button>
            </div>
            <div class="modal-body py-0">
                <iframe src="https://app.uniswap.org/#/swap?outputCurrency=0xc778417e063141139fce010982780140aa0cd5ab&theme=light&exactAmount=0.04&exactField=output" height="100%" width="100%"></iframe>
            </div>
        </div>
    </div>
</div>

<div class="modal fade theme-modal" id="acceptBidProcessModalCenter" tabindex="-1" role="dialog" aria-labelledby="followstepdModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="acceptBidProcessModalCenterTitle">Follow steps</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 340.8 340.8"
                        style="enable-background:new 0 0 340.8 340.8;" xml:space="preserve">
                        <g>
                            <g>
                                <path d="M170.4,0C76.4,0,0,76.4,0,170.4s76.4,170.4,170.4,170.4s170.4-76.4,170.4-170.4S264.4,0,170.4,0z M170.4,323.6
                 c-84.4,0-153.2-68.8-153.2-153.2S86,17.2,170.4,17.2S323.6,86,323.6,170.4S254.8,323.6,170.4,323.6z" />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path d="M182.4,169.6l50-50c3.2-3.2,3.2-8.8,0-12c-3.2-3.2-8.8-3.2-12,0l-50,50l-50-50c-3.2-3.2-8.8-3.2-12,0
                 c-3.2,3.2-3.2,8.8,0,12l50,50l-50,49.6c-3.2,3.2-3.2,8.8,0,12c1.6,1.6,4,2.4,6,2.4s4.4-0.8,6-2.4l50-50l50,50c1.6,1.6,4,2.4,6,2.4
                 s4.4-0.8,6-2.4c3.2-3.2,3.2-8.8,0-12L182.4,169.6z" />
                            </g>
                        </g>
                    </svg>
                </button>
            </div>
            <div class="modal-body py-0">
                <ul class="folloeSteps_list mb-0 list-unstyled">    
                    <li class="active" *ngIf="sellPopupApprove === 'StartApprove'">
                        <div class="followcheck progress-circle rotate linear infinite" data-progress="20"></div>
                        <div class="followStep_content">
                            <h3>Approve</h3>
                            <p>Checking balance and approving</p>
                        </div>
                        <a href="javascript:void(0)" class="btn followStep-btn disabled">Inprogress...</a>
                    </li>
                    <li class="active" *ngIf="sellPopupApprove === 'EndApprove' || sellPopupApprove === 'StartTokenApprove' || sellPopupApprove === 'EndTokenApprove'">
                        <div class="followcheck"></div>
                        <div class="followStep_content">
                            <h3>Approve</h3>
                            <p>Checking balance and approving</p>
                        </div>
                        <a href="javascript:void(0)" class="btn followStep-btn">Completed</a>
                    </li>     
                    <li *ngIf="signTextApproveToken === 'Pending'">
                        <div class="followcheck"></div>
                        <div class="followStep_content">
                            <h3>Token Approve</h3>
                            <p>Send transaction with your wallet</p>
                        </div>
                        <a href="javascript:void(0)" class="btn followStep-btn disabled">Start</a>
                    </li>           
                    <li class="active" *ngIf="sellPopupTokenApprove === 'StartTokenApprove' && sellPopupApprove === 'EndApprove' ">
                        <div class="followcheck progress-circle rotate linear infinite" data-progress="20"></div>
                        <div class="followStep_content">
                            <h3>Token Approve</h3>
                            <p>Checking balance and approving</p>
                        </div>
                        <a href="javascript:void(0)" class="btn followStep-btn disabled">Inprogress...</a>
                    </li>
                    <li class="active" class="active" *ngIf="sellPopupTokenApprove === 'EndTokenApprove' || sellPopupTokenApprove === 'TransactionCreated'">
                        <div class="followcheck"></div>
                        <div class="followStep_content">
                            <h3>Token Approve</h3>
                            <p>Checking balance and approving</p>
                        </div>
                        <a href="javascript:void(0)" class="btn followStep-btn">Completed</a>
                    </li>
                    <li *ngIf="signTextApproveToken === 'Pending'">
                        <div class="followcheck"></div>
                        <div class="followStep_content">
                            <h3>Transaction</h3>
                            <p>Send transaction with your wallet</p>
                        </div>
                        <a href="javascript:void(0)" class="btn followStep-btn disabled">Start</a>
                    </li>
                    <li class="active" *ngIf="sellPopupTokenApprove === 'TransactionCreated'">
                        <div class="followcheck progress-circle rotate linear infinite" data-progress="20"></div>
                        <div class="followStep_content">
                            <h3>Transaction</h3>
                            <p>Checking balance and transaction</p>
                        </div>
                        <a href="javascript:void(0)" class="btn followStep-btn disabled">Inprogress...</a>
                    </li>
                    <li class="active" *ngIf="sellPopupTokenApprove === 'EndTransaction'">
                        <div class="followcheck"></div>
                        <div class="followStep_content">
                            <h3>Transaction</h3>
                            <p>Checking balance and transaction</p>
                        </div>
                        <a href="javascript:void(0)" class="btn followStep-btn">Completed</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="modal fade theme-modal" id="buyBidProcessModalCenter" tabindex="-1" role="dialog" aria-labelledby="followstepdModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="buyBidProcessModalCenterTitle">Follow steps</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 340.8 340.8"
                        style="enable-background:new 0 0 340.8 340.8;" xml:space="preserve">
                        <g>
                            <g>
                                <path d="M170.4,0C76.4,0,0,76.4,0,170.4s76.4,170.4,170.4,170.4s170.4-76.4,170.4-170.4S264.4,0,170.4,0z M170.4,323.6
                 c-84.4,0-153.2-68.8-153.2-153.2S86,17.2,170.4,17.2S323.6,86,323.6,170.4S254.8,323.6,170.4,323.6z" />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path d="M182.4,169.6l50-50c3.2-3.2,3.2-8.8,0-12c-3.2-3.2-8.8-3.2-12,0l-50,50l-50-50c-3.2-3.2-8.8-3.2-12,0
                 c-3.2,3.2-3.2,8.8,0,12l50,50l-50,49.6c-3.2,3.2-3.2,8.8,0,12c1.6,1.6,4,2.4,6,2.4s4.4-0.8,6-2.4l50-50l50,50c1.6,1.6,4,2.4,6,2.4
                 s4.4-0.8,6-2.4c3.2-3.2,3.2-8.8,0-12L182.4,169.6z" />
                            </g>
                        </g>
                    </svg>
                </button>
            </div>
            <div class="modal-body py-0">
                <ul class="folloeSteps_list mb-0 list-unstyled">
                    <li class="active" *ngIf="buyPopupTransaction === 'StartTransaction'">
                        <div class="followcheck progress-circle rotate linear infinite" data-progress="20"></div>
                        <div class="followStep_content">
                            <h3>Transaction</h3>
                            <p>Checking balance and transaction</p>
                        </div>
                        <a href="javascript:void(0)" class="btn followStep-btn disabled">Inprogress...</a>
                    </li>
                    <li class="active" *ngIf="buyPopupTransaction === 'EndTransaction'">
                        <div class="followcheck"></div>
                        <div class="followStep_content">
                            <h3>Transaction</h3>
                            <p>Checking balance and transaction</p>
                        </div>
                        <a href="javascript:void(0)" class="btn followStep-btn">Completed</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>


<div class="modal fade theme-modal" id="rejectbidModalCenter" tabindex="-1" role="dialog" aria-labelledby="placebidModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="placebidModalCenterTitle">Reject Bid</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 340.8 340.8"
                        style="enable-background:new 0 0 340.8 340.8;" xml:space="preserve">
                        <g>
                            <g>
                                <path d="M170.4,0C76.4,0,0,76.4,0,170.4s76.4,170.4,170.4,170.4s170.4-76.4,170.4-170.4S264.4,0,170.4,0z M170.4,323.6
                 c-84.4,0-153.2-68.8-153.2-153.2S86,17.2,170.4,17.2S323.6,86,323.6,170.4S254.8,323.6,170.4,323.6z" />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path d="M182.4,169.6l50-50c3.2-3.2,3.2-8.8,0-12c-3.2-3.2-8.8-3.2-12,0l-50,50l-50-50c-3.2-3.2-8.8-3.2-12,0
                 c-3.2,3.2-3.2,8.8,0,12l50,50l-50,49.6c-3.2,3.2-3.2,8.8,0,12c1.6,1.6,4,2.4,6,2.4s4.4-0.8,6-2.4l50-50l50,50c1.6,1.6,4,2.4,6,2.4
                 s4.4-0.8,6-2.4c3.2-3.2,3.2-8.8,0-12L182.4,169.6z" />
                            </g>
                        </g>
                    </svg>
                </button>
            </div>
            <form [formGroup]="rejectonbidform">
                <div class="modal-body checkout-modalBody thememodalBody">
                    <div class="form-group">
                        <div class="form-row">
                            <div class="col-12">
                                <label>Enter Reason</label>
                            </div>
                        </div>
                        <div class="modal-input modal-inputW-coin">
                            <input type="hidden" formControlName="bid_id" [ngClass]="{ 'is-invalid': submitted && hr.bid_id.errors }" value="" placeholder="Enter Reason">
                            
                            <input type="text" formControlName="reason" [ngClass]="{ 'is-invalid': submitted && hr.reason.errors }" value="" placeholder="Enter Reason">
                            <div *ngIf="submitted && hr.reason.errors" class="invalid-feedback">
                                <div *ngIf="hr.reason.errors.required">Reason is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    <button type="button" (click)="onRejectOnBid(rejectonbidform.value)" [disabled]="!rejectonbidform.valid && submitted" class="btn btn-primary">Reject a bid</button>
                </div>
            </form>
        </div>
    </div>
</div>