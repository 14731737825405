<div class="overlay" [ngClass]="menuShow ? 'show' : ''" (click)="menuToggle()"></div>
<!-- Header -->
<header id="header" [ngClass]="menuShow ? 'stickyScrolHead' : ''">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <nav class="navbar navbar-expand-lg">
                    <a class="navbar-brand" href="javascript:void(0);" [routerLink]="['/']">
                        <img src="assets/img/logo.png"> NFT
                    </a>

                    <button class="navbar-toggler" type="button" [ngClass]="menuShow ? 'menu-opened' : ''" (click)="menuToggle()">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse" [ngClass]="menuShow ? 'menu-show' : ''">
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                                <a class="nav-link" [routerLink]="['/explore']" href="javascript:void(0);">Explore</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="javascript:void(0);" [routerLink]="['/cms-page/how-it-works']">How its works </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="http://www.zendesk.com" target="_blank">Community </a>
                            </li>

                        </ul>


                        <div class="extra_nav">
                            <ul class="navbar-nav ml-lg-auto align-items-lg-center">
                                <li class="nav-item">
                                    <a class="nav-link" href="javascript:void(0);" [routerLink]="['/account']">My Items </a>
                                </li>
                                <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                                    <a class="nav-link" [routerLink]="['/wishlist']" href="javascript:void(0);">Wishlist</a>
                                </li>


                                <li class="nav-item" [routerLinkActive]="['active']"
                                [routerLinkActiveOptions]="{exact:true}">
                                    <a class="nav-link" [routerLink]="['/activity']" href="javascript:void(0);">Activity</a>
                                </li>
                                <li class="nav-item login_btn" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                                    <a class="nav-link extra_btn" [routerLink]="['/collections']" href="javascript:void(0);">Create </a>
                                </li>
                                <li class="nav-item dropdown notiDropdown">
                                    <a href="javascript:void(0);" class="dropdown-toggle" id="dropdownNoti" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fas fa-bell"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right customDropFilter" aria-labelledby="dropdownNoti" (click)="$event.stopPropagation()">
                                        <div class="notficationTitle">
                                            Notifications
                                        </div>
                                        <ul class="notficationList">
                                            <li *ngFor="let notification of notificationsList">
                                                <div class="balance">
                                                    <div class="priceIcon active"><i class="fab fa-ethereum"></i></div>
                                                    <div class="balancecontent">
                                                        <div class="balanceDes">
                                                            {{notification.type}}
                                                            <span class="timeZone">{{notification.created_at | date:'MMM d, y'}}</span>
                                                        </div>
                                                        <div class="balanceName">
                                                            {{notification.message}}
                                                        </div>

                                                    </div>
                                                </div>
                                            </li>
                                            <br>
                                            <li *ngIf="notificationsList.length === 0" class="alert alert-danger">Nothing here.</li>
                                        </ul>
                                    </div>
                                </li>

                                <li class="nav-item dropdown profileDropdown" *ngIf="currentUser">
                                    <a href="javascript:void(0);" class="dropdown-toggle" id="dropdownProfile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <div class="profileMenu">
                                            <div class="shareCardProfile" style="background-image: url({{currentUser.image}});">
                                                <img [src]="currentUser.image" alt="" />
                                            </div>
                                            <div class="profileContent">
                                                <div class="profilePrice">{{currntaccountbalance}} ETH</div>
                                                <div class="profileId">{{currentUser.short_address}}</div>
                                            </div>
                                        </div>
                                    </a>

                                    <div class="dropdown-menu dropdown-menu-right customDropFilter" aria-labelledby="dropdownProfile" (click)="$event.stopPropagation()">

                                        <div class="userSection">
                                            <div class="userName" *ngIf="currentUser.name">{{currentUser.name}}</div>
                                            <div class="userName" *ngIf="!currentUser.name">Unnamed</div>

                                            <a href="javascript:void(0);" class="usercpy">
                                                <span>{{currentUser.short_address}}</span>
                                                <span style="display:none;" id="copyaddress">{{currentUser.address}}</span>
                                                <i class="fal fa-copy" (click)="copyInputMessage('#copyaddress')"></i>
                                            </a>
                                        </div>
                                        <div class="dropdown-divider"></div>

                                        <div class="transferBalance">
                                            <div class="balance">
                                                <div class="priceIcon active"><i class="fab fa-ethereum"></i></div>
                                                <div class="balancecontent">
                                                    <div class="balanceName">Balance</div>
                                                    <div class="balanceDes">{{currntaccountbalance}} ETH
                                                        <!-- <span>$2333.44</span> --></div>
                                                </div>
                                            </div>
                                            <div class="transferHed">
                                                <a href="javascript:void(0);" class="transferBtn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" enable-background="new 0 0 510 510" height="512" viewBox="0 0 510 510" width="512">
                                                        <g>
                                                            <path
                                                                d="m450 225v120h-282v-60l-135 90 135 90v-60h342v-180z" />
                                                            <path d="m60 165h282v60l135-90-135-90v60h-342v180h60z" />
                                                        </g>
                                                    </svg>
                                                </a>
                                            </div>

                                            <!-- <div class="balance">
                                                <div class="priceIcon"><i class="fab fa-ethereum"></i></div>
                                                <div class="balancecontent">
                                                    <div class="balanceName">Bidding Balance</div>
                                                    <div class="balanceDes">10.33 ETH <span>$2333.44</span></div>
                                                </div>
                                            </div> -->
                                        </div>




                                        <div class="dropdown-divider"></div>
                                        <ul class="verifiedMenu filterlistMenu">
                                            <li>
                                                <a [routerLink]="['/collections']" href="javascript:void(0);">
                                                    My Collections
                                                </a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/edit-profile']" href="javascript:void(0);">
                                                    My Profile
                                                </a>
                                            </li>
                                            <!-- <li>
                                                <a href="javascript:void(0);">
                                                    Dark Theme
                                                    <div class="checkCustom">
                                                        <input type="checkbox" hidden id='cuscheck_1'>
                                                        <label class="customLabelcheck" for="cuscheck_1"></label>
                                                    </div>
                                                </a>
                                            </li> -->
                                            <li *ngIf="currentUser">
                                                <a href="javascript:void(0);" (click)="logout()">
                                                    Disconnect
                                                </a>
                                            </li>

                                        </ul>


                                    </div>
                                </li>
                                .
                                <li class="nav-item sign_up_btn" *ngIf="!currentUser">
                                    <a class="nav-link " data-toggle="modal" data-target="#connectWalletModal" href="javascript:void(0);"> Connect Wallet </a>
                                </li>
                                <!-- <li class="nav-item" *ngIf="currentUser">
                                    <div class="" *ngIf="currentUser?.name">{{ currentUser?.name }}</div>
                                    <div class="" *ngIf="!currentUser?.name">Unnamed</div>
                                    <div class="">
                                        <img [src]="currentUser.image">
                                    </div>
                                    
                                    <a class="nav-link" [routerLink]="['/edit-profile']" href="javascript:void(0);">My Profile</a>
                                    <a class="nav-link" [routerLink]="['/collections']" href="javascript:void(0);">My Collections</a>
                                    <a class="nav-link" href="javascript:void(0);">My Favourites</a>
                                    <a class="nav-link" href="javascript:void(0);">My Account Settings</a> 
                                    <a class="nav-link" href="javascript:void(0);" (click)="logout()">Logout</a>                                   
                                </li> -->

                                <!-- --- after login menus  -->

                                <!-- <li class="nav-item dropdown notiDropdown">
                                        <a href="javascript:void(0);" class="dropdown-toggle" id="dropdownNoti"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fas fa-bell"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right customDropFilter" aria-labelledby="dropdownNoti" (click)="$event.stopPropagation()">
                                            <div class="notficationTitle">
                                                Notifications
                                            </div>
                                            <ul class="notficationList">
                                                <li>
                                                    <div class="balance">
                                                        <div class="priceIcon active"><i class="fab fa-ethereum"></i></div>
                                                        <div class="balancecontent">
                                                            <div class="balanceDes">
                                                                NFT received
                                                                <span class="timeZone">10 min ago</span>
                                                            </div>
                                                            <div class="balanceName">
                                                                10 NFT received
                                                            </div>
                                                        
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div class="balance">
                                                        <div class="priceIcon"><i class="fab fa-ethereum"></i></div>
                                                        <div class="balancecontent">
                                                            <div class="balanceDes">
                                                                Bidding Balance 
                                                                <span class="timeZone">1 days ago</span>
                                                            </div>
                                                            <div class="balanceName">
                                                                New bid : 0.045 WETH
                                                            </div>
                                                        
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div class="balance">
                                                        <div class="priceIcon"><i class="fab fa-ethereum"></i></div>
                                                        <div class="balancecontent">
                                                            <div class="balanceDes">
                                                                Bidding Balance
                                                                <span class="timeZone">1 days ago</span>
                                                            </div>
                                                            <div class="balanceName">
                                                                New bid : 0.045 WETH
                                                            </div>
                                                        
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                </li>

                                <li class="nav-item dropdown profileDropdown">
                                    <a href="javascript:void(0);" class="dropdown-toggle" id="dropdownProfile"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <div class="profileMenu">
                                            <div class="shareCardProfile"
                                                style="background-image: url(assets/img/profile-1.jpg);">
                                                <img src="assets/img/profile-1.jpg" alt="" />
                                            </div>
                                            <div class="profileContent">
                                                <div class="profilePrice">5 WETH</div>
                                                <div class="profileId">0xe88c787c304...691a</div>
                                            </div>
                                        </div>
                                    </a>

                                    <div class="dropdown-menu dropdown-menu-right customDropFilter"
                                        aria-labelledby="dropdownProfile" (click)="$event.stopPropagation()">

                                        <div class="userSection">
                                            <div class="userName">LIRONA</div>
                                            <a href="javascript:void(0);" class="usercpy">
                                                0xe88c787c3.....2db3187685691a
                                                <i class="fal fa-copy"></i>
                                            </a>
                                        </div>
                                        <div class="dropdown-divider"></div>

                                        <div class="transferBalance">
                                            <div class="balance">
                                                <div class="priceIcon active"><i class="fab fa-ethereum"></i></div>
                                                <div class="balancecontent">
                                                    <div class="balanceName">Balance</div>
                                                    <div class="balanceDes">10.33 ETH <span>$2333.44</span></div>
                                                </div>
                                            </div>
                                            <div class="transferHed">
                                                <a href="javascript:void(0);" class="transferBtn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1"
                                                        enable-background="new 0 0 510 510" height="512"
                                                        viewBox="0 0 510 510" width="512">
                                                        <g>
                                                            <path
                                                                d="m450 225v120h-282v-60l-135 90 135 90v-60h342v-180z" />
                                                            <path d="m60 165h282v60l135-90-135-90v60h-342v180h60z" />
                                                        </g>
                                                    </svg>
                                                </a>
                                            </div>

                                            <div class="balance">
                                                <div class="priceIcon"><i class="fab fa-ethereum"></i></div>
                                                <div class="balancecontent">
                                                    <div class="balanceName">Bidding Balance</div>
                                                    <div class="balanceDes">10.33 ETH <span>$2333.44</span></div>
                                                </div>
                                            </div>
                                        </div>




                                        <div class="dropdown-divider"></div>
                                        <ul class="verifiedMenu filterlistMenu">
                                            <li>
                                                <a href="javascript:void(0);">
                                                    My Items
                                                </a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);">
                                                    Edit Profile
                                                </a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);">
                                                    Dark Theme
                                                    <div class="checkCustom">
                                                        <input type="checkbox" hidden id='cuscheck_1'>
                                                        <label class="customLabelcheck" for="cuscheck_1"></label>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);">
                                                    Disconnect
                                                </a>
                                            </li>

                                        </ul>


                                    </div>
                                </li>
                            -->

                            </ul>
                        </div>

                        <div class="mobile_logo">
                            <a href="javascript:void(0);" class="navbar-brand">
                                <img src="assets/img/logo.png"> NFT
                            </a>
                        </div>
                    </div>

                </nav>
            </div>
        </div>
    </div>
</header>




<!-- ------------- connect a wallet modal -->

<div class="modal fade theme-modal" id="connectWalletModal" tabindex="-1" role="dialog" aria-labelledby="checkoutModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="checkoutModalCenterTitle">Connect your wallet</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 340.8 340.8"
                        style="enable-background:new 0 0 340.8 340.8;" xml:space="preserve">
                        <g>
                            <g>
                                <path d="M170.4,0C76.4,0,0,76.4,0,170.4s76.4,170.4,170.4,170.4s170.4-76.4,170.4-170.4S264.4,0,170.4,0z M170.4,323.6
                 c-84.4,0-153.2-68.8-153.2-153.2S86,17.2,170.4,17.2S323.6,86,323.6,170.4S254.8,323.6,170.4,323.6z" />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path d="M182.4,169.6l50-50c3.2-3.2,3.2-8.8,0-12c-3.2-3.2-8.8-3.2-12,0l-50,50l-50-50c-3.2-3.2-8.8-3.2-12,0
                 c-3.2,3.2-3.2,8.8,0,12l50,50l-50,49.6c-3.2,3.2-3.2,8.8,0,12c1.6,1.6,4,2.4,6,2.4s4.4-0.8,6-2.4l50-50l50,50c1.6,1.6,4,2.4,6,2.4
                 s4.4-0.8,6-2.4c3.2-3.2,3.2-8.8,0-12L182.4,169.6z" />
                            </g>
                        </g>
                    </svg>

                </button>
            </div>
            <div class="modal-body checkout-modalBody thememodalBody">
                <p class="modaltop_para">
                    Connect with one of available wallet providers or create a new wallet.
                    <a href="javascript:void(0);">What is wallet?</a>
                </p>

                <ul class="walletList">
                    <li>
                        <a href="javascript:void(0);" data-dismiss="modal" (click)="connectToWallet('fortmatic');">
                            <img src="assets/img/fortMatic.png" alt="" /> Fortmatic
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" data-dismiss="modal" (click)="connectToWallet('walletconnect');">
                            <img src="assets/img/walletconnect.png" alt="" /> Wallet Connet
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" data-dismiss="modal" (click)="connectToWallet('walletlink');">
                            <img src="assets/img/coinbase.png" alt="" /> Coinbase Wallet
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" data-dismiss="modal" (click)="connectToWallet('mewconnect');">
                            <img src="assets/img/myether.png" alt="" /> MyEther Wallet
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" data-dismiss="modal" (click)="connectToWallet('metamask');">
                            <img src="assets/img/metamask.png" alt="" /> Metamask Wallet
                        </a>
                    </li>
                </ul>
                <p class="modaltop_para mb-0">
                    We do not own your private keys and cannot access your funds without your confirmation.
                </p>
            </div>
        </div>
    </div>