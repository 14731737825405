import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NftService } from '../../services/nftservice.service';

@Component({
  selector: 'app-cms-page',
  templateUrl: './cms-page.component.html',
  styleUrls: ['./cms-page.component.css']
})
export class CmsPageComponent implements OnInit {

  slug : string;
  cmsData : any = [];
  constructor(private nftservice: NftService,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe( params => this.cmsPage(params['slug']) );
  }


  cmsPage(slug){
    this.nftservice.cmsPages(slug)
    .subscribe(
      (x) => {
        if(x['status'] == 'success'){
          this.cmsData = x['data']
          console.log(this.cmsData);
        }
      }
    )
  }

}
