<section class="editProfileSection bg-white">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="editProileHead">
                    Activity
                </div>
            </div>
        </div>
    </div>
    <div class="exploreTabhead newBidTab biduserTab">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="all-tab" data-toggle="tab" href="#all" role="tab"
                                aria-controls="all" aria-selected="false">
                                All
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="following-tab" data-toggle="tab" href="#following" role="tab"
                                aria-controls="following" aria-selected="false">
                                Following
                                <span class="countFollow">{{followingList.length}}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="followers-tab" data-toggle="tab" href="#followers" role="tab"
                                aria-controls="followers" aria-selected="false">
                                Followers
                                <span class="countFollow">{{followersList.length}}</span>
                            </a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link " id="activity-tab" data-toggle="tab" href="#activity" role="tab"
                                aria-controls="activity" aria-selected="true">Activity </a>
                        </li>  -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="exploreTabContent">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                            <div class="row">
                                <div class="col-12 col-md-8 offset-md-2 col-lg-5 mx-auto px-0">
                                    <ul class="activityLeft customScroll followersList">
                                        <li *ngFor="let following of followingList">
                                            <div class="followCard">
                                                <div class="followImg active" style="background-image: url({{following.user.image}});">
                                                    <img src="{{following.user.image}}" alt="" />
                                                </div>
                                                <div class="followContent">
                                                    <div class="followUser" *ngIf="following.user.name">@{{following.user.name}}</div>
                                                </div>
                                                <div class="followCount" *ngIf="following.user.address">{{following.user.address | slice:0:15}}</div>
                                                <!-- <a href="javascript:void(0);" class="followBtn">
                                                    <i class="fal fa-user-plus"></i>Follow
                                                </a> -->
                                            </div>
                                        </li>
                                        <li *ngFor="let follower of followersList">
                                            <div class="followCard">
                                                <div class="followImg active" style="background-image: url({{follower.followuser.image}});">
                                                    <img src="{{follower.followuser.image}}" alt="" />
                                                </div>
                                                <div class="followContent">
                                                    <div class="followUser" *ngIf="follower.followuser.name">@{{follower.followuser.name}}</div>
                                                </div>
                                                <div class="followCount" *ngIf="follower.followuser.address">{{follower.followuser.address | slice:0:15}}</div>
                                                <!-- <a href="javascript:void(0);" class="followBtn">
                                                    <i class="fal fa-user-plus"></i>Follow
                                                </a> -->
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="following" role="tabpanel" aria-labelledby="following-tab">
                            <div class="row">
                                <div class="col-12 col-md-8 offset-md-2 col-lg-5 mx-auto px-0">
                                    <ul class="activityLeft customScroll followersList-2">
                                        <li *ngFor="let following of followingList">
                                            <div class="followCard">
                                                <div class="followImg active" style="background-image: url({{following.user.image}});">
                                                    <img src="{{following.user.image}}" alt="" />
                                                </div>
                                                <div class="followContent">
                                                    <div class="followUser" *ngIf="following.user.name">@{{following.user.name}}</div>
                                                </div>
                                                <div class="followCount" *ngIf="following.user.address">{{following.user.address | slice:0:15}}</div>
                                                <!-- <a href="javascript:void(0);" class="followBtn">
                                                    <i class="fal fa-user-plus"></i>Follow
                                                </a> -->
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="alert alert-danger" *ngIf="followingList.length === 0">
                                      No record found.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="followers" role="tabpanel" aria-labelledby="followers-tab">
                            <div class="row">
                                <div class="col-12 col-md-8 offset-md-2 col-lg-5 mx-auto px-0">
                                    <ul class="activityLeft customScroll followersList">
                                        <li *ngFor="let follower of followersList">
                                            <div class="followCard">
                                                <div class="followImg active" style="background-image: url({{follower.followuser.image}});">
                                                    <img src="{{follower.followuser.image}}" alt="" />
                                                </div>
                                                <div class="followContent">
                                                    <div class="followUser" *ngIf="follower.followuser.name">@{{follower.followuser.name}}</div>
                                                </div>
                                                <div class="followCount" *ngIf="follower.followuser.address">{{follower.followuser.address | slice:0:15}}</div>
                                                <!-- <a href="javascript:void(0);" class="followBtn">
                                                    <i class="fal fa-user-plus"></i>Follow
                                                </a> -->
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="alert alert-danger" *ngIf="followersList.length === 0">
                                      No record found.
                                    </div>
                                </div>
                            </div>
                        </div>
                       <div class="tab-pane fade " id="activity" role="tabpanel" aria-labelledby="activity-tab">
                            <div class="row">
                                <div class="col-12 col-md-8 px-0">

                                    <ul class="activityLeft customScroll">
                                        <li>
                                            <a href='javascript:void(0);'>
                                                <div class="activityCard">
                                                    <div class="activityImg"
                                                        style="background-image: url(assets/img/slider-1.jpg);">
                                                        <img src="assets/img/slider-1.jpg" alt="" />
                                                        <span class="excIcon"><i class="far fa-exchange-alt"></i></span>
                                                    </div>
                                                    <div class="activityContent">
                                                        <div class="activityContnetHead">
                                                            Holy Apple
                                                        </div>
                                                        <div class="activityTransform">
                                                            <div class="activityTRanferd">transfered from</div>
                                                            <div class="transferPartner">
                                                                <div class="frstTrnsfer"
                                                                    style="background-image:url(assets/img/procss-1.jpg)">
                                                                    <img src="assets/img/procss-1.jpg" alt="" />
                                                                </div>
                                                                <span>Hugs</span>
                                                                to
                                                                <div class="frstTrnsfer"
                                                                    style="background-image:url(assets/img/procss-2.jpg)">
                                                                    <img src="assets/img/procss-2.jpg" alt="" />
                                                                </div>
                                                                <span>Lirona</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="activityTime">
                                                        59 minutes ago
                                                        <i class="far fa-external-link-alt"></i>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>


                                        <li>
                                            <a href='javascript:void(0);'>
                                                <div class="activityCard">
                                                    <div class="activityImg"
                                                        style="background-image: url(assets/img/procss-1.jpg);">
                                                        <img src="assets/img/procss-2.jpg" alt="" />
                                                        <span class="excIcon"><i class="far fa-exchange-alt"></i></span>
                                                    </div>
                                                    <div class="activityContent">
                                                        <div class="activityContnetHead">
                                                            Holy Apple
                                                        </div>
                                                        <div class="activityTransform">
                                                            <div class="activityTRanferd">transfered from</div>
                                                            <div class="transferPartner">
                                                                <div class="frstTrnsfer"
                                                                    style="background-image:url(assets/img/procss-1.jpg)">
                                                                    <img src="assets/img/procss-1.jpg" alt="" />
                                                                </div>
                                                                <span>Hugs</span>
                                                                to
                                                                <div class="frstTrnsfer"
                                                                    style="background-image:url(assets/img/procss-2.jpg)">
                                                                    <img src="assets/img/procss-2.jpg" alt="" />
                                                                </div>
                                                                <span>Lirona</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="activityTime">
                                                        59 minutes ago
                                                        <i class="far fa-external-link-alt"></i>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>

                                        <li>
                                            <a href='javascript:void(0);'>
                                                <div class="activityCard">
                                                    <div class="activityImg"
                                                        style="background-image: url(assets/img/procss-2.jpg);">
                                                        <img src="assets/img/procss-2.jpg" alt="" />
                                                        <span class="excIcon"><i class="far fa-exchange-alt"></i></span>
                                                    </div>
                                                    <div class="activityContent">
                                                        <div class="activityContnetHead">
                                                            Holy Apple
                                                        </div>
                                                        <div class="activityTransform">
                                                            <div class="activityTRanferd">transfered from</div>
                                                            <div class="transferPartner">
                                                                <div class="frstTrnsfer"
                                                                    style="background-image:url(assets/img/procss-1.jpg)">
                                                                    <img src="assets/img/procss-1.jpg" alt="" />
                                                                </div>
                                                                <span>Hugs</span>
                                                                to
                                                                <div class="frstTrnsfer"
                                                                    style="background-image:url(assets/img/procss-2.jpg)">
                                                                    <img src="assets/img/procss-2.jpg" alt="" />
                                                                </div>
                                                                <span>Lirona</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="activityTime">
                                                        59 minutes ago
                                                        <i class="far fa-external-link-alt"></i>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>

                                        <li>
                                            <a href='javascript:void(0);'>
                                                <div class="activityCard">
                                                    <div class="activityImg"
                                                        style="background-image: url(assets/img/slider-1.jpg);">
                                                        <img src="assets/img/slider-1.jpg" alt="" />
                                                        <span class="excIcon"><i class="far fa-exchange-alt"></i></span>
                                                    </div>
                                                    <div class="activityContent">
                                                        <div class="activityContnetHead">
                                                            Holy Apple
                                                        </div>
                                                        <div class="activityTransform">
                                                            <div class="activityTRanferd">transfered from</div>
                                                            <div class="transferPartner">
                                                                <div class="frstTrnsfer"
                                                                    style="background-image:url(assets/img/procss-1.jpg)">
                                                                    <img src="assets/img/procss-1.jpg" alt="" />
                                                                </div>
                                                                <span>Hugs</span>
                                                                to
                                                                <div class="frstTrnsfer"
                                                                    style="background-image:url(assets/img/procss-2.jpg)">
                                                                    <img src="assets/img/procss-2.jpg" alt="" />
                                                                </div>
                                                                <span>Lirona</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="activityTime">
                                                        59 minutes ago
                                                        <i class="far fa-external-link-alt"></i>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>


                                        <li>
                                            <a href='javascript:void(0);'>
                                                <div class="activityCard">
                                                    <div class="activityImg"
                                                        style="background-image: url(assets/img/procss-1.jpg);">
                                                        <img src="assets/img/procss-2.jpg" alt="" />
                                                        <span class="excIcon"><i class="far fa-exchange-alt"></i></span>
                                                    </div>
                                                    <div class="activityContent">
                                                        <div class="activityContnetHead">
                                                            Holy Apple
                                                        </div>
                                                        <div class="activityTransform">
                                                            <div class="activityTRanferd">transfered from</div>
                                                            <div class="transferPartner">
                                                                <div class="frstTrnsfer"
                                                                    style="background-image:url(assets/img/procss-1.jpg)">
                                                                    <img src="assets/img/procss-1.jpg" alt="" />
                                                                </div>
                                                                <span>Hugs</span>
                                                                to
                                                                <div class="frstTrnsfer"
                                                                    style="background-image:url(assets/img/procss-2.jpg)">
                                                                    <img src="assets/img/procss-2.jpg" alt="" />
                                                                </div>
                                                                <span>Lirona</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="activityTime">
                                                        59 minutes ago
                                                        <i class="far fa-external-link-alt"></i>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>

                                        <li>
                                            <a href='javascript:void(0);'>
                                                <div class="activityCard">
                                                    <div class="activityImg"
                                                        style="background-image: url(assets/img/procss-2.jpg);">
                                                        <img src="assets/img/procss-2.jpg" alt="" />
                                                        <span class="excIcon"><i class="far fa-exchange-alt"></i></span>
                                                    </div>
                                                    <div class="activityContent">
                                                        <div class="activityContnetHead">
                                                            Holy Apple
                                                        </div>
                                                        <div class="activityTransform">
                                                            <div class="activityTRanferd">transfered from</div>
                                                            <div class="transferPartner">
                                                                <div class="frstTrnsfer"
                                                                    style="background-image:url(assets/img/procss-1.jpg)">
                                                                    <img src="assets/img/procss-1.jpg" alt="" />
                                                                </div>
                                                                <span>Hugs</span>
                                                                to
                                                                <div class="frstTrnsfer"
                                                                    style="background-image:url(assets/img/procss-2.jpg)">
                                                                    <img src="assets/img/procss-2.jpg" alt="" />
                                                                </div>
                                                                <span>Lirona</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="activityTime">
                                                        59 minutes ago
                                                        <i class="far fa-external-link-alt"></i>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>

                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="activityRight">
                                        <div class="customDropFilter">
                                            <div class="sortBy">Filters</div>

                                            <ul class="filterlistMenu">
                                                <li>
                                                    <a href="javascript:void(0);" class="active">
                                                        Listing
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        Purchasees
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        Sales
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        Transfers
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        Burns
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        Bids
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        Likes
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        Followings
                                                    </a>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>