import { Component, OnInit } from '@angular/core';
import { NftService } from '../../services/nftservice.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  faqData : any = [];
  constructor(private nftservice: NftService) { }

  ngOnInit(): void {
    this.initializePage();
  }

  initializePage(){
    this.getFaqs();
  }

  getFaqs(){
    this.nftservice.faqs()
    .subscribe(
      (data) => {
        if(data['status'] == 'success'){
          this.faqData = data['data']
        }
      }
    )
  }

}
