import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../../services/global.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NftService } from '../../services/nftservice.service';

@Component({
  selector: 'app-create-collectible',
  templateUrl: './create-collectible.component.html',
  styleUrls: ['./create-collectible.component.css']
})
export class CreateCollectibleComponent implements OnInit {

  addcollectionform: FormGroup;
  public imageURL;
  imgsrc: any = "";
  myFiles = [];
  submitted : boolean = false;
  loading : boolean = false;
  collectionData : any = [];
  subscription: Subscription;
  collectionerror : boolean = false;
  collection_name : string ;
  constructor(private router: Router, private formBuilder: FormBuilder, private toastr: ToastrService , private nftservice : NftService,private globalservice : GlobalService) { }

  ngOnInit(): void {
    this.initializePage()
    this.subscription = this.globalservice.refreshPage.subscribe( (res) => {
      this.initializePage();
    });

    this.addcollectionform = this.formBuilder.group({
      name: ['', Validators.required],
      image: ['', Validators.required],
      description: '',
    })

  }

  initializePage(){
    this.getCollections();
  }

  getCollections(){
    this.nftservice.collections()
    .subscribe(
      (data) => {
        if(data['status'] == 'success'){
          this.collectionData = data['data'];
          //console.log(this.collectionData);
        }
      });     
  }

  get f() { return this.addcollectionform.controls; }

  onFileChange(event) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.myFiles.push(event.target.files[i]);
    }
    const reader = new FileReader();
    this.imageURL = event.target.files;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgsrc = reader.result;
    }
  }

  uniqueCollection(event){
    this.collection_name = event.target.value
    this.nftservice.uniqueCollection(this.collection_name)
    .subscribe(
      (x) => {
        if(x['status'] == 'success'){
          this.collectionerror  = true;
        }else{
          this.collectionerror  = false;  
        }
      }
    )

  }

  onSubmitted(form_data){
    this.submitted = true;
    this.loading  = true;
    if(this.addcollectionform.invalid){
      return;
    }

    const fd: FormData = new FormData();
    Object.keys(form_data).forEach(el => {
      fd.append(el, form_data[el]);
    });

    if (this.myFiles.length > 0) {
      for (var i = 0; i < this.myFiles.length; i++) {
        fd.append("image", this.myFiles[i]);
      }
    }

    this.nftservice.createCollection(fd)
    .subscribe(
      (data) => {
        if(data['status'] == 'success'){
          this.toastr.success(data['message']);
          this.addcollectionform.reset();
          this.submitted = false;
          this.loading  = false;
          this.imgsrc = "";
          this.globalservice.refreshPage.next(true);
        }
      }
    )

  }

}
