import { Component, OnInit } from '@angular/core';
import { NftService } from '../../services/nftservice.service';

@Component({
  selector: 'app-creators',
  templateUrl: './creators.component.html',
  styleUrls: ['./creators.component.css']
})
export class CreatorsComponent implements OnInit {

  creatorsData : any = [];
  constructor(private nftservice: NftService) { }

  ngOnInit(): void {
    this.initializePage();
  }

  initializePage(){
    this.getCreators();
  }

  getCreators(){
    this.nftservice.getAllCreators()
    .subscribe(
      (data) => {
        if(data['status'] == 'success'){
          console.log(data['creatorsList']);
          this.creatorsData = data['creatorsList']
        }
      }
    )
  }

}
