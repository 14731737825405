import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,FormArray ,Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NftService } from '../../services/nftservice.service';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.css']
})
export class CollectionsComponent implements OnInit {

  pricefilterform:FormGroup;
  exploreData : any = [];
  allProductData : any = [];
  page : number = 1;
  page1 : number = 1;
  per_page : any =  0;
  total : any = 0;
  totalP : any = 0;
  loading = false;
  productData : any = [];
  lowPrice : any ='';
  status : any = '';
  collection : any = '';
  highPrice : any = '';
  statusValue : any = 'buy';
  searchData : any ='';
  pricefrom : any = '';
  priceto : any = '';
  // collection : any ='';
  submitted : boolean = false;
  chainName : any ='';
  salestatus : any = '';
  collectionData : any = [];

  constructor(private nftservice: NftService,private toastr: ToastrService,private formbuilder : FormBuilder,private locationpath:Location,private activatedRoute: ActivatedRoute,private router :Router) { 
    this.activatedRoute.params.subscribe((params) => {
    
      this.collection = params['collection_slug'];
      
      // this.lowPrice = params['lowprice'] ? atob(params['lowprice']) : '';
      // this.lowPrice = params['price_from'] ? atob(params['price_from']) : '';
      // this.lowPrice = params['price_to'] ? atob(params['price_to']) : '';
      // this.lowPrice = params['collection'] ? atob(params['collection']) : '';
    });

  }

  ngOnInit(): void {
    localStorage.removeItem('cat_id');
    this.initializePage()
    this.pricefilterform  = this.formbuilder.group({
        min : ['', Validators.required],
        max : ['', Validators.required]
    });
  }

  initializePage(){
    this.getCollectionDetailsData();
    this.collections();
  }

  getCollectionDetailsData(){
    this.nftservice.collectionDetailsData(this.page,this.lowPrice,this.collection,this.pricefrom,this.priceto)
    .subscribe(
      (data) => {
        if(data['status'] == 'success'){          
          this.exploreData = data['data']
          this.allProductData = data['allproducts']
          console.log(this.allProductData);
          this.total = data['total_count'];
          this.per_page = data['per_page'];
        }
      }
    )
  }

  loadMore() {
    this.loading  = true;
    if (this.total > this.per_page) {
      this.page++;
      this.nftservice
        .collectionDetailsData(this.page,this.lowPrice,this.collection,this.pricefrom,this.priceto)
        .subscribe((res) => {
          if (res['status'] == 'success') {
              if (res['allproducts'].length > 0) {                  
                this.loading  = false;
                this.allProductData = [...this.allProductData, ...res['allproducts']];                        
                this.total = res['total_count'];
                this.per_page = res['per_page'];
              }  
          }
        });
    }
  }


  categoryWiseProduct(category_id){
    this.page1 = 1;
    this.productData = [];
    this.nftservice.categoryProducts(category_id,this.page1)
    .subscribe(
      (d)=>{
        if(d['status'] == 'success'){
          this.productData = d['data']
          this.totalP   = d['total_count'];
          this.per_page = d['per_page'];
        }
      }
    )
  }

  loadMoreP(category_id) {
    this.loading  = true;
      if(JSON.parse(localStorage.getItem('cat_id'))){
        this.page1++;
      }else{
        localStorage.setItem('cat_id',JSON.stringify(category_id))
        this.page1 = 1;
        this.page1++;
      }    

      this.nftservice
        .categoryProducts(category_id,this.page1)
        .subscribe((res) => {
          if (res['status'] == 'success') {
              if (res['data'].length > 0) {                  
                this.loading  = false;
                this.productData = [...this.productData, ...res['data']];                        
                this.totalP   = res['total_count'];
                this.per_page = res['per_page'];
              }  
          }
        });
  }

  collections(){
    this.nftservice.collections()
    .subscribe(
      (data) => {
        if(data['status'] == 'success'){
          this.collectionData = data['data']
        }
      }
    )
  }

  filterByPrice(orderby){    
    this.lowPrice = orderby;
   // this.locationpath.replaceState('explore?page=1&orderby='+btoa(this.lowPrice)+'&collection='+btoa(this.collection)+'&price_from='+btoa(this.pricefrom)+'&price_to='+btoa(this.priceto))
    this.page = 1
    this.getCollectionDetailsData();
  }

  filterByStatus(status){
    this.status = status;
    //this.locationpath.replaceState('explore?page=1&orderby='+btoa(this.lowPrice)+'&collection='+btoa(this.collection)+'&price_from='+btoa(this.pricefrom)+'&price_to='+btoa(this.priceto))
    this.page = 1
    this.getCollectionDetailsData();

    this.statusValue = status;
  }

  get f(){ return this.pricefilterform.controls ;}

  filterByPriceRange(){
    this.submitted = true;
    if(this.pricefilterform.invalid){
      return;
    }

    this.pricefrom = this.pricefilterform.value.min;
    this.priceto = this.pricefilterform.value.max;
    // this.locationpath.replaceState('explore?page=1&orderby='+btoa(this.lowPrice)+'&collection='+btoa(this.collection)+'&price_from='+btoa(this.pricefrom)+'&price_to='+btoa(this.priceto))
    this.page = 1
    this.getCollectionDetailsData();

  }

  filterByCollection(event){
    this.collection = event.target.value;
    // this.locationpath.replaceState('explore?page=1&orderby='+btoa(this.lowPrice)+'&collection='+btoa(this.collection)+'&price_from='+btoa(this.pricefrom)+'&price_to='+btoa(this.priceto))
    this.page = 1
    console.log(this.collection);
    if(this.collection.length > 3) {
      this.getCollectionDetailsData();  
    }
  }

  filterByChain(chain){
    this.chainName =  chain;
    // this.locationpath.replaceState('explore?page=1&orderby='+btoa(this.lowPrice)+'&collection='+btoa(this.collection)+'&price_from='+btoa(this.pricefrom)+'&price_to='+btoa(this.priceto))
    this.page = 1
    this.getCollectionDetailsData();
  }

  filterBySale(sale){
    this.salestatus =  sale;
    // this.locationpath.replaceState('explore?page=1&orderby='+btoa(this.lowPrice)+'&collection='+btoa(this.collection)+'&price_from='+btoa(this.pricefrom)+'&price_to='+btoa(this.priceto))
    this.page = 1
    this.getCollectionDetailsData();
  }
  
  updateWistlist(product_id) {
    const fd: FormData = new FormData();
    fd.append('product_id', product_id);
  	this.nftservice.updateWistlist(fd)
  	.subscribe(
  	  (data) => {
  		console.log(data);
  		if(data['status'] == 'success'){
  		  this.toastr.success(data['message']);
  		} else {
            this.toastr.error(data['message']);
          }
  	  }
  	);
  }

}
