import { Component, OnInit } from '@angular/core';
import { NftService } from '../../services/nftservice.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-item',
  templateUrl: './edit-item.component.html',
  styleUrls: ['./edit-item.component.css']
})
export class EditItemComponent implements OnInit {

  editItemForm : FormGroup;
  categoryData : any = [];
  subscription: Subscription;
  myFiles = [];
  imgsrc : any = '';
  public imageURL;
  submitted : boolean =false;
  submitted1 : boolean =false;
  properties: FormArray;
  levels: FormArray;
  stats: FormArray;
  product_id : any;
  productData : any;
  propertyarr : any =[];
  levelarr : any =[];
  statarr : any =[];
  loading : boolean = false;
  constructor(private nftservice : NftService,private router: Router,private route: ActivatedRoute,private toastr: ToastrService,private globalservice : GlobalService, private formBuilder: FormBuilder) { 
    this.route.params.subscribe(params => {
    
      this.product_id = params['id'] ? params['id']: '';      
      
    });
  }

  editItem(){
    this.nftservice.itemDetails(this.product_id)
    .subscribe(
      (data) => {
        if(data['status'] == 'success'){
          this.productData = data['data']
          console.log(this.productData);

          let propertyarr = []
          for(let i=0;i<=this.productData.properties.length;i++){
            propertyarr.push(this.productData.properties[i])
          }

          let control = < FormArray > this.editItemForm.controls['properties'];
           if(this.productData.properties.length > 0){
             for(let i=0;i<this.productData.properties.length;i++){
             let form = this.formBuilder.group({        
              type: [this.productData.properties[i].property_type],
              name: [this.productData.properties[i].property_name], 
             });     
               control.push(form);  
             }
           }else{
             let form = this.formBuilder.group({        
               type: [''],
               name: [''],
             });   
             control.push(form);
           }

           let control1 = < FormArray > this.editItemForm.controls['levels'];
           if(this.productData.levels.length > 0){
             for(let i=0;i<this.productData.levels.length;i++){
             let form = this.formBuilder.group({                      
              name: [this.productData.levels[i].level_name], 
              from: [this.productData.levels[i].level_value1],
              to: [this.productData.levels[i].level_value2],
             });     
               control1.push(form);  
             }
           }else{
             let form = this.formBuilder.group({        
               name: [''],
               from : [''],
               to : ['']
             });   
             control1.push(form);
           }

           let control2 = < FormArray > this.editItemForm.controls['stats'];
           if(this.productData.stats.length > 0){
             for(let i=0;i<this.productData.stats.length;i++){
             let form = this.formBuilder.group({                      
              name: [this.productData.stats[i].stat_name], 
              from: [this.productData.stats[i].stat_value1],
              to: [this.productData.stats[i].stat_value2],
             });     
               control2.push(form);  
             }
           }else{
             let form = this.formBuilder.group({        
               name: [''],
               from : [''],
               to : ['']
             });   
             control2.push(form);
           }

          this.imgsrc = this.productData.image

          this.editItemForm.patchValue({            
            name : this.productData.name,
            description : this.productData.description,
            link : this.productData.external_link,
            category_id : this.productData.category_id,
            unlock_description : this.productData.unlockable_description
          })
        }
      }
    )
  }

  ngOnInit(): void {
    this.initializePage();
    this.subscription = this.globalservice.refreshPage.subscribe( (res) => {
      this.initializePage();
    });
    this.editItemForm = this.formBuilder.group({   
      name: ['',Validators.required],
      description: '',
      link: '',
      supply: '1',
      category_id: ['',Validators.required],
      on_sale : '',
      instant_sale : '',
      price : '',
      image : '',
      unlock_description : '',
      properties: this.formBuilder.array([]),
      levels: this.formBuilder.array([]),
      stats: this.formBuilder.array([])
    });
  }

  initializePage(){
    this.productdata();
    this.editItem();
  }

  productdata(){
    this.nftservice.productData()
    .subscribe(
      (x) => {
        if(x['status'] == 'success'){
          this.categoryData = x['categories'];
        }
      }
    )
  }

  showPrice(){
    if(!this.editItemForm.value.instant_sale){
      document.getElementById('price_piece').style.display ="block";
    }else{
      document.getElementById('price_piece').style.display ="none";
    }
    
  }

  get f(){ return this.editItemForm.controls; } 

  onFileChange(event) {
    for (var i = 0; i < event.target.files.length; i++) { 
      this.myFiles.push(event.target.files[i]);
    }
    const reader = new FileReader();
    this.imageURL = event.target.files;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => { 
      this.imgsrc = reader.result;
    }
    const file = event.target.files[0];
    this.editItemForm.get('image').setValue(file);

  }

  addProperty(): void {
    this.properties = this.editItemForm.get('properties') as FormArray;
    this.properties.push(this.createProperty());
  }

  removeProperty(rowIndex:number){
    if(rowIndex > 0){
      this.properties.removeAt(rowIndex);
    }
    
  }

  createProperty(): FormGroup {
    return this.formBuilder.group({
      type: '',
      name: ''
    });
  }


  addLevel(): void {
    this.levels = this.editItemForm.get('levels') as FormArray;
    this.levels.push(this.createLevel());
  }

  removeLevel(rowIndex:number){
    if(rowIndex > 0){
      this.levels.removeAt(rowIndex);
    }
    
  }

  createLevel(): FormGroup {
    return this.formBuilder.group({
      name : '',
      from : '',
      to   : ''

    });
  }

  addStat(): void {
    this.stats = this.editItemForm.get('stats') as FormArray;
    this.stats.push(this.createStat());
  }

  removeStat(rowIndex:number){
    if(rowIndex > 0){
      this.stats.removeAt(rowIndex);
    }
    
  }

  createStat(): FormGroup {
    return this.formBuilder.group({
      name: '',
      from: '',
      to: ''
    });
  }

  onSubmit(form_data){
    this.submitted = true;
    console.log(this.editItemForm.value);
    if(this.editItemForm.invalid){
      return;
    }

    this.loading = true;

    const fd:FormData = new FormData();
    Object.keys(form_data).forEach(el => {      
      fd.append(el, form_data[el]);
      fd.append("properties", JSON.stringify(form_data['properties']));
      fd.append("levels", JSON.stringify(form_data['levels']));
      fd.append("stats", JSON.stringify(form_data['stats']));
    });

    if(this.myFiles.length > 0 ){      
      for (var i = 0; i < this.myFiles.length; i++) { 
        fd.append("image", this.myFiles[i]);
      }
    }

    this.nftservice.updateItem(this.product_id,fd)
    .subscribe(
      (data) => {
        if(data['status'] == 'success'){
          this.loading = false;
          this.submitted = false;
          this.toastr.success(data['message'])
          this.editItemForm.reset();  
          //this.router.navigate(['/bid-detail',this.token_id]);
        }
      }
    )

  }

}
