<section class="biduserSection collectDetailSecton" *ngIf="!showform">
    <div class="bannerImageSection" style="background-image: url(assets/img/banner_img.jpg);">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="usrShare">
                        <div class="usrProfile" style="background-image: url({{collectiondtls.image}});">
                            <img src="{{collectiondtls.image}}" alt="" />
                        </div>

                        <a href="javascript:void(0);" class="shareCard" [routerLink]="['/collections']">
                            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="long-arrow-left"
                                role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                class="svg-inline--fa fa-long-arrow-left fa-w-14">
                                <path fill="currentColor"
                                    d="M136.97 380.485l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L60.113 273H436c6.627 0 12-5.373 12-12v-10c0-6.627-5.373-12-12-12H60.113l83.928-83.444c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0l-116.485 116c-4.686 4.686-4.686 12.284 0 16.971l116.485 116c4.686 4.686 12.284 4.686 16.97-.001z"
                                    class=""></path>
                            </svg>
                            <div class="shareCardProfileName">
                                <h5>Back</h5>
                            </div>

                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="userContentSection">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="bidcontentLeft bidUserLeft">
                        <div class="bidContentHed">
                            {{collectiondtls.collection_name}}
                        </div>
                        <div class="bidDes">
                            {{collectiondtls.description}}
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="bidcontentRight bidUserRight">

                        <a href="javascript:void(0);" (click)="editcollection(collectiondtls.id)"
                            class="followBtn editProfileBtn">
                            <i class="fal fa-edit"></i>
                            Edit Collection
                        </a>

                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="exploreTabhead newBidTab biduserTab">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="editProileHead">
                        <h4>ITEMS</h4>
                        <a href="javascript:void(0);" class="followBtn" (click)="signInToMetamask()">
                            Add New Item
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="exploreTabContent">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="hotCollectparent" *ngIf="collectionItemData.length > 0; else noitem">
                        <div class="hotCollectionCard" *ngFor="let item of collectionItemData">
						<a routerLink="/assets/{{item.token_id}}" class="placeBidHref">
                            <div class="hotCollectImg" style="background-image: url({{item.image}});">
                                <img src="{{item.image}}" alt="" />
                            </div>
                            <div class="hotCollectBody">
                                <a href="javascript:void(0)" class="hotBidCardHead">{{item.name}}</a>
                                <div class="hotBidCardRank">{{item.description}}</div>
                            </div>
						</a>
                        </div>

                    </div>

                    <ng-template #noitem>
                        <div class="hotCollectparent justify-content-center pt-5 mt-5">
                            <p>No Item Found.</p>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="editProfileSection collectDetailSectonEdit" *ngIf="showform">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-10 col-lg-8 col-xl-7 mx-auto">
                <a href="javascript:void(0);" (click)="back()">Back</a>
                <form [formGroup]="editcollectionform">
                    <div class="editProileHead">Edit collection details</div>
                    <div class="editSubHead">You can set preferred collection details, create your branded collect detail and
                        manage
                        other personal settings</div>


                    <div class="editProfileCard">
                        <div class="profileUpload">
                            <div class="profieImgView" [ngStyle]="{'background-image': 'url(' + imgsrc + ')'}">
                                <img [src]="imgsrc" id="upload_img">
                            </div>

                            <div class="profileViewContent">
                                <div class="customFile">
                                    <input hidden type="file" formControlName="image" id="editImgCollect" class="form-control"
                                        (change)="onFileChange($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.image.errors }" />
                                    <label for="editImgCollect">
                                        Choose file
                                    </label>
                                </div>
                                <div class="notePrfileImg">
                                    We recommend an image of at least 400x400.
                                    <br>
                                    Gifs work too.
                                </div>
                            </div>
                        </div>

                        <div class="formHead">
                            <div class="form-group">
                                <label for="display">Name</label>
                                <input type="text" formControlName="name" class="form-control" (keyup)="uniqueCollectionEdit($event)"
                                    [ngClass]="{ 'is-invalid': submitted && f.name.errors || nameerror }" />
                                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                    <div *ngIf="f.name.errors.required">name is required</div>
                                </div>
                                <div class="invalid-feedback" *ngIf="nameerror">collection already exists</div>
                            </div>

                            <div class="form-group ">
                                <label for="desCollect">Description</label>
                                <textarea type="text" rows="5" id="desCollect" formControlName="description" class="form-control"></textarea>
                                <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                                    <div *ngIf="f.description.errors.required">description is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="editFooter">
                            <button type="button" (click)="onSubmitted(editcollectionform.value)" class="updateBtn">Update</button>
                        </div>


                    </div>
                </form>
            </div>
        </div>
    </div>
</section>