import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError, from } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NftService {
  public baseURL;
  public isloggedIn = false;

  constructor(private router: Router, private http: HttpClient) { 
    this.baseURL = environment.apiUrl;
  }

  sliders() {
    return this.http.get(this.baseURL + 'sliders')
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  wishlistData() {
    return this.http.get(this.baseURL + 'wishlist-list')
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  topsellers(filtertime) {
    return this.http.get(this.baseURL + 'top-seller?filterby='+filtertime)
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  topbuyers(filtertime) {
    return this.http.get(this.baseURL + 'top-buyer?filterby='+filtertime)
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  hotBid() {
    return this.http.get(this.baseURL + 'hot-bid')
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  hotCollections() {
    return this.http.get(this.baseURL + 'hot-collections')
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  featuredCreators() {
    return this.http.get(this.baseURL + 'featured-creators')
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  explore(page,orderby,collection,pricefrom,priceto,status) {
    console.log(orderby);
    return this.http.get(this.baseURL + 'explore?page_number='+ page+ '&orderby='+orderby+'&collection='+collection+'&price_from='+pricefrom+'&price_to='+priceto+'&status='+status)
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  collectionDetailsData(page,orderby,collection,pricefrom,priceto) {
    console.log(collection);
    return this.http.get(this.baseURL + 'collection-details-data?page_number='+ page+ '&orderby='+orderby+'&collection='+collection+'&price_from='+pricefrom+'&price_to='+priceto)
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  account(page,orderby,collection,pricefrom,priceto) {
    console.log(orderby);
    return this.http.get(this.baseURL + 'user-data?page_number='+ page+ '&orderby='+orderby+'&collection='+collection+'&price_from='+pricefrom+'&price_to='+priceto)
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  categoryProducts(id,page) {
    return this.http.get(this.baseURL + 'products/'+id+'?page_number='+page)
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  cmsPages(slug) {
    return this.http.get(this.baseURL + 'cms-pages?slug='+slug)
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  settings() {
    return this.http.get(this.baseURL + 'settings')
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }
  
  faqs() {
    return this.http.get(this.baseURL + 'faqs')
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  productData() {
    return this.http.get(this.baseURL + 'product-data')
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  searchInfo() {
    return this.http.get(this.baseURL + 'search-data')
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  itemDetails(token_id) {
    return this.http.get(this.baseURL + 'item-details/'+token_id)
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  loginregister(address) {
    return this.http.get(this.baseURL + 'login-register/'+address)
      .pipe(
        map(data => {
          if (data && data['status'] == 'success') {
            this.isloggedIn = true;
            localStorage.setItem('currentUser', JSON.stringify(data['data']));
            localStorage.setItem('token', data['token']);
          }
          return data;
        //catchError(this.handleError) // then handle the error;
        }));
  }

  createSingleCollectible(form_data) {
    return this.http.post(this.baseURL + 'create-single-collectible',form_data)
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  updateWistlist(form_data) {
    return this.http.post(this.baseURL + 'add-update-wishlist',form_data)
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  createCollection(form_data) {
    return this.http.post(this.baseURL + 'create-collection',form_data)
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  collections() {
    return this.http.get(this.baseURL + 'collections')
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  allcollections() {
    return this.http.get(this.baseURL + 'all-collections')
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  collectionDetails(slug) {
    return this.http.get(this.baseURL + 'collection-details/'+slug)
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  collectionItems(collection_slug) {
    return this.http.get(this.baseURL + 'list-items/'+collection_slug)
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  profile() {
    return this.http.get(this.baseURL + 'my-profile')
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  updateProfile(form_data) {
    return this.http.post(this.baseURL + 'update-profile',form_data)
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  updateCollection(id,form_data) {
    return this.http.post(this.baseURL + 'update-collection/'+id,form_data)
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  uniqueCollection(collection) {
  
    return this.http.get(this.baseURL + 'unique-collection?name='+collection)
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  uniqueCollectionedit(id,collection) {
    return this.http.get(this.baseURL + 'unique-collection-edit/'+id+'?name='+collection)
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  soldProducts() {
    return this.http.get(this.baseURL + 'sold-products')
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  userProducts() {
    return this.http.get(this.baseURL + 'user-products')
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  userFavourites() {
    return this.http.get(this.baseURL + 'user-favourites')
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  userActivities() {
    return this.http.get(this.baseURL + 'activities')
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }
  
  placeOnSell(form_data) {
    return this.http.post(this.baseURL + 'place-on-sell',form_data)
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }
  
  placeOnBid(form_data) {
    return this.http.post(this.baseURL + 'place-on-bid',form_data)
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  reportProduct(form_data) {
    return this.http.post(this.baseURL + 'report-product',form_data)
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }
  
  buyItem(form_data) {
    return this.http.post(this.baseURL + 'buy-item',form_data)
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  updateItem(id,form_data) {
    return this.http.post(this.baseURL + 'update-product/'+id,form_data)
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.log('error1');
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      console.log('error2');
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  importCollection(form_data) {
    return this.http.post(this.baseURL + 'import-collection',form_data)
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  followUnfollowUser(form_data) {
    return this.http.post(this.baseURL + 'follow-unfollow',form_data)
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  getOtherUserProfileDetails(address) {
    return this.http.get(this.baseURL + 'other-user-details/'+address)
      .pipe(
        catchError(this.handleError)
      )
  }
  
  getUserAccountDetails() {
    return this.http.get(this.baseURL + 'my-account-details')
      .pipe(
        catchError(this.handleError)
      )
  }

  getUserNotificationList() {
    return this.http.get(this.baseURL + 'notifications-list')
      .pipe(
        catchError(this.handleError)
      )
  }

  getAllCreators() {
    return this.http.get(this.baseURL + 'all-creators')
      .pipe(
        catchError(this.handleError)
      )
  }

  updateBidUserId(form_data) {
    return this.http.post(this.baseURL + 'update-bid-user',form_data)
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  updatePurchaseUserId(form_data) {
    return this.http.post(this.baseURL + 'update-buy-user',form_data)
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  updateRejectBid(form_data) {
    return this.http.post(this.baseURL + 'update-reject-bid',form_data)
      .pipe(
        catchError(this.handleError) // then handle the error;
      )
  }

  
}