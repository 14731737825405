import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { NftService } from '../../services/nftservice.service';
import { FormBuilder, FormGroup,FormArray ,Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  sliderData : any = [];
  subscription: Subscription;
  topSeller : any = [];
  topBuyer : any = [];
  hotBids : any = [];
  hotCollection : any = [];
  exploreData : any = [];
  allProductData : any = [];
  creatorData : any = [];
  page : number = 1;
  page1 : number = 1;
  per_page : any =  0;
  total : any = 0;
  totalP : any = 0;
  loading = false;
  lowPrice : any = '';
  highPrice : any = '';
  filtertime : any = 'month';
  searchData : any ='';
  pricefrom : any = '';
  priceto : any = '';
  status : any = '';
  collection : any ='';
  productData : any = [];
  category_id : any = '';
  keyword = 'name';
  // public data = [
  //   {
  //     id: 1,
  //     name: 'Albania',
  //   },
  //   {
  //     id: 2,
  //     name: 'Belgium',
  //   },
  //   {
  //     id: 3,
  //     name: 'Denmark',
  //   },
  //   {
  //     id: 4,
  //     name: 'Montenegro',
  //   }
    
  // ];

  constructor(private nftservice: NftService,private locationpath:Location, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute,private router :Router,private toastr: ToastrService, private globalService: GlobalService) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.lowPrice = params['lowprice'] ? atob(params['lowprice']) : '';
      this.lowPrice = params['price_from'] ? atob(params['price_from']) : '';
      this.lowPrice = params['price_to'] ? atob(params['price_to']) : '';
      this.lowPrice = params['collection'] ? atob(params['collection']) : '';
    });
    
   }

  selectEvent(item) {
    console.log(item);
    if(item.type == "product") {
      this.router.navigate(['/assets/'+item.token_id]);
    }
    // do something with selected item
  }

  banner = {
    center:true,
    loop: true,
    rewind:true,
    margin: 20,
    nav: false,
    dots: false,
    smartSpeed:1000,
    autoplay: true,
    startPosition:2,
    responsive: {
      0: {
        items: 1
      },
      992: {
        items: 2
      },
      1200: {
        items: 2
      }
                }
  }

  hotBid = {
    loop: false,
    rewind:false,
    margin:15,
    nav: true,
    dots: false,
    smartSpeed:1000,
    autoplay: true,
    navText:["<i class='fa fa-angle-left arrowLeft'></i>","<i class='fa fa-angle-right arrowRight'></i>"],
    responsive: {
      0: {
        items: 1
      },
      576:{
        items:2
      },
      992: {
        items: 4
      },
      1200: {
        items: 5
      }
                }
  }

  ngOnInit(): void {
      localStorage.removeItem('cat_id');
      this.initializePage();
      this.subscription= this.globalService.refreshPage.subscribe((res) => {
        this.initializePage();
      })
  }

  initializePage(){
    this.getSliders();
    this.getTopseller(this.filtertime);
    this.getTopBuyer(this.filtertime);
    this.getHotbid();
    this.getHotCollection();
    this.getExplore();
    this.getFeaturedcreators();
    this.getsearch();
  }
  
  getsearch(){
    this.nftservice.searchInfo()
    .subscribe(
      (s) => {
        if(s['status'] == 'success'){
          console.log(s['data']);
          this.searchData = s['data'];
        }
      }
    )

  }

  onChangeSearch(search: string) {
    this.getsearch();
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    //console.log(e);
  }

  getSliders(){
    this.nftservice.sliders()
    .subscribe(
      (data) => {
        if(data['status'] == 'success'){
          console.log(data['data']);
          this.sliderData = data['data']
        
        }
      }
    )
  }

  getTopseller(filtertime){
    this.nftservice.topsellers(this.filtertime)
    .subscribe(
      (data) => {
        if(data['status'] == 'success'){
          console.log(data['data']);
          this.topSeller = data['data']
        }
      }
    )
  }

  getTopBuyer(filtertime){
    this.nftservice.topbuyers(this.filtertime)
    .subscribe(
      (data) => {
        if(data['status'] == 'success'){
          this.topBuyer = data['data']
        }
      }
    )
  }

  getHotbid(){
    this.nftservice.hotBid()
    .subscribe(
      (data) => {
        if(data['status'] == 'success'){
          this.hotBids = data['data']
        }
      }
    )
  }

  getHotCollection(){
    this.nftservice.hotCollections()
    .subscribe(
      (data) => {
        if(data['status'] == 'success'){
          this.hotCollection = data['data']
        }
      }
    )
  }

  getFeaturedcreators(){
    this.nftservice.featuredCreators()
    .subscribe(
      (data) => {
        if(data['status'] == 'success'){
          console.log(data['data']);
          this.creatorData = data['data']
        }
      }
    )
  }

  getExplore(){
    this.nftservice.explore(this.page,this.lowPrice,this.collection,this.pricefrom,this.priceto,this.status)
    .subscribe(
      (data) => {
        if(data['status'] == 'success'){
          console.log(data['allproducts']);
          this.exploreData = data['data']
          this.allProductData = data['allproducts']
          this.total        = data['total_count'];
          this.per_page = data['per_page'];
        }
      }
    )
  }

  loadMore() {
    this.loading  = true;
    if (this.total > this.per_page) {
      this.page++;
      this.nftservice
        .explore(this.page,this.lowPrice,this.collection,this.pricefrom,this.priceto, this.status)
        .subscribe((res) => {
          if (res['status'] == 'success') {
              if (res['allproducts'].length > 0) {                  
                this.loading  = false;
                this.allProductData = [...this.allProductData, ...res['allproducts']];                        
                this.total = res['total_count'];
                this.per_page = res['per_page'];
              }  
          }
        });
    }
  }

  categoryWiseProduct(category_id){
    this.category_id = category_id;
    this.page1 = 1;
    this.productData = [];
    this.nftservice.categoryProducts(category_id,this.page1)
    .subscribe(
      (d)=>{
        if(d['status'] == 'success'){
          this.productData = d['data']
          this.totalP   = d['total_count'];
          this.per_page = d['per_page'];
        }
      }
    )
  }

  updateWistlist(product_id) {
    const fd: FormData = new FormData();
    fd.append('product_id', product_id);
    
    this.nftservice.updateWistlist(fd)
      .subscribe(
        (data) => {
          console.log(data);
          if(data['status'] == 'success'){
            this.toastr.success(data['message']);
            this.getHotbid();
            this.getExplore();
            this.categoryWiseProduct(this.category_id);
          } else {
            this.toastr.error(data['message']);
          }
        }
      );
  }

  loadMoreP(category_id) {
    this.loading  = true;
      if(JSON.parse(localStorage.getItem('cat_id'))){
        this.page1++;
      }else{
        localStorage.setItem('cat_id',JSON.stringify(category_id))
        this.page1 = 1;
        this.page1++;
      }    

      this.nftservice
        .categoryProducts(category_id,this.page1)
        .subscribe((res) => {
          if (res['status'] == 'success') {
              if (res['data'].length > 0) {                  
                this.loading  = false;
                this.productData = [...this.productData, ...res['data']];                        
                this.totalP   = res['total_count'];
                this.per_page = res['per_page'];
              }  
          }
        });
  }


  filterByLowPrice(orderby){    
    this.lowPrice = orderby;
    this.locationpath.replaceState('explore?page=1&orderby='+btoa(this.lowPrice)+'&collection='+btoa(this.collection)+'&price_from='+btoa(this.pricefrom)+'&price_from='+btoa(this.priceto))
    this.page = 1
    this.getExplore();
  }

  filterbyperiod(event){
    this.filtertime = event.target.value;
    this.getTopseller(this.filtertime);

  }

  filterbbyperiod(event){
    this.filtertime = event.target.value;
    this.getTopBuyer(this.filtertime);
  }

  

}
