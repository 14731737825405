import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse
} from '@angular/common/http';
import {  Router } from '@angular/router';

import { Observable } from 'rxjs/Observable';

import { environment } from '../environments/environment';
import { LoaderService } from './loader/loader.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private requests: HttpRequest<any>[] = [];

  constructor(private loaderService: LoaderService,private router :Router) {

  }
  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
       this.requests.splice(i, 1);
    }
 
    
     if(req.url.split('/')[req.url.split('/').length -1].split('?')[0] !=='save-chat-history')
    this.loaderService.isLoading.next(this.requests.length > 0);
    
   
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({headers: req.headers.set('Accept-Language', localStorage.getItem('browserLanguage') || '{}')});
    //req = req.clone({headers: req.headers.set('Accept-Currency', localStorage.getItem('currency') || '{}')});
    const token = localStorage.getItem('token');
    req = req.clone({
      setHeaders: {
        'Authorization': 'Bearer ' + token,
      }
    });
    this.requests.push(req);
    this.loaderService.isLoading.next(true);

    return Observable.create(observer => {
      const subscription = next.handle(req)
        .subscribe(
            event => {
                if (event instanceof HttpResponse) {
                    this.removeRequest(req);
                    observer.next(event);
                }
            },
            err => {
                if(err.status == 401)
                {                        
                    localStorage.removeItem('authUser')
                    //window.location.href = '' 
                    this.router.navigate(['/login'])
                }
                this.removeRequest(req);
                observer.error(err);
            },
            () => {
                this.removeRequest(req);
                observer.complete();
            }
        );
        return () => {
            this.removeRequest(req);
            subscription.unsubscribe();
        };
    });
    
  }
}