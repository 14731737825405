import { Component, OnInit , HostListener } from '@angular/core';
import { NftService } from '../../services/nftservice.service';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../../services/global.service';
import { Web3Service } from 'src/app/web3.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Location, DatePipe } from '@angular/common';


declare var setShareLinks: Function;
declare var copyToClipboard: Function;
declare var hideSellStepPopup: Function;
declare var showPopup: Function;

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.css']
})
export class AssetsComponent implements OnInit {

  placeonsellform: FormGroup;
  placeonsellauctionform: FormGroup;
  placeonbidform: FormGroup;
  rejectonbidform: FormGroup;
  placeonreportform: FormGroup;
  buyItemform : FormGroup;	
  product_id : any;
  token_id : any;
  bidprice : any;
  productdtls : any;
  buyData : any;
  fileURL : any;
  basePrice : any;
  webkitRequestFullScreen : any;
  sellerData : any;
  auctionData : any;
  sellPopupApprove : any = 'StartApprove';
  sellPopupTokenApprove : any = 'StartTokenApprove';
  bidPopupApprove : any = 'CheckingBalance';
  sellPopupTransaction : any = 'StartTransaction';
  buyPopupTransaction : any = 'StartTransaction';
  signClass : any = '';
  signText : any = 'Pending';
  signTextApproveToken : any = 'Pending';
  signTextBid : any = 'Pending';
  signTextApprove : any = 'Pending';
  stepPopup : any = 'Show';
  selltype : any = 'Price';
  bidButtonText : any = 'Bid';
  acceptBidData : any;
  cancelBidData : any;
  bidData : any;
  currentUser : any;
  form_data : any;
  submitted : boolean = false;
  loading : boolean = false;
  highest_bidder : any ;
  fee = 0 ;
  subscription: Subscription;
  signature : any ;
  bidAcceptRecord :  any = [];
  bidRejectRecord :  any = [];
  sellData : any ;
  ownerData : any = [];
  histories : any = [];
  userBidRecord : any = [];
  imgsrc : any = [];
  youwillget = 0 ;
  constructor(private nftservice : NftService,private router: Router,private toastr: ToastrService,private globalservice : GlobalService,public web3Service: Web3Service, private _location: Location, private formBuilder: FormBuilder, private route: ActivatedRoute) {
    var self = this;
    this.route.params.subscribe(params => {
    
      this.token_id = params['token_id'] ? params['token_id']: '';      
      
    });

    this.subscription = this.globalservice.refreshUserData.subscribe( (res) => {
				this.initializePage();
    });

   	this.web3Service.handleSeaportEvents().then(result=>{	
   		console.log(result);		
			console.log("Event Call");
		});

		this.subscription = this.globalservice.checkEventListenData.subscribe( (res) => {
				//Code for Sell Order
				if(res == "StartApprove") {
					this.sellPopupApprove = "StartApprove";
					this.sellPopupTokenApprove = "StartApprove";
				}

				if(res == "EndApprove") {
					this.sellPopupApprove = "EndApprove";
					this.sellPopupTokenApprove = "EndApprove";
				}
				if(res == "CreateSign") {
					this.sellPopupApprove = "CreateSign";
				}
				if(res == "EndSign") {
					this.sellPopupApprove = "EndSign";
					this.signClass = 'active';
					this.signText = 'Completed';
					//this.stepPopup = 'Hide';
					setTimeout(function(){
						self.initializePage();
						hideSellStepPopup("followstepModalCenter");
					}, 1000);
				}

				//Code for Bid Order
				if(res == "SuffBalance") {
					this.bidPopupApprove = "SuffBalance";
				}

				if(res == "LowBalance") {
					this.bidPopupApprove = "LowBalance";
				}

				if(res == "EndBidSign") {
					this.bidPopupApprove = "EndBidSign";
					setTimeout(function(){
						self.initializePage();
						hideSellStepPopup("followstep2ModalCenter");
					}, 1000);
				}

				if(res == "CreateBidSign") {
					this.signTextBid = "Completed";
					this.bidPopupApprove = "CreateBidSign";
				}

				if(res == "EndApproveBid") {
					this.signTextBid = "Completed";
					this.bidPopupApprove = "EndApproveBid";
				}
				
				if(res == "StartApproveBid") {
					this.bidPopupApprove = "StartApproveBid";
					this.signTextApprove = "Completed";
				}

				if(res == "BuyEndSign") {
					// this.sellPopupTransaction = "EndTransaction";
					this.buyPopupTransaction = "EndTransaction";
					setTimeout(function(){
						self.initializePage();
						hideSellStepPopup("checkoutModalCenter");
						hideSellStepPopup("acceptbidModalCenter");
						hideSellStepPopup("acceptBidProcessModalCenter");
						hideSellStepPopup("buyBidProcessModalCenter");
					}, 1000);
				}

				if(res == "StartTokenApprove") {
					this.signTextApprove = "Completed";
					// this.signTextApproveToken = "Completed";
					this.sellPopupTokenApprove = "StartTokenApprove";
				}

				if(res == "EndTokenApprove") {
					// this.signTextApproveToken = "Completed";
					this.sellPopupTokenApprove = "EndTokenApprove";
				}

				if(res == "TransactionCreated") {
					this.signTextApproveToken = "Completed";
					this.sellPopupTokenApprove = "TransactionCreated";
				}


    });

   }

	ngOnInit(): void {		
		this.initializePage();
		this.placeonsellform = this.formBuilder.group({
			sellamount: ['', Validators.required],
			product_id: [this.product_id, Validators.required],
			sellsig: '',
		});

		this.placeonsellauctionform = this.formBuilder.group({
			sellamount: ['', Validators.required],
			reserveprice: ['', Validators.required],
			expirationdays: ['', Validators.required],
			product_id: [this.product_id, Validators.required],
			sellsig: '',
		});

		this.placeonbidform = this.formBuilder.group({
			price: ['', Validators.required],
			product_id: [this.product_id, Validators.required]
		});

		this.rejectonbidform = this.formBuilder.group({
			bid_id: ['', Validators.required],
			reason: ['', Validators.required]
		});

		this.placeonreportform = this.formBuilder.group({
			reason: ['', Validators.required],
			comments: ['', Validators.required],
			product_id: [this.product_id, Validators.required]
		});

		this.buyItemform = this.formBuilder.group({
			amount: '',
			quantity: ['1', Validators.required],
		});

		this.subscription = this.globalservice.sellSignInApproved.subscribe( (res) => {
			
			const fd: FormData = new FormData();
			fd.append('product_id', this.product_id);
			fd.append('sellamount', this.placeonsellform.value.sellamount);
			fd.append('signature', res);
			
			this.nftservice.placeOnSell(fd)
				.subscribe(
				  (data) => {
					if(data['status'] == 'success'){
					  this.toastr.success(data['message']);
					  this.placeonsellform.reset();
					  this.submitted = false;
					  this.loading  = false;
					}
				  }
				);
		});
		
		this.subscription = this.globalservice.signInDisApproved.subscribe( (res) => {
			this.toastr.error("Canceled!");  
		});
	}

	async initializePage(){
		this.currentUser  = JSON.parse(localStorage.getItem('currentUser'));	
		this.getItemsDetails()

		

	}
	
	get f() { return this.placeonsellform.controls; }
	get fh() { return this.placeonsellauctionform.controls; }
	get h() { return this.placeonbidform.controls; }
	get hr() { return this.rejectonbidform.controls; }
	get r() { return this.placeonreportform.controls; }
	get g() { return this.buyItemform.controls ;}

	getItemsDetails(){
			var self = this;
		  this.nftservice.itemDetails(this.token_id)
		  .subscribe(
			(data) => {
			  if(data['status'] == 'success'){
			  	console.log(data['userBidRecord']); 
			  	this.product_id = data['data'].id;
					this.histories	= data['histories']
					this.userBidRecord	= data['userBidRecord']
					this.productdtls = data['data']
					this.fileURL = data['data']['file_name'];
					this.highest_bidder = data['hbidder']
					this.ownerData	= data['owners'];
					this.sellerData = data['seller']
					this.auctionData = data['auction']
					console.log(this.auctionData);
					if(this.auctionData) {
						this.bidButtonText = "Bid";
					} else {
						this.bidButtonText = "Offer";
					}
					if(data['seller']) {
						this.basePrice = data['seller'].basePrice / 1e18;
						this.buyItemform.patchValue({amount: data['seller'].basePrice / 1e18});
					}

				  }

				}
		  )
	}
	
	checkFee(event: any){
		
		this.fee		=	event.target.value * environment.serviceFee /100;
		this.youwillget	=	event.target.value - this.fee;
	}

	async signInToMetamask() {
		
		var thisobj =	this;
		
		var message = "Welcome to NFTMarketplace! \n \n \n Click 'Sign' to sign in. No password needed! \n \n \n I accept the NFTMarketplace Terms of Service: https://blockdemo.net/ ";
		await this.web3Service.signIn(message).then(result=>{			
			thisobj.signature = result				

		});
		/* 
		thisobj.mainService.userDetails(thisobj.current_account).subscribe(res => {
			if(res.status=="success"){
				localStorage.setItem('authUser', JSON.stringify(res));
				this.toastr.success("Account logged in successfully.");
			}else{
				this.toastr.error("Error in loging into account.");
			}
		}); */
	
	}

	async onPlaceOnBid(form_data){
		const fd: FormData = new FormData();
		fd.append('product_id', this.product_id);
		fd.append('price', this.placeonbidform.value.price);
		//fd.append('signature', "res");
		this.bidprice = this.placeonbidform.value.price;
		var type	=	localStorage.getItem('type');
		var result 	=	await this.web3Service.connectToWallet(type);
		var self =	this;
		if(!self.placeonbidform.value.price) {
				self.toastr.error('Please enter amount');
				return false;
		}

		hideSellStepPopup("placebidModalCenter");
		showPopup("followstep2ModalCenter");

		if(result.code==200){
			setTimeout(async function(){
				var current_account 			=	self.web3Service.getFirstAccount();
				self.bidData = await self.web3Service.onBidPlace(current_account, self.productdtls.token_id, self.productdtls.collection.exchange, self.placeonbidform.value.price);
				if(self.bidData) {
					self.toastr.success('Sell request added successfully.');
					self.nftservice.placeOnBid(fd)
						.subscribe(
						  (data) => {
							if(data['status'] == 'success'){
							  // self.toastr.success(data['message']);
							  self.placeonbidform.reset();
							  self.submitted = false;
							  self.loading  = false;			
							  // location.reload();		  
							  //self.router.navigate(['/assets/'+self.product_id]);
							}
						}
					);
				}
			}, 1000);
		}
		
	}

	async showDeposit() {
		hideSellStepPopup("followstep2ModalCenter");
		showPopup("depositModalCenter");
	}
		
	async showBidStepPopup() {
		hideSellStepPopup("depositModalCenter");
		this.bidPopupApprove = "CheckingBalance";
		this.onPlaceOnBid(this.placeonbidform.value);
	}		

	async acceptBid(bid) {
		console.log(this.bidAcceptRecord);

		this.bidAcceptRecord = bid;
		console.log(bid);
	}	

	async rejectBid(bid) {
		 this.rejectonbidform.patchValue({bid_id: bid.id});
	}	

	async acceptBidRecord(order_id) {
		hideSellStepPopup("acceptbidModalCenter");
		showPopup("acceptBidProcessModalCenter");

		var type	=	localStorage.getItem('type');
		var result 	=	await this.web3Service.connectToWallet(type);
		var self =	this;
		
		// const acceptD: FormData = new FormData();
		// acceptD.append('product_id', self.product_id);
		// acceptD.append('order_id', order_id);

		// self.nftservice.updateBidUserId(acceptD)
		// 	.subscribe(
		// 	  (data) => {
		// 		if(data['status'] == 'success'){
		// 			console.log("Update");
		// 		}
		// 	}
		// );
		// return false;				
		if(result.code==200){
			setTimeout(async function(){
				var current_account 			=	self.web3Service.getFirstAccount();
				const fd: FormData = new FormData();
				fd.append('product_id', self.product_id);
				fd.append('sellamount', self.placeonsellform.value.sellamount);
				//fd.append('signature', "res");
				self.acceptBidData = await self.web3Service.acceptBidItem(current_account, self.productdtls.token_id, self.productdtls.collection.exchange, order_id);
				// console.log(acceptBidData);
				if(self.sellData) {
					self.toastr.success('Bid accepted successfully.');
					self.globalservice.refreshPage.next(true);
					location.reload();	

					const acceptD: FormData = new FormData();
					acceptD.append('product_id', self.product_id);
					acceptD.append('order_id', order_id);

					self.nftservice.updateBidUserId(acceptD)
						.subscribe(
						  (data) => {
							if(data['status'] == 'success'){
								console.log("Update");
							}
						}
					);

				}
			}, 1000);
		}
	}

	async cancelBid(order_id) {
		// console.log(order_id); return false;
		// var type	=	localStorage.getItem('type');
		// var result 	=	await this.web3Service.connectToWallet(type);
		// var self =	this;
		// if(result.code==200){
		// 	setTimeout(async function(){
		// 		var current_account 			=	self.web3Service.getFirstAccount();
		// 		const fd: FormData = new FormData();
		// 		fd.append('product_id', self.product_id);
		// 		fd.append('sellamount', self.placeonsellform.value.sellamount);
		// 		self.cancelBidData = await self.web3Service.cancelBidItem(current_account, self.productdtls.token_id, self.productdtls.collection.exchange, order_id);
		// 		if(self.sellData) {
		// 			self.toastr.success('Bid cancel successfully.');
		// 			self.globalservice.refreshPage.next(true);
		// 			location.reload();	
		// 		}
		// 	}, 1000);
		// }
	}	

	async onPlaceOnSellAuction(form_data){
		var type	=	localStorage.getItem('type');
		var result 	=	await this.web3Service.connectToWallet(type);
		var self =	this;
		if(!self.placeonsellauctionform.value.sellamount) {
				self.toastr.error('Please enter min amount');
				return false;
		}
		if(!self.placeonsellauctionform.value.reserveprice) {
				self.toastr.error('Please enter reserve price');
				return false;
		}
		if(!self.placeonsellauctionform.value.expirationdays) {
				self.toastr.error('Please enter expiration days');
				return false;
		}
		showPopup('followstepModalCenter');
		hideSellStepPopup('sellModalCenter');
		if(result.code==200){
			setTimeout(async function(){
				var current_account 			=	self.web3Service.getFirstAccount();
				const fd: FormData = new FormData();
				fd.append('product_id', self.product_id);
				fd.append('sellamount', self.placeonsellauctionform.value.sellamount);
				self.sellData = await self.web3Service.onPlaceOnSellAuction(current_account, self.productdtls.token_id, self.productdtls.collection.exchange, self.placeonsellauctionform.value.sellamount, self.placeonsellauctionform.value.reserveprice, self.placeonsellauctionform.value.expirationdays);
				if(self.sellData) {
					self.toastr.success('Sell request added successfully.');
					self.globalservice.refreshPage.next(true);
				}
			}, 1000);
		}		
	}

	async onPlaceOnSell(form_data){
		console.log(form_data);
		
			var type	=	localStorage.getItem('type');
			var result 	=	await this.web3Service.connectToWallet(type);
			var self =	this;
			if(!self.placeonsellform.value.sellamount) {
					self.toastr.error('Please enter amount');
					return false;
			}
		console.log(self.placeonsellform.value.sellamount);
		if(result.code==200){
			setTimeout(async function(){
				var current_account 			=	self.web3Service.getFirstAccount();
				const fd: FormData = new FormData();
				fd.append('product_id', self.product_id);
				fd.append('sellamount', self.placeonsellform.value.sellamount);

				//fd.append('signature', "res");
				self.sellData = await self.web3Service.onPlaceOnSell(current_account, self.productdtls.token_id, self.productdtls.collection.exchange, self.placeonsellform.value.sellamount);
				if(self.sellData) {
					self.toastr.success('Sell request added successfully.');
					self.globalservice.refreshPage.next(true);
					//location.reload();	
				}
				// self.nftservice.placeOnSell(fd)
				// 	.subscribe(
				// 	  (data) => {
				// 		if(data['status'] == 'success'){
				// 		  self.toastr.success(data['message']);
				// 		  self.placeonsellform.reset();
				// 		  self.submitted = false;
				// 		  self.loading  = false;
				// 		  //location.reload();	
				// 		  //self.router.navigate(['/assets/'+this.product_id]);
				// 		  //this._location.back();
				// 		  //this.globalservice.refreshPage.next(true);
				// 		}
				// 	}
				// );
			}, 1000);
		}		
	}


	async purchaseItem(form_data){

		hideSellStepPopup("checkoutModalCenter");
		showPopup("buyBidProcessModalCenter");
		
		var type	=	localStorage.getItem('type');
		var result 	=	await this.web3Service.connectToWallet(type);
		var self =	this;
		if(result.code==200){
			setTimeout(async function(){
				var current_account 			=	self.web3Service.getFirstAccount();
				self.buyData = await self.web3Service.purchaseItem(current_account, self.productdtls.token_id, self.productdtls.collection.exchange);
				
				if(self.buyData) {
					self.toastr.success('Buy request added successfully.');
					self.globalservice.refreshPage.next(true);
					// location.reload();	

						// const acceptD: FormData = new FormData();
						// acceptD.append('product_id', self.product_id);
						// self.nftservice.updatePurchaseUserId(acceptD)
						// 	.subscribe(
						// 	  (data) => {
						// 		if(data['status'] == 'success'){
						// 			console.log("Update");
						// 		}
						// 	}
						// );
				}

				// const fd: FormData = new FormData();
				// fd.append('product_id', this.product_id);
				// fd.append('quantity', this.buyItemform.value.quantity);
				// fd.append('amount', this.sellerData.price);
				// fd.append('seller', this.sellerData.seller_id);	

				// self.nftservice.buyItem(fd)
				// .subscribe(
				// 	(buy) => {
				// 		if(buy['status'] == 'success'){
				// 			this.toastr.success(buy['message']);
				// 			this.buyItemform.reset();
				// 			this.submitted = false;
				// 			this.loading  = false;
				// 			this.globalservice.refreshPage.next(true);
				// 			location.reload();		  
				// 		  this.router.navigate(['/assets/'+this.product_id]);
				// 		}
				// 	}
				// )



			}, 1000);
		}	
	}

	

	fullScreen() {
		document.getElementsByTagName('body')[0].classList.add('fullScreenBody');
		let elem = document.documentElement;
		let methodToBeInvoked = elem.requestFullscreen ||  elem['mozRequestFullscreen']
		  ||
		  elem['msRequestFullscreen'];
		if (methodToBeInvoked) methodToBeInvoked.call(elem) ;
	}

	@HostListener('window:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
	  
	  if(event.keyCode == 27){
		  document.getElementsByTagName('body')[0].classList.remove('fullScreenBody');
	  }
	}

	
  		
	

	updateWistlist(product_id) {
		const fd: FormData = new FormData();
		fd.append('product_id', product_id);
		
		this.nftservice.updateWistlist(fd)
		  .subscribe(
			(data) => {
			  console.log(data);
			  if(data['status'] == 'success'){
				this.getItemsDetails();
				this.toastr.success(data['message']);
			  } else {
            this.toastr.error(data['message']);
          }
			}
		  );
	}

	shareLinkOnSocialMedia(type): void {
	    setShareLinks(type);
	}

	async onPlaceReport(form_data){
		if(this.placeonreportform.invalid){
      this.toastr.error('Please fill all required fields');
      return;
    }
		const fd: FormData = new FormData();
		fd.append('product_id', this.product_id);
		fd.append('reason', this.placeonreportform.value.reason);
		fd.append('comments', this.placeonreportform.value.comments);
		this.nftservice.reportProduct(fd)
			.subscribe(
			  (data) => {
				if(data['status'] == 'success'){
				  this.toastr.success(data['message']);
				  this.placeonreportform.reset();
				  this.submitted = false;
				  this.loading  = false;			
				  location.reload();		  
				  //this.router.navigate(['/assets/'+this.product_id]);
				}
			}
		);
	}

	copyInputMessage(inputElement){
    copyToClipboard(inputElement);
    this.toastr.success('Copied!');
  }

  showHideSellFormByType(selltype) {
  	this.selltype = selltype;
  }

  async onRejectOnBid(bidData) {
  	var self = this;
  	console.log(bidData);
  	if(!self.rejectonbidform.value.reason) {
				self.toastr.error('Please enter reason');
				return false;
		}
		const fd: FormData = new FormData();
		fd.append('reason', self.rejectonbidform.value.reason);
		fd.append('bid_id', self.rejectonbidform.value.bid_id);
		self.nftservice.updateRejectBid(fd)
			.subscribe(
				(buy) => {
					if(buy['status'] == 'success'){
						self.toastr.success(buy['message']);
						self.rejectonbidform.reset();
						self.submitted = false;
						self.loading  = false;
						self.globalservice.refreshPage.next(true);
						location.reload();		  
					  // this.router.navigate(['/assets/'+this.product_id]);
					}
				}
			)
  }



}