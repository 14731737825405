<section class="editProfileSection bg-white">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="editProileHead">
                   Creators
                </div>
            </div>
        </div>
    </div>
    <div class="exploreTabContent faqsTopBorder">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="featuredParent">
                        <div class="featuredCard" *ngFor="let creator of creatorsData">
                            <a routerLink="/user/{{creator.address}}" class="placeBidHref">
                                <div class="featureImg" style="background-image:url({{creator.image}})">
                                    <img src="{{ creator.image}}" alt="" />
                                </div>
                                <div class="featureBody">
                                    <div class="hotBidCardHead">{{ creator.address | slice:0:10 }}</div>
                                    <!-- <div class="featureLinkName">@jonmonaghan</div> -->
                                </div>
                                <div class="featureUser" style="background-image: url({{creator.image}});">
                                    <img src="{{creator.image}}" alt="" />
                                </div>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>