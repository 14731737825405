<section class="editProfileSection">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-10 col-lg-7 mx-auto">
                <div class="editProileHead">Edit profile</div>
                <div class="editSubHead">You can set preferred display name, create your branded profile URL and manage
                    other personal settings</div>
                <div class="editProfileCard">
                    <form [formGroup] ="editProfileform">
                        <div class="profileUpload">
                            <div class="profieImgView" style="background-image: url({{imgsrc}});">
                                <img [src]="imgsrc" id="upload_img" >
                            </div>
                            <div class="profileViewContent">
                                <div class="customFile">                                
                                    <input type="file" formControlName="image" (change)="onFileChange($event)" hidden id="prfImg" />
                                    <label for="prfImg">Choose file</label>
                                </div>
                                <div class="notePrfileImg">
                                    We recommend an image of at least 400x400.
                                    <br>
                                    Gifs work too.
                                </div>
                            </div>
                        </div>

                        <div class="formHead">
                        
                            <div class="form-group">
                                <label for="display">Display name</label>
                                <input type="text" formControlName="name" class="form-control" placeholder="Name" />
                            </div>

                            <div class="form-group customUrlGroup">
                                <label for="display">Custom URL</label>
                                <span class="preUrl">nft.com/</span>
                                <input type="text" formControlName="custom_url" class="form-control" placeholder="Enter custom URL" />
                            </div>

                            <div class="form-group">
                                <label for="display">Bio</label>
                                <input type="text" formControlName="bio" class="form-control" placeholder="Tell about yourself in a few words" />
                            </div>

                            <div class="form-group linkformGroup">
                                <label for="display">Twitter Username</label>
                                <div class="sublabel">Link your Twitter account in order to get the verification badge</div>
                                <a href="javascript:void(0);" class="linkText">Link</a>
                                <input type="text" class="form-control" formControlName="twitter_username"  placeholder="@" />
                            </div>

                            <div class="form-group customUrlGroup">
                                <label for="display">Personal site or portfolio</label>
                                <span class="preUrl">https:://</span>
                                <input type="text" class="form-control" formControlName="portfolio"  placeholder="Enter custom URL" />
                            </div>
                            
                        
                        <!-- <div class="verfiyBlock">
                            <div class="verfiycontnt">
                                <div class="verfiyHead">Verification</div>
                                <div class="verfiyDes">Procceed with verification proccess to get more
                                    visibility and gain trust on Rarible Marketplace. Please
                                    allow up to several weeks for the process.
                                </div>
                            </div>
                            <div class="verfiyBtnBlock">
                                <a href="javascript:void(0);" class="verifyBtn">Get verified</a>
                            </div>  
                        </div> -->
                        </div>
                    </form>
                    <div class="editFooter">
                        <a href="javascript:void(0);" class="cancelBtn" [routerLink]="['/account']">Cancel</a>
                        <button type="button" (click)="onSubmitted(editProfileform.value)" class="updateBtn">Update Profile</button>
                    </div>
                    

                </div>
                
            </div>
        </div>
    </div>
</section>
