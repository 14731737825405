import { Component, OnInit } from '@angular/core';
import { NftService } from '../../services/nftservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../../services/global.service';
import { Subscription } from 'rxjs';

declare var copyToClipboard: Function;

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})

export class HistoryComponent implements OnInit {

  // soldProductData : any = [];
  // collectionData : any = [];
  // productData : any = [];
  // favData : any = [];
  // userData : any = [];
  // createdProductData : any = [];
  followersList : any = [];
  subscription: Subscription;
  currentRoute : string;
  followingList : any = [];
  // wishlistData : any = [];
  // address : any;
  // followStatus : any;
  constructor(private nftservice : NftService,private router: Router, private toastr: ToastrService, private activatedRoute: ActivatedRoute,private globalservice : GlobalService) { 
    this.activatedRoute.params.subscribe((params) => {

    });
   this.currentRoute =  this.router.url;
  }

  ngOnInit(): void {
    this.initializePage()
    this.subscription = this.globalservice.refreshPage.subscribe( (res) => {
      this.initializePage();
    });
  }

  initializePage(){
    this.getUserAccountDetails()
  }

  getUserAccountDetails(){
    this.nftservice.getUserAccountDetails()
    .subscribe(
      (x) => {
        if(x['status'] == 'success'){
          this.followersList = x['followersList']
          this.followingList = x['followingList']
          console.log(this.followingList);
        }
      });
  }


}