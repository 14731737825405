<section class="editProfileSection bg-white">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="editProileHead">
                    Collections
                </div>
            </div>
        </div>
    </div>
    <div class="exploreTabhead newBidTab biduserTab">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-9 ml-auto">
                    <div class="exploreTabFilter">
                        <div class="expScroll newexpScroll">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="all-tab" data-toggle="tab" href="#all" role="tab"
                                        aria-controls="all" aria-selected="true">All</a>
                                </li>
                                <li class="nav-item" *ngFor="let category of exploreData">
                                    <a class="nav-link" id="art-tab" data-toggle="tab" href="#{{ category.name}}" role="tab"
                                        aria-controls="art" aria-selected="false" (click)="categoryWiseProduct(category.id)">{{ category.name}}</a>
                                </li>
                                
                                
                            </ul>
                        </div>
                        <div class="filterMenu">
                            <div class="dropdown">
                                <a href='javascript:void(0);'  class="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                     <i class="fal fa-sliders-h"></i>
                                        Sort By
                                </a>
                                <div class="dropdown-menu dropdown-menu-right customDropFilter"  aria-labelledby="dropdownMenuButton" (click)="$event.stopPropagation()">
                                    <ul class="filterlistMenu">
                                        <li>
                                            <a href="javascript:void(0);" class="active">
                                                Recently added
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);" (click)="filterByPrice('price_asc')">
                                               Cheapest
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);" (click)="filterByPrice('price_desc')">
                                              Highest Price
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);">
                                               Most Liked
                                            </a>
                                        </li>
                                        <div class="dropdown-divider"></div>
                                    </ul>

                                    <div class="sortBy">Options</div>
                                   
                                    <ul class="verifiedMenu filterlistMenu">
                                        <li>
                                            <a href="javascript:void(0);" >
                                               Verified only
                                                 <div class="checkCustom">
                                                    <input type="checkbox" hidden id='cuscheck_1'>
                                                    <label class="customLabelcheck" for="cuscheck_1"></label>
                                                </div>
                                            </a>
                                        </li>
                                       
                                    </ul>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="exploreTabContent explorePageContent">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-3 pr-md-0">
                    <div class="filterExplore">
                        <div>
                            <div class="statusAccordion">
                                <div class="filterExpHead"  data-toggle="collapse" data-target="#statusAccrd">
                                   <div class="filterHed">
                                        <i class="fas fa-star"></i>
                                        Status
                                   </div>
                                </div>
                                <div class="filterExpContent collapse show" id="statusAccrd" >
                                   <div class="statsFilter">
                                       <div [ngClass]="{'active': statusValue=='buy'}" class="statsBtn" (click)="filterByStatus('buy')" >
                                           Buy Now   
                                           <a href="javascript:void(0);" class="closeStats"><i class="fal fa-times"></i></a>
                                       </div>
                                       <div  [ngClass]="{'active': statusValue=='auction'}" class="statsBtn active" (click)="filterByStatus('auction')">
                                            On Auction
                                            <a href="javascript:void(0);" class="closeStats"><i class="fal fa-times"></i></a> 
                                       </div>
                                       <div  [ngClass]="{'active': statusValue=='new'}" class="statsBtn active" (click)="filterByStatus('new')">New
                                        <a href="javascript:void(0);" class="closeStats"><i class="fal fa-times"></i></a> 
                                       </div>
                                       <div  [ngClass]="{'active': statusValue=='hasoffer'}" class="statsBtn active" (click)="filterByStatus('hasoffer')">Has Offers
                                        <a href="javascript:void(0);" class="closeStats"><i class="fal fa-times"></i></a> 
                                       </div>
                                   </div>
                                </div>
                            </div>

                            <div class="statusAccordion">
                                <div class="filterExpHead"  data-toggle="collapse" data-target="#priceAccrd">
                                   <div class="filterHed">
                                    <i class="fal fa-dollar-sign"></i>
                                        Price
                                   </div>
                                </div>
                                <div class="filterExpContent collapse show" id="priceAccrd">
                                    <div class="dropdown">
                                        <button class="dropdown-toggle priceFIlterExpBtn" type="button" id="priceExplore" data-toggle="dropdown" >
                                            <div class="priceExpIcon"> <i class="fal fa-dollar-sign"></i></div>
                                            United States Dollar (USD)
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="priceExplore">
                                            <a class="dropdown-item" href="javascript:void(0);">
                                                <div class="priceFIlterExpBtn">
                                                    <div class="priceExpIcon"> <i class="fal fa-dollar-sign"></i></div>
                                                    United States Dollar (USD)
                                                </div>
                                            
                                            </a>
                                           
                                            <a class="dropdown-item" href="javascript:void(0);"> 
                                                <div class="priceFIlterExpBtn">
                                                        <div class="priceExpIcon"> <i class="fab fa-ethereum"></i></div>
                                                        Ether (ETH)
                                                    </div>
                                                </a>
                                          
                                        </div>
                                    </div>

                                    <div class="minMaxPrice">
                                        <form [formGroup]="pricefilterform">
                                            <div class="form-group minMaxGroup">
                                                <input type="number" formControlName="min" class="form-control" placeholder="Min" [ngClass]="{ 'is-invalid' : submitted && f.min.errors }">
                                                <div *ngIf="submitted && f.min.errors" class="invalid-feedback">
                                                    <div *ngIf="f.min.errors.required">Min price is required</div>
                                                </div>
                                                <span>to</span>
                                                <input type="number" formControlName="max" class="form-control" placeholder="Max" [ngClass]="{ 'is-invalid' : submitted && f.max.errors }">
                                                <div *ngIf="submitted && f.max.errors" class="invalid-feedback">
                                                    <div *ngIf="f.max.errors.required">Max price is required</div>
                                                </div>
                                            </div>
                                            <a  href="javascript:void(0);" (click)="filterByPriceRange()" class="bidBuy">Apply</a>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div class="statusAccordion">
                                <div class="filterExpHead"  data-toggle="collapse" data-target="#chainsAccrd">
                                   <div class="filterHed">
                                    <i class="fal fa-link"></i>
                                      Chains
                                   </div>
                                </div>
                                <div class="filterExpContent collapse show" id="chainsAccrd" >
                                    <ul class="verifiedMenu filterlistMenu">
                                        <li>
                                            <a href="javascript:void(0);">
                                                Rinkeby
                                                <div class="checkCustom">
                                                    <input type="checkbox" hidden id='cuscheck_21' (change)="filterByChain('rinkeby')">
                                                    <label class="customLabelcheck" for="cuscheck_21"></label>
                                                </div>
                                            </a>
                                        </li>

                                        <li>
                                            <a href="javascript:void(0);">
                                                Mumbai
                                                <div class="checkCustom">
                                                    <input type="checkbox" hidden id='cuscheck_22' (change)="filterByChain('mumbai')">
                                                    <label class="customLabelcheck" for="cuscheck_22"></label>
                                                </div>
                                            </a>
                                        </li>

                                        <li>
                                            <a href="javascript:void(0);" >
                                                BSC Testnet
                                                <div class="checkCustom">
                                                    <input type="checkbox" hidden id='cuscheck_23' (change)="filterByChain('bsc')">
                                                    <label class="customLabelcheck" for="cuscheck_23"></label>
                                                </div>
                                            </a>
                                        </li>
                                   
                                    </ul>
                                </div>
                            </div>

                            <div class="statusAccordion">
                                <div class="filterExpHead"  data-toggle="collapse" data-target="#saleonAccrd">
                                   <div class="filterHed">
                                    <i class="fal fa-tags"></i>
                                     On Sale In
                                   </div>
                                </div>
                                <div class="filterExpContent collapse show" id="saleonAccrd" >
                                   <div class="onsaleFilter">
                                       <div class="searchGroup form-group">
                                           <input type="text" placeholder="Filter" class="form-control">
                                           <i class="fa fa-search"></i>
                                       </div>

                                       <ul class="verifiedMenu filterlistMenu">
                                            <li>
                                                <a href="javascript:void(0);" >
                                               ETH
                                                    <div class="checkCustom">
                                                        <input type="checkbox" hidden id='cuscheck_2' (change)="filterBySale('eth')">
                                                        <label class="customLabelcheck" for="cuscheck_2"></label>
                                                    </div>
                                                </a>
                                            </li>

                                            <li>
                                                <a href="javascript:void(0);" >
                                                WETH
                                                    <div class="checkCustom">
                                                        <input type="checkbox" hidden id='cuscheck_3' (change)="filterBySale('weth')">
                                                        <label class="customLabelcheck" for="cuscheck_3"></label>
                                                    </div>
                                                </a>
                                            </li>

                                            <li>
                                                <a href="javascript:void(0);" >
                                                BRICK
                                                    <div class="checkCustom">
                                                        <input type="checkbox" hidden id='cuscheck_4' (change)="filterBySale('brick')">
                                                        <label class="customLabelcheck" for="cuscheck_4"></label>
                                                    </div>
                                                </a>
                                            </li>
                                       
                                        </ul>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-9">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                            <div class="exploreParent">
                                <div class="hotBidCard" *ngFor="let allProduct of allProductData">
									<a routerLink="/assets/{{allProduct.token_id}}" class="placeBidHref">
										<div class="hotBidCardImg"
											style="background-image: url({{allProduct.image}});">
											<img src="{{allProduct.image}}" alt="" />
										</div>
										<div class="hotBidCardBody">
											<a class="hotBidCardHead" [routerLink]="['/assets',allProduct.token_id]">{{allProduct.name}} #{{allProduct.id}}</a>
											<div class="hotBidCardRank" *ngIf="allProduct.price">{{allProduct.price}} ETH</div>
											<a href="javascript:void(0);" [routerLink]="['/assets',allProduct.token_id]" class="placeBidHref">Place a bid</a>
											<!-- <a href="javascript:void(0);" class="highestBid">Highest bid <span>0.02 WETH</span></a> -->
										</div>
									</a>

									<a href="javascript:void(0);" (click)="updateWistlist(allProduct?.id)" class="like">
										<i class="fa fa-heart"></i>
									</a>
									<a routerLink="/assets/{{allProduct?.token_id}}" class="placeBidHref">   
										<div class="hotBidProfileList">

											<div class="hotBidListProfileimg" *ngFor="let owner of allProduct.owner"
												style="background-image: url({{owner.image}});">
												<img src="{{owner.image}}" alt="" />
											</div>
										</div>
										
										<div class="dropListHotBid">
											<i class="fal fa-ellipsis-v"></i>
										</div>
									</a>
                                </div>
                                <div class="alert alert-danger" *ngIf="allProductData.length === 0">
                                  No record found.
                                </div>  
                            </div>
                            <div class="loadMoreTb" *ngIf="total > page*per_page && !loading">
                                <a href="javascript:void(0);" class="loadBtn" (click)="loadMore()">Load more items</a>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="{{ category.name}}" role="tabpanel" aria-labelledby="art-tab" *ngFor="let category of exploreData">
                            <div class="exploreParent">
                                <div class="hotBidCard" *ngFor="let product of productData">
									<a routerLink="/assets/{{product.token_id}}" class="placeBidHref">
										<div class="hotBidCardImg" style="background-image: url({{product.image}});">
											<img src="{{ product.image}}" alt="" />
										</div>
										<div class="hotBidCardBody">
											<a href="javascript:void(0);" class="hotBidCardHead" [routerLink]="['/assets',product.token_id]">{{ product.name}}</a>
											<div class="hotBidCardRank">{{ product.price}} ETH</div>
											<!-- <a href="javascript:void(0);" class="placeBidHref">Place a bid</a> -->
											<a routerLink="/assets/{{product.token_id}}" href="javascript:void(0);" class="highestBid">Highest bid <span>0.02
													WETH</span></a>
										</div>
									</a>
									<a href="javascript:void(0);" (click)="updateWistlist(product?.id)" class="like active">
										<i class="fa fa-heart"></i>
									</a>
									<a routerLink="/assets/{{product?.token_id}}" class="placeBidHref">   
										<div class="hotBidProfileList">
											<div class="hotBidListProfileimg" *ngFor="let owner of product?.owner"
												style="background-image: url({{owner.image}});">
												<img src="{{owner.image}}" alt="" />
											</div>
										</div>
										<div class="dropListHotBid">
											<i class="fal fa-ellipsis-v"></i>
										</div>
									</a>
                                </div>
                                <div class="alert alert-danger" *ngIf="productData.length === 0">
                                  No record found.
                                </div>  
                            </div>

                            <div class="loadMoreTb" *ngIf="totalP > page1*per_page && !loading">
                                <a href="javascript:void(0);" class="loadBtn" (click)="loadMoreP(category.id)">Load more items</a>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>